import {
  CamelCaseNamingConvention,
  fromValue,
  mapFrom,
  MappingProfile,
} from '@automapper/core';
import { AnagraficaDTO as SharedAnagraficaDTO } from 'src/app/dto/shared/anagrafica-dto';
import { AnagraficaDTO } from 'src/app/dto/dipendente-infortunato/anagrafica-dto';
import { Anagrafica } from '.';

export const anagraficaProfile: MappingProfile = (mapper) => {
  mapper.createMap(Anagrafica, AnagraficaDTO);
  mapper.createMap(AnagraficaDTO, Anagrafica);
  mapper
    .createMap(SharedAnagraficaDTO, Anagrafica, {
      namingConventions: new CamelCaseNamingConvention(),
    })
    .forMember(
      (dest) => dest.sipec,
      mapFrom((src) => src.sipec)
    )
    .forMember(
      (dest) => dest.codiceFiscale,
      mapFrom((src) => src.userName)
    )
    .forMember(
      (dest) => dest.nome,
      mapFrom((src) => src.name)
    )
    .forMember(
      (dest) => dest.cognome,
      mapFrom((src) => src.surname)
    )
    .forMember(
      (dest) => dest.sesso,
      mapFrom((src) => src.sesso)
    )
    .forMember(
      (dest) => dest.qualifica,
      mapFrom((src) => src.qualifica)
    )
    .forMember(
      (dest) => dest.ruolo,
      mapFrom((src) => src.ruolo)
    )
    .forMember(
      (dest) => dest.turno,
      mapFrom((src) => src.turno)
    )
    .forMember(
      (dest) => dest.sede,
      mapFrom((src) => src.sede)
    )
    .forMember(
      (dest) => dest.indirizzo,
      mapFrom((src) => src.residenza.indirizzo)
    )
    .forMember(
      (dest) => dest.provincia,
      mapFrom((src) => src.residenza.provincia)
    )
    // .forMember((dest) => dest.uo, fromValue(''))
    // .forMember((dest) => dest.lavoro, fromValue(''))
    // .forMember((dest) => dest.ruolo, fromValue(null))
    // .forMember((dest) => dest.qualifica, fromValue(null))
    .forMember(
      (dest) => dest.cap,
      mapFrom((src) => src.residenza.cap)
    )
    // .forMember((dest) => dest.articolazioneOrdinaria, fromValue(''))
    .forMember(
      (dest) => dest.comune,
      mapFrom((src) => src.residenza.comune)
    )
    .forMember(
      (dest) => dest.luogoNascita,
      mapFrom((src) => src.luogoNascita)
    )
    .forMember(
      (dest) => dest.dataNascita,
      mapFrom((src) => src.dataNascita)
    );
  mapper
    .createMap(Anagrafica, SharedAnagraficaDTO, {
      namingConventions: new CamelCaseNamingConvention(),
    })
    .forMember(
      (dest) => dest.turno,
      mapFrom((src) => src.articolazioneOrdinaria)
    )
    .forMember(
      (dest) => dest.sede,
      mapFrom((src) => src.uo)
    );
};
