import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FormState as ManualForm } from 'src/app/features/gestione-manuali/features/new/store/form/form.state';
import { Manual } from 'src/app/model/manual';
import { mapper } from 'src/app/model/mapper';
import { environment } from 'src/environments/environment';
import { DownloadFileHelper } from '../helpers/download-file-helper';
import { File } from 'src/app/model/file';

@Injectable()
export class ManualService {
  private readonly baseUrl = `${environment.baseUrl}/manuale`;

  constructor(private http: HttpClient) {}

  insert(manuale: ManualForm) {
    const encoder = new TextEncoder();
    const fileEncoded = encoder.encode(manuale.attachment.file);
    return this.http.post(this.baseUrl, {
      descrizione: manuale.descrizione,
      role: manuale.roles,
      file: Array.from(fileEncoded),
      nomeFile: manuale.attachment.name,
      tipo: manuale.attachment.type,
    });
  }

  get(id: string) {
    return this.http
      .get<Manual>(`${this.baseUrl}/${id}`)
      .pipe(map((manual) => mapper.map(manual, ManualForm, Manual)));
  }

  update(manuale: ManualForm, id?: string) {
    const { descrizione, roles } = manuale;
    return this.http.put(`${this.baseUrl}/${id}`, {
      descrizione,
      role: roles,
    });
  }

  delete(id: string) {
    return this.http.delete(`${this.baseUrl}/${id}`);
  }

  donwload(id: string): Observable<File> {
    return this.http
      .get(`${this.baseUrl}/${id}/download`, {
        observe: 'response',
        responseType: 'blob',
      })
      .pipe(map((res) => DownloadFileHelper.getFileFromHttpResponse(res)));
  }
}
