import {
  ContentChild,
  ContentChildren,
  Directive,
  QueryList,
} from '@angular/core';
import { AbstractControl, NgControl } from '@angular/forms';
import { merge, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SelectGroupDirective } from './select-group.directive';

@Directive({ selector: '[checkChildren]' })
export class CheckChildrenDirective {
  private destroy$ = new Subject();
  @ContentChildren(NgControl, { descendants: true })
  controls: QueryList<NgControl>;
  @ContentChild(SelectGroupDirective) selectGroup: SelectGroupDirective;

  ngAfterContentInit() {
    this.selectGroup.checkChanges$
      .pipe(takeUntil(this.destroy$))
      .subscribe((checked) => {
        if (!checked)
          this.controls.forEach(({ control }) =>
            (control as AbstractControl).patchValue(checked)
          );
      });

    const changes = this.controls.map(
      ({ control }) => (control as AbstractControl).valueChanges
    );

    merge(...changes)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        // every is more readbale
        this.selectGroup.checked = this.controls
          .toArray()
          .every(({ control }) => control?.value);
        // this.selectGroup.checked = !(this.controls.some(c => !c.control.value));
      });
  }
}
