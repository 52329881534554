import { createAction, props } from '@ngrx/store';
import { SidebarItem } from './sidebar.reducer';

export namespace SidebarActions {
  export const load = createAction('[Sidebar] load');
  export const loadComplete = createAction(
    '[Sidebar] load complete',
    props<{ items: SidebarItem[] }>()
  );
}
