import { Pipe, PipeTransform } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { AppState } from 'src/app/core/core.module';
import { RoleSelectors } from 'src/app/core/store/profile/store/role/role.selectors';
import { RoleEnum } from 'src/app/model/role';

@Pipe({
  name: 'readOnlyRole',
})
export class ReadOnlyRolePipe implements PipeTransform {
  private destroy$ = new Subject();
  constructor(private store: Store<AppState>) {}

  transform(readOnlyRoles: RoleEnum[]): Observable<boolean> {
    return this.store
      .select(RoleSelectors.rolesOnly, { roles: readOnlyRoles })
      .pipe(
        map((allowed) => !!allowed),
        takeUntil(this.destroy$)
      );
  }
}
