import { Directive, ElementRef, Input } from "@angular/core";
import { fromEvent } from "rxjs";
import { map } from "rxjs/operators";

@Directive({ selector: '[selectGroup]' })
export class SelectGroupDirective {
  @Input() selectGroup = false;

  checkChanges$ = fromEvent(this.host.nativeElement, 'change').pipe(
    map((e: Event) => (e.target as HTMLInputElement).checked)
  );

  constructor(private host: ElementRef<HTMLInputElement>) {}

  set checked(checked: boolean) {
    this.host.nativeElement.checked = checked;
  }
}
