import { AutoMap } from '@automapper/classes';
import { Allegato } from './allegato';

export class Allegati {
  @AutoMap({ typeFn: () => Allegato })
  attiIstruttori: Allegato[];

  @AutoMap({ typeFn: () => Allegato })
  documentazioneSanitaria: Allegato[];
}
