import { Component, Input } from '@angular/core';
import { Dinamica } from 'src/app/model/dipendente-infortunato/dinamica-infortunio/dinamica';

@Component({
  selector: 'vvf-registro-infortuni-dipendente-dinamica',
  template: `
    <div class="row mt-3">
      <div class="col-12 col-md-6 col-lg-4">
        <vvf-input-text
          [ngModel]="dinamica.causaInfortunio"
          [label]="
            'nuovoInfortunio.stepDinamicaInfortunio.causeOfInjury' | translate
          "
          [readOnly]="true"
        ></vvf-input-text>
      </div>
      <div class="col-12 col-md-6 col-lg-4">
        <vvf-input-text
          [ngModel]="dinamica.tipoAttivitaDescrizione | translate"
          [label]="
            'nuovoInfortunio.stepDinamicaInfortunio.activities' | translate
          "
          [readOnly]="true"
        ></vvf-input-text>
      </div>
      <div class="col-12 col-md-6 col-lg-4">
        <vvf-input-text
          [ngModel]="dinamica.eventoImprovviso | translate"
          [label]="
            'nuovoInfortunio.stepDinamicaInfortunio.unexpectedEventDetail'
              | translate
          "
          [readOnly]="true"
        ></vvf-input-text>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-6 col-lg-4">
        <vvf-input-text
          [ngModel]="dinamica.descrizioneInfortunio"
          [label]="
            'nuovoInfortunio.stepDinamicaInfortunio.descriptionActivitiesDetail'
              | translate
          "
          [readOnly]="true"
        ></vvf-input-text>
      </div>

      <div class="col-12 col-md-6 col-lg-4">
        <vvf-input-text
          [ngModel]="
            'nuovoInfortunio.stepDinamicaInfortunio.typologyOptions.' +
              dinamica.tipoEventoDescrzione | translate
          "
          [label]="
            'nuovoInfortunio.stepDinamicaInfortunio.typologyDetail' | translate
          "
          [readOnly]="true"
        ></vvf-input-text>
      </div>
      <div class="col-12 col-md-6 col-lg-4" *ngIf="dinamica.tipoEvento === 1">
        <vvf-input-text
          [ngModel]="dinamica.altezzaCaduta"
          [label]="'Altezza caduta' | translate"
          [readOnly]="true"
        ></vvf-input-text>
      </div>
    </div>
  `,
})
export class DinamicaDetailComponent {
  @Input() dinamica: Dinamica = {} as Dinamica;
}
