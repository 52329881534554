import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { Attachment } from '../../../model/attachment';

@Component({
  selector: 'shared-upload-file',
  template: `
    <input
      type="file"
      #fileInput
      [attr.id]="id"
      name="fileUpload"
      [multiple]="multiple"
      [accept]="accept"
      style="display: none;"
    />

    <ng-content></ng-content>

    <div class="invalid-feedback" *ngIf="isInvalid">
      {{ isInvalidMessage | translate }}
    </div>
  `,
})
export class UploadFileComponent {
  private files: Attachment[] = [];

  @ViewChild('fileInput', { static: false }) fileInput: ElementRef | undefined;

  @Input() id = 'fileUpload';
  @Input() isFileSigned: boolean = false;
  @Input() multiple = false;
  @Input() accept = '*';
  @Input() isInvalid: boolean = false;
  @Input() isInvalidMessage: string = '';
  @Output() uploadFiles = new EventEmitter<Attachment[]>();

  public upload() {
    if (!this.fileInput) {
      return;
    }

    const fileInput = this.fileInput?.nativeElement;
    this.files = [];

    fileInput.onchange = async () => {
      if (!this.fileInput) {
        return;
      }

      for (const file of fileInput.files) {
        let fileText = '';
        this.isFileSigned
          ? (fileText = await this.readFileToBase64(file))
          : (fileText = await this.readFile(file));

        const attachment: Attachment = {
          name: file.name,
          type: file.type,
          file: fileText,
        };
        if (this.accept === '*') {
          this.files.push(attachment);
          continue;
        }

        const accepted =
          this.accept.split(',').findIndex((a) => a === file.type) >= 0;

        if (accepted) {
          this.files.push(attachment);
        }
      }

      this.fileInput.nativeElement.value = '';
      this.uploadFiles.emit(this.files);
    };

    fileInput.click();
  }

  private async readFileToBase64(file: File): Promise<string> {
    const result = await new Promise<string>((resolve) => {
      const fileReader = new FileReader();
      fileReader.onload = (e) => resolve(e.target?.result as string);
      fileReader.readAsDataURL(file);
    });

    return result.split(',')[1];
  }

  private async readFile(file: File): Promise<string> {
    const result = await new Promise<string>((resolve) => {
      const fileReader = new FileReader();
      fileReader.onload = (e) => resolve(e.target?.result as string);
      fileReader.readAsText(file);
    });

    return result;
  }
}
