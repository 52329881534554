import {
  Component,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { IncidentiFilters } from '../../../model/incidente';
import { TypedFormControls } from '../../models/form/typed-form-controls';

@Component({
  selector: 'vvf-incidenti-filters-form',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div class="container-fluid px-4 py-3 mb-3 bg-color">
      <h5 class="primary-color">{{ 'common.research' | translate }}</h5>
      <form
        [formGroup]="form"
        [connectForm]="model"
        [debounceTime]="300"
        (changeForm)="valueChange.emit($event)"
        class="form-row mt-5"
      >
        <div class="col-12">
          <div class="form-row">
            <div class="form-group col-md-3">
              <div class="input-group">
                <label
                  for="input-group-data"
                  [ngClass]="{ active: model.eventDate }"
                >
                  {{ 'incidenti.filters.eventDate' | translate }}
                </label>
                <input
                  type="text"
                  class="form-control"
                  id="input-group-data"
                  formControlName="eventDate"
                  ngbDatepicker
                  #eventDatePicker="ngbDatepicker"
                />
                <div class="input-group-append">
                  <button
                    class="btn btn-icon"
                    type="button"
                    (click)="eventDatePicker.toggle()"
                  >
                    <span class="icon icon-primary material-icons">
                      event
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>

      <div class="row">
        <button class="btn btn-primary btn-icon mr-2" (click)="search.emit()">
          <span>{{ 'common.search' | translate }}</span>
          <i class="icon icon-white material-icons">search</i>
        </button>
        <button
          type="reset"
          class="btn btn-sm btn-icon"
          (click)="resetHandler()"
        >
          <span class="primary-color">{{
            'common.resetFilters' | translate
          }}</span>
          <i class="icon icon-primary material-icons">close</i>
        </button>
      </div>
    </div>
  `,
})
export class IncidentiFiltersComponent {
  @Input() model: IncidentiFilters = {} as IncidentiFilters;

  @Output() valueChange = new EventEmitter<IncidentiFilters>();
  @Output() search = new EventEmitter<void>();
  @Output() reset: EventEmitter<void> = new EventEmitter<void>();

  private controls: TypedFormControls<IncidentiFilters> = {
    eventDate: new FormControl(),
  };

  form = new FormGroup(this.controls);

  resetHandler() {
    this.form.reset();
    this.reset.emit();
  }
}
