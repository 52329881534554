import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { FascicoloInfortunio } from '../../model/infortunio';
import { StringHelper } from '../../shared/providers/string-helper.provider';
import { BozzaResponse } from 'src/app/model/bozza-response';
import { DipendenteInfortunato } from 'src/app/model/dipendente-infortunato';
import { DipendenteInfortunatoDTO } from 'src/app/dto/dipendente-infortunato';
import { mapper } from 'src/app/model/mapper';
import { map } from 'rxjs/operators';
import { Revisione } from 'src/app/model/revisione';

@Injectable()
export class InfortunioService {
  private readonly baseUrl = `${environment.baseUrl}/pratica/{0}/infortunio`;

  constructor(private http: HttpClient) {}

  getInfortunio(idPratica: string): Observable<FascicoloInfortunio> {
    return this.http.get<FascicoloInfortunio>(
      StringHelper.format(this.baseUrl, idPratica)
    );
  }

  getDraft(
    idPratica: string,
    idInfortunio: string
  ): Observable<DipendenteInfortunato> {
    return this.http
      .get<DipendenteInfortunatoDTO>(
        `${StringHelper.format(this.baseUrl, idPratica)}/bozza/${idInfortunio}`
      )
      .pipe(
        map((scheda) =>
          mapper.map(scheda, DipendenteInfortunato, DipendenteInfortunatoDTO)
        )
      );
  }

  saveDraft(
    idPratica: string,
    scheda: DipendenteInfortunato
  ): Observable<BozzaResponse> {
    let a = mapper.map(scheda, DipendenteInfortunatoDTO, DipendenteInfortunato);
    return this.http.put<BozzaResponse>(
      `${StringHelper.format(this.baseUrl, idPratica)}/bozza`,
      mapper.map(scheda, DipendenteInfortunatoDTO, DipendenteInfortunato)
    );
  }

  updateDraft(
    idPratica: string,
    idInfortunio: string,
    scheda: DipendenteInfortunato
  ): Observable<BozzaResponse> {
    return this.http.put<BozzaResponse>(
      `${StringHelper.format(this.baseUrl, idPratica)}/bozza/${idInfortunio}`,
      mapper.map(scheda, DipendenteInfortunatoDTO, DipendenteInfortunato)
    );
  }

  updateResponsabileDraft(
    idPratica: string,
    idInfortunio: string,
    scheda: DipendenteInfortunato
  ): Observable<BozzaResponse> {
    return this.http.put<BozzaResponse>(
      `${StringHelper.format(this.baseUrl, idPratica)}/bozza/${idInfortunio}/responsabile`,
      mapper.map(scheda, DipendenteInfortunatoDTO, DipendenteInfortunato)
    );
  }

  delete(idPratica: string, idInfortunio: string): Observable<void> {
    return this.http.delete<void>(
      `${StringHelper.format(this.baseUrl, idPratica)}/${idInfortunio}`
    );
  }

  getRevisioni(idPratica: string) {
    return this.http.get<Revisione[]>(
      `${StringHelper.format(this.baseUrl, idPratica)}/revisioni`
    );
  }

  sendDraftInseritore(idPratica: string, idInfortunio: string) {
    return this.http.put<BozzaResponse>(
      `${StringHelper.format(
        this.baseUrl,
        idPratica
      )}/bozza/inviaResponsabile/${idInfortunio}`,
      {}
    );
  }
}
