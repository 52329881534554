import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {PaginationBaseService} from '../../shared/services/pagination-base.service';
import {FascicoloIncidente, IncidentiFilters} from '../../model/incidente';
import {Pagination} from '../../shared/models/pagination.model';
import {Observable} from 'rxjs';
import {PaginatedResult} from '../../shared/models/paginated-result.model';

@Injectable()
export class IncidentiService extends PaginationBaseService<FascicoloIncidente> {
  private readonly baseUrl = `${environment.baseUrl}/pratiche/attrezzatureincidentate`;

  constructor(private http: HttpClient) {
    super();
  }

  getAll(pagination: Pagination, filters: Partial<IncidentiFilters>): Observable<PaginatedResult<FascicoloIncidente>> {
    let params = this.getPaginationHttpParams(pagination);

    if (filters?.eventDate) {
      params = params.append('DataEvento', filters.eventDate.toString());
    }

    return this.getPaginatedResult(this.http.get<FascicoloIncidente[]>(this.baseUrl, {observe: 'response', params}));
  }
}
