import { AutoMap } from '@automapper/classes';
import { DocumentoDTO } from './documento-dto';

export class AllegatoDTO {
  @AutoMap()
  titolo: string;

  @AutoMap({ typeFn: () => DocumentoDTO })
  documento: DocumentoDTO;
}
