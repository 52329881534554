import { AutoMap } from '@automapper/classes';
import { AnagraficaDTO } from './anagrafica-dto';
import { LuogoInfortunioDTO } from './luogo-infortunio-dto';
import { TestimoneDTO } from './testimone-dto';
import { CertificatoMedicoDTO } from './certificato-medico-dto';
import { LesioneDTO } from './lesione-dto';
import { AllegatoDTO } from '../shared/allegato-dto';
import { DocumentoDTO } from '../shared/documento-dto';
import { AttrezzaturaDTO } from './attrezzatura-dto';
import { MezzoDTO } from './mezzo-dto';
import { DpiDTO } from './dpi-dto';

export class DipendenteInfortunatoDTO {
  @AutoMap({ typeFn: () => AnagraficaDTO })
  infortunato: AnagraficaDTO;

  @AutoMap()
  descrizioneAttivita: string;

  @AutoMap()
  dataInfortunio: number;

  @AutoMap({ typeFn: () => LuogoInfortunioDTO })
  luogoInfortunio: LuogoInfortunioDTO;

  @AutoMap()
  causaInfortunio: string;

  @AutoMap()
  progressivoScheda: string;

  @AutoMap()
  numeroScheda: string;

  @AutoMap()
  dominio: string;

  @AutoMap()
  dataIntervento: string;

  @AutoMap()
  causaInfortunioDescrizione?: string;

  @AutoMap()
  descrizioneCausaInfortunio: string;

  @AutoMap()
  tipoEvento: number;

  @AutoMap()
  altezzaCaduta: string;

  @AutoMap()
  tipoEventoDescrzione?: string;

  @AutoMap()
  tipoAttivitaDescrizione: string;

  @AutoMap()
  eventoImprovviso: string;

  @AutoMap({ typeFn: () => DocumentoDTO })
  documentazione: DocumentoDTO[];

  @AutoMap()
  attrezzatureEnabled: boolean;

  @AutoMap()
  attrezzature: AttrezzaturaDTO[];

  @AutoMap()
  mezziEnabled: boolean;

  @AutoMap()
  mezzi: MezzoDTO[];

  @AutoMap()
  dpiEnabled: boolean;

  @AutoMap()
  dpi: DpiDTO[];

  @AutoMap()
  altrePersone: boolean;

  @AutoMap()
  attivitaConsueta: boolean;

  @AutoMap()
  lavorazioneSvolta: string;

  @AutoMap()
  testimoniEnabled: boolean;

  @AutoMap({ typeFn: () => TestimoneDTO })
  testimoni: TestimoneDTO[];

  @AutoMap()
  tipoSoccorso: number;

  @AutoMap()
  sedeAccadimento: number;

  @AutoMap()
  specificaSedeInfortunio: string;

  @AutoMap()
  giorniPS: string;

  @AutoMap()
  decessoDipendente: boolean;

  @AutoMap()
  conseguenzaLesioni: string;

  @AutoMap({ typeFn: () => CertificatoMedicoDTO })
  certificatiMedici: CertificatoMedicoDTO[];

  @AutoMap({ typeFn: () => LesioneDTO })
  lesioni: LesioneDTO[];

  @AutoMap({ typeFn: () => AllegatoDTO })
  attiIstruttori: AllegatoDTO[];

  @AutoMap({ typeFn: () => AllegatoDTO })
  documentazioneSanitaria: AllegatoDTO[];
}
