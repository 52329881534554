import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { UserSelectors } from 'src/app/core/store/profile/store/user/user.selectors';
import { AppState } from '../core/core.module';
import { RouterActions } from '../core/store/router/store/router.actions';
import * as UIActions from '../core/store/ui/store/ui.actions';

@Component({
  selector: 'vvf-header',
  template: `
    <div class="it-header-wrapper">
      <div class="it-header-slim-wrapper">
        <div class="container-fluid">
          <div class="row">
            <div class="col-12">
              <div class="it-header-slim-wrapper-content">
                <button
                  class="btn btn-sm btn-icon no-focus"
                  (click)="toggleSidebar()"
                >
                  <i class="fas fa-bars fa-2x text-white"></i>
                </button>
                <img src="assets/img/logo-vvf.svg" class="icon mr-2" />
                <a class="d-none d-lg-block navbar-brand" [routerLink]="['/']">
                  <h6 class="text-uppercase">
                    {{ 'header.title' | translate }}
                  </h6>
                </a>
                <div class="nav-mobile"></div>
                <div class="header-slim-right-zone">
                  <div class="dropdown" ngbDropdown>
                    <div
                      class="avatar avatar-primary size-lg cursor-pointer"
                      id="dropdownBasic1"
                      ngbDropdownToggle
                    >
                      {{ initials$ | async }}
                    </div>
                    <div
                      class="dropdown-menu right"
                      aria-labelledby="dropdownBasic1"
                      ngbDropdownMenu
                    >
                      <div class="link-list-wrapper">
                        <ul class="link-list">
                          <li (click)="logout()">
                            <a class="list-item" href="javascript:void(0)">
                              <span>{{ 'header.logout' | translate }}</span>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="it-nav-wrapper">
        <div class="it-header-center-wrapper"></div>
      </div>
    </div>
  `,
})
export class HeaderComponent implements OnInit {
  initials$ = this.store.select(UserSelectors.getInitials);

  constructor(private store: Store<AppState>) {}

  ngOnInit(): void {}

  toggleSidebar() {
    this.store.dispatch(UIActions.toggleSidebar());
  }

  logout() {
    this.store.dispatch(RouterActions.go({ path: ['account'] }));
  }
}
