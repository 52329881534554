import { createAction, props } from '@ngrx/store';
import { Auth } from '../../../../model/auth';

export namespace AuthActions {
  export const login = createAction(
    '[Auth] login',
    props<{ userName: string; password: string }>()
  );

  export const loginSuccess = createAction(
    '[Auth] login success',
    props<{ auth: Auth }>()
  );

  export const loginFailed = createAction('[Auth] login failed');

  export const logout = createAction('[Auth] logout');

  export const logoutPassword = createAction(
    '[Auth] logout change password',
    props<{ token: string | null }>()
  );

  export const logoutSuccess = createAction('[Auth] logout success');

  export const logoutComplete = createAction('[Auth] logout complete');

  export const saveAuth = createAction('[Auth] save', props<{ auth: Auth }>());
}
