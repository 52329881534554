import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { CreateFascicolo } from '../../model/fascicolo';

@Injectable()
export class PraticaService {
  private readonly baseUrl = `${environment.baseUrl}/pratica`;

  constructor(private http: HttpClient) {}

  crea(createFascicolo: CreateFascicolo): Observable<string> {
    return this.http.post<string>(this.baseUrl, createFascicolo);
  }

  modifica(
    fascicoloId: string,
    createFascicolo: CreateFascicolo
  ): Observable<void> {
    return this.http.put<void>(
      `${this.baseUrl}/${fascicoloId}`,
      createFascicolo
    );
  }

  elimina(fascicoloId: string): Observable<void> {
    return this.http.delete<void>(`${this.baseUrl}/${fascicoloId}`);
  }

  protocolla(fascicoloId: string): Observable<void> {
    return this.http.put<void>(`${this.baseUrl}/protocolla/${fascicoloId}`, {});
  }

  inviaResponsabileStruttura(fascicoloId: string): Observable<void> {
    return this.http.put<void>(`${this.baseUrl}/InviaPraticaResponsabileStruttura/${fascicoloId}`, {});
  }


  inviaPraticaAttrezzaturaIncidentata(fascicoloId: string): Observable<void> {
    return this.http.put<void>(`${this.baseUrl}/InviaPraticaAttrezzaturaIncidentata/${fascicoloId}`, {});
  }
}
