import {createReducer, on} from '@ngrx/store';
import { AuthActions } from './auth.actions';
import {Auth} from '../../../../model/auth';

export interface AuthState {
  auth: Auth;
  error?: boolean;
}

export const initialState: AuthState = {
  auth: {} as Auth,
  error: false
};

export const authReducer = createReducer(
  initialState,
  on(AuthActions.saveAuth, (state, { auth }) => ({ auth, error: false })),
  on(AuthActions.loginSuccess, (state, { auth }) => ({ auth, error: false })),
  on(AuthActions.loginFailed, () => ({ auth: {} as Auth, error: true })),
  on(AuthActions.logoutComplete, () => ({ auth: {} as Auth, error: false })),
);
