import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Auth} from '../../../../model/auth';
import {environment} from '../../../../../environments/environment';

@Injectable()
export class AuthService {
  private readonly baseUrl = `${ environment.baseUrl }/account`;

  constructor(private http: HttpClient) {
    // window.addEventListener('storage', () => {
    //   const auth = this.getAuth();
    //   console.log(auth);
    // });
  }

  login(userName: string, password: string) {
    return this.http.post<Auth>(`${this.baseUrl}/login`, { userName, password });
  }

  saveAuth(auth: Auth) {
    localStorage.setItem('auth', JSON.stringify(auth));
  }

  getAuth(): Auth {
    return JSON.parse(localStorage.getItem('auth') || '{}');
  }

  cleanAuth() {
    localStorage.removeItem('auth');
  }

}
