import { AutoMap } from '@automapper/classes';
import {
  CamelCaseNamingConvention,
  mapFrom,
  MappingProfile,
  preCondition,
} from '@automapper/core';
import { DipendenteInfortunatoDTO } from 'src/app/dto/dipendente-infortunato';

export class Dinamica {

  id: string;

  @AutoMap()
  causaInfortunio: string;

  @AutoMap()
  causaInfortunioDescrzione?: string;

  @AutoMap()
  descrizioneCausaInfortunio: string;

  @AutoMap()
  tipoEvento: number;

  @AutoMap()
  tipoEventoDescrzione?: string;

  @AutoMap()
  tipoAttivitaDescrizione: string;

  @AutoMap()
  eventoImprovviso: string;

  @AutoMap()
  descrizioneInfortunio: string;

  @AutoMap()
  altezzaCaduta: string;

  @AutoMap()
  progressivoScheda: string;

  @AutoMap()
  numeroScheda: string;

  @AutoMap()
  dominio: string;

  @AutoMap()
  dataIntervento: string;
}

export const dinamicaProfile: MappingProfile = (mapper) => {
  mapper
    .createMap(DipendenteInfortunatoDTO, Dinamica, {
      namingConventions: new CamelCaseNamingConvention(),
    })
    .forMember(
      (dest) => dest.progressivoScheda,
      mapFrom((src) => src.progressivoScheda)
    )
    .forMember(
      (dest) => dest.numeroScheda,
      mapFrom((src) => src.numeroScheda)
    )
    .forMember(
      (dest) => dest.dominio,
      mapFrom((src) => src.dominio)
    )
    .forMember(
      (dest) => dest.dataIntervento,
      mapFrom((src) => src.dataIntervento)
    )
    .forMember(
      (dest) => dest.causaInfortunio,
      mapFrom((src) => src.causaInfortunio)
    )
    .forMember(
      (dest) => dest.descrizioneCausaInfortunio,
      mapFrom((src) => src.descrizioneCausaInfortunio)
    )
    .forMember(
      (dest) => dest.descrizioneInfortunio,
      mapFrom((src) => src.descrizioneAttivita)
    )
    .forMember(
      (dest) => dest.tipoAttivitaDescrizione,
      mapFrom((src) => src.tipoAttivitaDescrizione)
    )
    .forMember(
      (dest) => dest.eventoImprovviso,
      mapFrom((src) => src.eventoImprovviso)
    )
    .forMember(
      (dest) => dest.altezzaCaduta,
      preCondition(((src) => src.tipoEvento === 1)),
      mapFrom((src) => src.altezzaCaduta)
    )
};
