import { AutoMap } from '@automapper/classes';
import { DocumentoDTO } from '../shared/documento-dto';

export class AttrezzaturaIncidentataDTO {
  categoriaAttrezzaturaIdParent?: number;

  @AutoMap()
  categoriaAttrezzaturaId: number;

  @AutoMap()
  descrizione: string;

  @AutoMap()
  primaVolta: boolean;

  @AutoMap()
  causeIncidente: number[];

  @AutoMap({ typeFn: () => DocumentoDTO })
  documentazione: DocumentoDTO[];

  @AutoMap()
  misureTecniche: string;

  @AutoMap()
  misureOrganizzative: string;

  @AutoMap()
  misureProcedurali: string;

  @AutoMap()
  targa: string;
  @AutoMap()
  sigla: string;
  @AutoMap()
  codiceGenereMezzo: string;
  @AutoMap()
  descrizioneGenereMezzo: string;
  @AutoMap()
  tipoGenereMezzo: string;
  @AutoMap()
  tipoDPI: string;
}
