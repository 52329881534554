import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { ManualAction, ManualGridItem } from 'src/app/model/manual';
import { RoleEnum } from 'src/app/model/role';

@Component({
  selector: 'vvf-manage-manuals-grid',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <table
      class="table table-bordered table-responsive-md"
      *ngIf="loading || items?.length"
    >
      <thead>
        <tr>
          <th scope="col">
            {{ 'manualiGrid.description' | translate }}
          </th>
          <th scope="col">
            {{ 'manualiGrid.nomeFile' | translate }}
          </th>
          <th class="text-center" scope="col">
            {{ 'manualiGrid.stato' | translate }}
          </th>
          <th class="text-center" sscope="col">
            {{ 'manualiGrid.ruoli' | translate }}
          </th>
          <th scope="col" class="text-center">
            {{ 'common.actions' | translate }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of items; let index = index; trackBy: trackByFn">
          <td class="align-middle">{{ item.descrizione }}</td>
          <td class="align-middle">
            <span class="text-secondary font-weight-bold"
              >{{ item.nomeFile }}
            </span>
          </td>
          <td class="align-middle  text-center">
            <span class="text-secondary font-weight-bold">
              {{ item.statoManuale | translate | uppercase }}
            </span>
          </td>
          <td class="align-middle">
            <div class="chip chip-lg" *ngFor="let role of item.roles">
              <span class="chip-label">
                {{ role.name }}
              </span>
            </div>
          </td>
          <td class="align-middle text-center">
            <vvf-manuals-grid-actions
              [manuale]="item"
              [index]="index"
              (action)="action.emit($event)"
            ></vvf-manuals-grid-actions>
          </td>
        </tr>
      </tbody>
    </table>

    <ng-content></ng-content>
  `,
})
export class ManageManualsGridComponent {
  RoleEnum = RoleEnum;
  @Input() loading: boolean = false;
  @Input() items: ManualGridItem[] = [];
  @Output() action = new EventEmitter<ManualAction>();

  trackByFn = (index: number) => index;
}
