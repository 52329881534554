import { AutoMap } from '@automapper/classes';

export class AnagraficaDTO {
  @AutoMap()
  codiceFiscale: string;

  @AutoMap()
  nome: string;

  @AutoMap()
  cognome: string;

  @AutoMap()
  sesso: string;

  @AutoMap()
  indirizzo: string;

  @AutoMap()
  provincia: string;

  @AutoMap()
  uo: string;

  @AutoMap()
  lavoro: string;

  @AutoMap()
  ruolo: string;

  @AutoMap()
  qualifica: string;

  @AutoMap()
  cap: string;

  @AutoMap()
  articolazioneOrdinaria: string;

  @AutoMap()
  comune: string;

  @AutoMap()
  dataNascita: string;

  @AutoMap()
  luogoNascita: string;

  @AutoMap()
  sipec: boolean;
}
