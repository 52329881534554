import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  Output,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'vvf-checkbox',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div class="form-check {{ className }}" [class.read-only]="readOnly">
      <input
        type="checkbox"
        [id]="name"
        [name]="name"
        [(ngModel)]="value"
        [attr.aria-labelledby]="name + '-help'"
        [checked]="!!value"
        (change)="checkHandler()"
      />
      <label
        [attr.for]="name"
        (click)="labelClickHandler($event)"
        class="font-weight-bold"
      >
        {{ label }}
      </label>
      <small
        *ngIf="description"
        [id]="name + '-help'"
        class="form-text font-weight-bold"
        [innerHtml]="description"
      ></small>
    </div>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxComponent),
      multi: true,
    },
  ],
})
export class CheckboxComponent implements ControlValueAccessor {
  private _value: boolean = false;
  get value() {
    return this._value;
  }

  set value(val: boolean) {
    this._value = val;
    this.onChange(val);
    this.onTouch(val);
  }

  @Input() formControlName = '';
  @Input() type = 'text';
  @Input() currency = false;
  @Input() label = '';
  @Input() description = '';
  @Input() placeholder = '';
  @Input() minValue: number;
  @Input() readOnly = false;
  @Input() className = '';
  @Output() check: EventEmitter<boolean> = new EventEmitter<boolean>();

  private _name!: string;
  get name(): string {
    return this._name ?? this.formControlName;
  }

  @Input() set name(value: string) {
    this._name = value;
  }

  onChange: any = () => {};

  onTouch: any = () => {};

  writeValue(value: any) {
    this.value = value;
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouch = fn;
  }

  checkHandler() {
    if (!this.readOnly) {
      this.check.emit(this.value);
    }
  }

  labelClickHandler(event: MouseEvent) {
    if (this.readOnly) {
      event.preventDefault();
    }
  }
}
