import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import {
  FascicoloInfortunioGridItem,
  InfortuniAction,
  InfortuniActionType,
  Infortunio,
  StatoInfortunio,
} from 'src/app/model/infortunio';
import { RoleEnum } from 'src/app/model/role';

@Component({
  selector: 'vvf-infortuni-grid-infortunato',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <ng-container
      *rolesExcept="[Role.SEA_RESPONSABILE_PS, Role.SEA_RESPONSABILE_SPI]"
    >
      <div class="chip chip-lg" *ngIf="infortunioIndex < 3">
        <span class="chip-label">
          {{ infortunio.nome }} {{ infortunio.cognome }}
        </span>
        <button
          (click)="$event.stopPropagation(); editHandler()"
          *ngIf="
            infortunio.stato !== StatoInfortunio.INVIATARESPONSABILE &&
            infortunio.stato !== StatoInfortunio.COMPLETATORESPONSABILE
          "
        >
          <i
            *rolesOnly="Role.SEA_INSERITORE"
            class="material-icons text-secondary"
            >edit</i
          >
        </button>
      </div>
    </ng-container>
    <div
      *rolesOnly="[Role.SEA_RESPONSABILE_PS, Role.SEA_RESPONSABILE_SPI]"
      class="text-center"
    >
      --
    </div>
  `,
})
export class InfortuniGridInfortunatoComponent {
  Role = RoleEnum;

  StatoInfortunio = StatoInfortunio;
  @Input() fascicoloIndex = -1;
  @Input() infortunioIndex = -1;
  @Input() fascicolo: FascicoloInfortunioGridItem =
    {} as FascicoloInfortunioGridItem;
  @Input() infortunio: Infortunio = {} as Infortunio;
  @Output() actionClick = new EventEmitter<InfortuniAction>();

  get canEdit() {
    return !!this.fascicolo?.allowedActions[
      InfortuniActionType.EDIT_INFORTUNIO
    ];
  }

  editHandler() {
    if (!this.canEdit) {
      return;
    }

    this.actionClick.emit({
      index: this.fascicoloIndex,
      infortunioIndex: this.infortunioIndex,
      id: this.fascicolo.id,
      infortunioId: this.infortunio.id,
      actionType: InfortuniActionType.EDIT_INFORTUNIO,
    });
  }
}
