import { AutoMap } from '@automapper/classes';
import { TipoParteCorpo } from '../tipo-parte-corpo.enum';

export class PartiCorpo {
  @AutoMap({ typeFn: () => Number })
  parti: TipoParteCorpo[];

  @AutoMap()
  nota: string;
}
