import { Injectable } from '@angular/core';
import {
  Actions,
  createEffect,
  ofType,
  ROOT_EFFECTS_INIT,
} from '@ngrx/effects';
import { AuthService } from '../services/auth.service';
import { AuthActions } from './auth.actions';
import {
  catchError,
  exhaustMap,
  filter,
  map,
  mapTo,
  switchMap,
  switchMapTo,
  tap,
} from 'rxjs/operators';
import { Auth } from '../../../../model/auth';
import { of } from 'rxjs';
import { RouterActions } from '../../router/store/router.actions';
import { ProfileActions } from '../../profile/store/profile.actions';
import { UserActions } from '../../profile/store/user/user.actions';

@Injectable()
export class AuthEffects {
  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ROOT_EFFECTS_INIT),
      mapTo(this.authService.getAuth()),
      filter((auth) => !!auth.accessToken),
      switchMap((auth) => [AuthActions.saveAuth({ auth }), UserActions.load()])
    )
  );

  loginEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.login),
      exhaustMap(({ userName, password }) =>
        this.authService.login(userName, password).pipe(
          map((auth: Auth) => AuthActions.loginSuccess({ auth })),
          catchError(() => of(AuthActions.loginFailed()))
        )
      )
    )
  );

  loginSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.loginSuccess),
      tap(({ auth }) => this.authService.saveAuth(auth)),
      switchMap(() => [
        UserActions.load(),
        RouterActions.go({ path: ['home'] }),
      ])
    )
  );

  logout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.logout),
      // tap(() => this.authService.cleanAuth()),
      switchMapTo([
        RouterActions.go({ path: ['account'] }),
        AuthActions.logoutSuccess(),
      ])
    )
  );

  logoutChangePassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.logoutPassword),
      // tap(() => this.authService.cleanAuth()),
      switchMap(({ token }) => [
        RouterActions.go({ path: [`/change/password/${token}`] }),
        AuthActions.logoutSuccess(),
      ])
    )
  );

  logoutSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.logoutSuccess),
      switchMapTo([
        AuthActions.logoutComplete(),
        ProfileActions.logout()
      ])
    )
  );

  constructor(private actions$: Actions, private authService: AuthService) {}
}
