import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AnagraficaDTO } from 'src/app/dto/shared/anagrafica-dto';
import { map } from 'rxjs/operators';
import { Anagrafica } from 'src/app/model/common/anagrafica';
import { mapper } from 'src/app/model/mapper';

export interface QueryParams {
  searchKey: string;
  codiceFiscale: string;
  codiceSede: string;
}

@Injectable()
export class AnagraficheUtentiService {
  private readonly baseUrl = `${environment.baseUrl}/anagraficheUtenti`;

  constructor(private http: HttpClient) {}

  getAnagrafiche(
    queryParams?: Partial<QueryParams>
  ): Observable<AnagraficaDTO[]> {
    let params = new HttpParams();
    if (queryParams) {
      if (queryParams.searchKey) {
        params = params.append('searchKey', queryParams.searchKey);
      }
      if (queryParams.codiceFiscale) {
        params = params.append('codiceFiscale', queryParams.codiceFiscale);
      }
      if (queryParams.codiceSede) {
        params = params.append('codiceSede', queryParams.codiceSede);
      }
    }
     return this.http.get<AnagraficaDTO[]>(this.baseUrl, { params });
         //return this.http.get<AnagraficaDTO[]>('/assets/mocks/anagrafica.json');
  }

  getAnagraficaByFiscalCode(fiscalCode: string): Observable<Anagrafica> {
    return this.getAnagrafiche({ codiceFiscale: fiscalCode }).pipe(
      map((result) =>
        result?.length
          ? JSON.parse(
              JSON.stringify(mapper.map(result[0], Anagrafica, AnagraficaDTO))
            )
          : ({} as Anagrafica)
      )
    );
  }
}
