import { Option } from '../../common/option';
import { DataLuogo } from './data-luogo';
import { Indirizzo } from './indirizzo';
import { Intervento } from './intervento';

export class TempoLuogo {
  dataLuogo: DataLuogo;

  intervento: Intervento;

  indirizzo: Indirizzo;

  places: Option[];

  sede: Option[];
}
