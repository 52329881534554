import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

@Component({
  selector: 'vvf-call-to-action',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div
      class="card card-teaser custom rounded shadow bg-primary clickable"
      (click)="actionClick.emit()"
    >
      <div class="card-body">
        <h6
          class="card-title text-white d-flex justify-content-between align-items-center"
        >
          <span>{{ text }}</span>
          <img src="assets/svg/add-infortunio.svg" class="icon" />
        </h6>
      </div>
    </div>
  `,
})
export class CallToActionComponent {
  @Input() text = '';
  @Output() actionClick = new EventEmitter<void>();
}
