import {
  AfterContentInit,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  QueryList,
  SimpleChanges
} from '@angular/core';
import {TabComponent} from './tab.component';

@Component({
  selector: 'shared-tabs',
  template: `
    <ul class="nav nav-tabs">
      <li *ngFor="let tab of tabs; let index = index" class="nav-item">
        <a href="javascript:void(0)"
           class="nav-link"
           [class.active]="tab.active"
           (click)="changeTab(index)"
        >
          {{tab.title}}
        </a>
      </li>
    </ul>

    <ng-content></ng-content>
  `
})
export class TabsComponent implements OnChanges, AfterContentInit {
  @Input() active = 0;
  @Output() navChange: EventEmitter<number> = new EventEmitter<number>();

  @ContentChildren(TabComponent) tabs: QueryList<TabComponent>
    = new QueryList<TabComponent>();

  ngAfterContentInit(): void {
    // get all active tabs
    const activeTabs = this.tabs.filter((tab) => tab.active);

    // if there is no active tab set, activate the first
    if (activeTabs.length === 0) {
      this.selectTab();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.active && this.tabs) {
      this.selectTab();
    }
  }

  changeTab(index: number) {
    this.active = index ?? this.active;
    this.selectTab();
    this.navChange.emit(this.active);
  }

  selectTab() {
    const tab = this.tabs.get(this.active);

    // deactivate all tabs
    this.tabs.toArray().forEach(t => t.active = false);

    // activate the tab the user has clicked on.
    if (tab) {
      tab.active = true;
    }
  }
}
