import { AutoMap } from '@automapper/classes';
import {
  CamelCaseNamingConvention,
  mapFrom,
  MappingProfile,
  mapWith,
  preCondition,
} from '@automapper/core';
import { DipendenteInfortunatoDTO } from 'src/app/dto/dipendente-infortunato';
import { DpiDTO } from 'src/app/dto/dipendente-infortunato/dpi-dto';
import { DpiItem } from './dpi-item';

export class Dpi {
  @AutoMap()
  enabled: boolean;

  @AutoMap({ typeFn: () => DpiItem })
  items: DpiItem[];
}

export const dpiProfile: MappingProfile = (mapper) => {
  mapper
    .createMap(DpiDTO, DpiItem, {
      namingConventions: new CamelCaseNamingConvention(),
    })
    .forMember(
      (dest) => dest.categoriaAttrezzatura.id,
      mapFrom((src) => src.categoriaAttrezzaturaId)
    );

  mapper
    .createMap(DpiItem, DpiDTO, {
      namingConventions: new CamelCaseNamingConvention(),
    })
    .forMember(
      (dest) => dest.categoriaAttrezzaturaId,
      mapFrom((src) => src.categoriaAttrezzatura.id)
    );

  mapper
    .createMap(DipendenteInfortunatoDTO, Dpi, {
      namingConventions: new CamelCaseNamingConvention(),
    })
    .forMember(
      (dest) => dest.enabled,
      mapFrom((src) => src.dpiEnabled)
    )
    .forMember(
      (dest) => dest.items,
      mapWith(DpiItem, DpiDTO, (src) => src.dpi)
    );
};
