import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import {
  FascicoloInfortunio,
  InfortuniAction,
  InfortuniActionType,
} from 'src/app/model/infortunio';

@Component({
  selector: 'vvf-registro-infortuni-grid',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <ng-container *ngIf="!fascicoli?.length; else table">
      <div class="d-flex justify-content-center w-100">
        {{ 'common.noResult' | translate }}
      </div>
    </ng-container>

    <ng-template #table>
      <table class="table table-bordered">
        <thead>
          <tr>
            <th scope="col">
              {{ 'fascicoli.eventDate' | translate }}
            </th>
            <th scope="col">
              {{ 'fascicoli.descrizione' | translate }}
            </th>
            <th scope="col">
              {{ 'fascicoli.items' | translate }}
            </th>
            <th scope="col">
              {{ 'fascicoli.state' | translate }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            *ngFor="
              let fascicolo of fascicoli;
              let fascicoloIndex = index;
              trackBy: fascicoliTrackByFn
            "
          >
            <td class="align-middle">
              {{ fascicolo.dataEvento | date }}
            </td>
            <td class="align-middle">
              {{ fascicolo.descrizione }}
            </td>
            <td class="align-middle">
              <ng-container
                *ngFor="
                  let infortunio of fascicolo.infortuni;
                  let infortunioIndex = index;
                  trackBy: infortuniTrackByFn
                "
              >
                <div
                  class="chip chip-lg cursor-pointer"
                  (click)="
                    $event.stopPropagation();
                    viewHandler(infortunio.id, fascicolo.id, infortunioIndex)
                  "
                >
                  <span class="chip-label">
                    {{ infortunio.nome }} {{ infortunio.cognome }}
                  </span>
                </div>
              </ng-container>
            </td>
            <td class="align-middle text-secondary font-weight-bold">
              {{
                'fascicoli.status.' + fascicolo.stato | translate | uppercase
              }}
            </td>
          </tr>
        </tbody>
      </table>
    </ng-template>
  `,
})
export class GridComponent {
  @Input() fascicoli: FascicoloInfortunio[] = [];

  @Output() actionClick = new EventEmitter<InfortuniAction>();

  fascicoliTrackByFn = (index: number) => index;
  infortuniTrackByFn = (index: number) => index;

  viewHandler(infortunioId: string, id: string, index: number) {
    this.actionClick.emit({
      actionType: InfortuniActionType.VIEW,
      id,
      infortunioId,
      index,
    });
  }
}
