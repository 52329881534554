import { classes } from '@automapper/classes';
import { createMapper } from '@automapper/core';
import { AttrezzaturaIncidentataProfile } from './attrezzatura-incidentata/profile';
import { anagraficaProfile } from './common/anagrafica/profile';
import { documentoProfile } from './common/documento/profile';
import { allegatoProfile } from './dipendente-infortunato/allegati/profile';
import { dinamicaProfile } from './dipendente-infortunato/dinamica-infortunio/dinamica';
import { attrezzatureProfile } from './dipendente-infortunato/informazioni-tecniche/attrezzature';
import { dpiProfile } from './dipendente-infortunato/informazioni-tecniche/dpi';
import { mezziProfile } from './dipendente-infortunato/informazioni-tecniche/mezzi';
import { testimoniProfile } from './dipendente-infortunato/informazioni-tecniche/testimoni';
import { testimoneProfile } from './dipendente-infortunato/informazioni-tecniche/testimoni/testimone';
import { lesioneItemProfile } from './dipendente-infortunato/lesioni/lesione-item/profile';
import { dipendenteInfortunatoProfile } from './dipendente-infortunato/profile';
import { certificatoMedicoProfile } from './dipendente-infortunato/prognosi/profile';
import { dataLuogoProfile } from './dipendente-infortunato/tempo-luogo/data-luogo';
import { indirizzoProfile } from './dipendente-infortunato/tempo-luogo/indirizzo';
import { interventoProfile } from './dipendente-infortunato/tempo-luogo/intervento';
import { tempoLuogoProfile } from './dipendente-infortunato/tempo-luogo/profile';
import { ManualeProfile } from './manual';
import { InformazioniTecnicheProfile } from './attrezzatura-incidentata/informazioni-tecniche/profile';

export const mapper = createMapper({
  name: 'gestionaleInfortuniAutoMapper',
  pluginInitializer: classes,
});

mapper
  // common
  .addProfile(anagraficaProfile)
  .addProfile(documentoProfile)

  // dipendente infortunato
  .addProfile(tempoLuogoProfile)
  .addProfile(interventoProfile)
  .addProfile(indirizzoProfile)
  .addProfile(dataLuogoProfile)
  .addProfile(dinamicaProfile)
  .addProfile(attrezzatureProfile)
  .addProfile(mezziProfile)
  .addProfile(dpiProfile)
  .addProfile(testimoneProfile)
  .addProfile(testimoniProfile)
  .addProfile(certificatoMedicoProfile)
  .addProfile(lesioneItemProfile)
  .addProfile(allegatoProfile)

  .addProfile(dipendenteInfortunatoProfile)
  .addProfile(ManualeProfile)

  //Attrezzatura Incidentata

  .addProfile(InformazioniTecnicheProfile)
  .addProfile(AttrezzaturaIncidentataProfile);
