import { createReducer, on } from '@ngrx/store';
import { ConfigurationActions } from './configuration.actions';
import { Configuration } from './configuration.state';

export const initialState = {} as Configuration;

export const configurationReducer = createReducer(
  initialState,
  on(
    ConfigurationActions.loadSuccess,
    (state, { configuration }) => ({ ...state, ...configuration })
  )
);
