import { AutoMap } from '@automapper/classes';

export class LuogoInfortunioDTO {
  @AutoMap()
  indirizzo: string;

  @AutoMap()
  provincia: string;

  @AutoMap()
  cap: string;

  @AutoMap()
  tipoLuogo: number;

  @AutoMap()
  orario: string;

  @AutoMap()
  ambientiOperativi: number;

  @AutoMap()
  serviziIstituto: number;

  @AutoMap()
  modalitaInfortunio: number;

  @AutoMap()
  areaOperativa: string;

  @AutoMap()
  fasciaOraria: number;

  @AutoMap()
  comune: string;

  @AutoMap()
  objectId: string;

  @AutoMap()
  tipo: string;

  @AutoMap()
  nomeFile: string;

  @AutoMap()
  numeroIntervento: string;

  @AutoMap()
  sedeAccadimento: number;

  @AutoMap()
  specificaSedeInfortunio: string;

  @AutoMap()
  specificaLuogo: number;

  @AutoMap()
  tipologiaInterventoInfortunio: number;

  @AutoMap()
  orarioLavoroStraordinario: boolean;
}
