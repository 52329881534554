import { Component, Input } from '@angular/core';

@Component({
  selector: 'shared-stepper-tab',
  template: `
    <div [hidden]="!active" class="pane">
      <ng-content></ng-content>
    </div>
  `,
})
export class StepperTabComponent {
  @Input() title = '';
  @Input() active = false;
  @Input() status = '';
}
