import { AutoMap } from '@automapper/classes';
import { Lesione } from './lesione-item/lesione';
import { PartiCorpo } from './lesione-item/parti-corpo';

export class LesioneEditor {
  @AutoMap({ typeFn: () => Lesione })
  lesione: Lesione;

  @AutoMap({ typeFn: () => PartiCorpo })
  partiCorpo: PartiCorpo;
}
