import { createAction, props } from '@ngrx/store';

export namespace DownloadFileActions {

  export const download = createAction(
    '[Download File] download',
    props<{ objectId: string; name: string }>()
  );
  
}
