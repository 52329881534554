import { Component, Input } from '@angular/core';

@Component({
  selector: 'shared-collapse',
  template: `
    <div [class]="className" tabindex="0">
      <div class="col-12 p-0">
        <div class="collapse-div">
          <div class="collapse-header">
            <button
              (click)="toggleCollapse()"
              [attr.aria-expanded]="expanded === true"
              data-toggle="collapse"
              class="px-0"
            >
              <div>
                <ng-content select="[title]"></ng-content>
              </div>
            </button>
          </div>
          <div [ngbCollapse]="!expanded" class="collapse" role="tabpanel">
            <ng-content
              select="[body]"
              class="collapse-body border-left"
            ></ng-content>
          </div>
        </div>
      </div>
    </div>
  `,
})
export class CollapseComponent {
  @Input() expanded: boolean;
  private _className!: string;
  get className(): string {
    return (
      this._className ??
      'collapse-wrapper bg-white border-radius-collapse my-3 m-0'
    );
  }

  @Input() set className(value: string) {
    this._className = value;
  }

  toggleCollapse() {
    this.expanded = !this.expanded;
  }
}
