import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Typologic } from 'src/app/model/common/typologic';

@Injectable()
export class TranslateTypologicService {
  constructor(private translateService: TranslateService) {}

  private doTranslation(typologics: Typologic[], path: string): Typologic[] {
    return typologics.map((typologic: Typologic) => ({
      ...typologic,
      description: this.translateService.instant(
        `${path}.${typologic.description}`
      ),
      children: typologic.children
        ? this.doTranslation(typologic.children, path)
        : undefined,
    }));
  }

  translate(typologics: Typologic[], path: string) {
    return this.doTranslation(typologics, path);
  }
}
