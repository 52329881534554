import {PaginatedResult} from '../models/paginated-result.model';
import {HttpParams, HttpResponse} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {Pagination} from '../models/pagination.model';
import {Injectable} from '@angular/core';

@Injectable()
export class PaginationBaseService<T> {

  constructor() {
  }

  protected getPaginationHttpParams(pagination?: Pagination): HttpParams {
    let params = new HttpParams();

    if (pagination) {

      if (pagination.currentPage) {
        params = params.append('pageNumber', pagination.currentPage.toString());
      }

      if (pagination.itemsPerPage) {
        params = params.append('pageSize', pagination.itemsPerPage.toString());
      }

      if (pagination.orderBy) {
        params = params.append('orderBy', pagination.orderBy);
      }

      if (pagination.ascending) {
        params = params.append('ascending', pagination.ascending.toString());
      }
    }

    return params;
  }

  protected getPaginationParams(pagination?: Pagination) {
    let params = {};

    if (pagination) {
      if (pagination.currentPage) {
        params = {...params, pageNumber: pagination.currentPage};
      }

      if (pagination.itemsPerPage) {
        params = {...params, pageSize: pagination.itemsPerPage};
      }

      if (pagination.orderBy) {
        params = {...params, orderBy: pagination.orderBy};
      }

      if (pagination.ascending) {
        params = {...params, ascending: pagination.ascending};
      }
    }

    return params;
  }

  protected getPaginatedResult(httpResponse: Observable<HttpResponse<T[]>>) {
    const paginatedResult: PaginatedResult<T> = {} as PaginatedResult<T>;

    return httpResponse.pipe(
      map((response: HttpResponse<T[]>) => {
        paginatedResult.result = response.body ?? [];

        if (response.headers.get('Pagination') != null) {
          paginatedResult.pagination = JSON.parse(response.headers.get('Pagination') as string);
        }

        return paginatedResult;
      }),
      catchError(e => {
        console.error('PaginationBaseService error', e);
        paginatedResult.result = [];
        return of(paginatedResult);
      })
    );
  }

  protected getPagimatedClientSideResult(httpResponse: Observable<HttpResponse<T[]>>) {
    const paginatedResult: PaginatedResult<T> = {} as PaginatedResult<T>;

    return httpResponse.pipe(
      map((response: HttpResponse<T[]>) => {
        paginatedResult.result = response.body ?? [];

        if (response.headers.get('Pagination') != null) {
          paginatedResult.pagination = JSON.parse(response.headers.get('Pagination') as string);
        }

        return paginatedResult;
      }),
      catchError(e => {
        console.error('PaginationBaseService error', e);
        paginatedResult.result = [];
        return of(paginatedResult);
      })
    );
  }


}
