import { Fascicolo } from './fascicolo';

export interface Incidente {
  id: string;
  tipo: string;
  categoria: string;
  stato: string;
  dettaglio: string;
  targa: string;
  sigla: string;
  tipoGenereMezzo: string;
  tipoDPI: string;
}

export enum AttrezzaturaIncidentataEnum {
  Attrezzatura = 'attrezzatura',
  Mezzo = 'mezzo',
  Dpi = 'DPI',
}

export enum StatoIncidente {
  BOZZA = 'bozza',
  COMPLETATO = 'completato',
  CANCELLATO = 'cancellato',
  INVIATA = 'inviatoresponsabile',
}

export interface FascicoloIncidente extends Fascicolo {
  attrezzatureIncidentate: Incidente[];
  tipologiaIncidentata: AttrezzaturaIncidentataEnum;
}

export enum IncidentiActionType {
  DELETE = 'delete',
  EDIT = 'edit',
  VIEW = 'view',
  PROTOCOLLA = 'protocolla',
}

export enum IncidenteActionType {
  DELETE = 'delete',
  EDIT = 'edit',
  SEND = 'send',
}

export interface IncidenteAction {
  index: number;
  id: string;
  actionType: IncidenteActionType;
}
export interface IncidentiAction {
  index: number;
  id: string;
  infortunioId?: string;
  infortunioIndex?: number;
  actionType: IncidentiActionType;
}

export type AllowedIncidentiActions = Partial<
  Record<IncidentiActionType, boolean>
>;

export type AllowedIncidenteActions = Partial<
  Record<IncidenteActionType, boolean>
>;

export interface IncidenteGridItem extends Incidente {
  allowedActions: AllowedIncidenteActions;
}
export interface FascicoloIncidenteGridItem extends FascicoloIncidente {
  allowedActions: AllowedIncidentiActions;
}

export interface IncidentiFilters {
  eventDate: number;
}
