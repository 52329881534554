import {
  AfterViewInit,
  Component,
  EventEmitter,
  forwardRef,
  Injector,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
  NgControl,
} from '@angular/forms';
import { Subject } from 'rxjs';
import { Errore } from '../../../model/errore';
import { IPaginatedService } from '../../services/paginated-iterface.service';
import { PaginationService } from '../../services/pagination.service';
import { Pagination } from '../../models/pagination.model';
import { NgSelectComponent } from '@ng-select/ng-select';

@Component({
  selector: 'vvf-select',
  template: `
    <div [ngClass]="{ 'form-group': isFormGroup }">
      <div class="bootstrap-select-wrapper">
      <label>
        <span *ngIf="mandatory" class="text-danger">* </span>
        {{ label | translate }}
      </label>
        <div class="dropdown bootstrap-select">
          <ng-select
            #select
            class="dropdown-menu"
            [ngStyle]="{ 'width.px': minWidth ? minWidth : 'auto' }"
            [ngClass]="{ 'is-invalid': error?.campoRiferimento }"
            [ngModel]="value"
            [items]="items ? items : []"
            [disabled]="disabled"
            [searchable]="false"
            [bindValue]="bindValue"
            [bindLabel]="bindLabel"
            [dropdownPosition]="dropdownPosition"
            [multiple]="multiple"
            [closeOnSelect]="closeOnSelect"
            [virtualScroll]="virtualScroll"
            [loadingText]="loadingText + '...'"
            [typeahead]="typeahead"
            [clearable]="clearable"
            [clearAllText]="clearAllText"
            [placeholder]="placeholder"
            [typeToSearchText]="typeToSearchText"
            [readonly]="readonly"
            (open)="onOpen()"
            (change)="onChanged($event)"
            (scrollToEnd)="onScrollToEnd()"
            (clear)="onClear($event)"
          >
            <ng-template ng-typetosearch-tmp></ng-template>
            <ng-template
              ng-notfound-tmp
              let-searchTerm="searchTerm"
            ></ng-template>
            <ng-template
              ng-loadingtext-tmp
              let-searchTerm="searchTerm"
            ></ng-template>

            <ng-container *ngIf="searchable">
              <ng-template ng-header-tmp>
                <div class="bs-searchbox">
                  <input
                    type="text"
                    class="form-control"
                    [placeholder]="typeToSearchText"
                    (input)="selectFilter(select, $event)"
                  />
                </div>
              </ng-template>
            </ng-container>

            <ng-template ng-label-tmp let-item="item">
              <ng-container
                *ngIf="(bindLabel && item[bindLabel]) || (!bindLabel && !!item)"
              >
                {{
                  (labelPrefix ? labelPrefix + '.' : '') +
                    (bindLabel ? item[bindLabel] : item) | translate
                }}
              </ng-container>
            </ng-template>

            <ng-template ng-option-tmp let-item="item" let-index="index">
              <li>
                <a class="dropdown-item">
                  {{
                    (labelPrefix ? labelPrefix + '.' : '') +
                      (bindLabel ? item[bindLabel] : item) | translate
                  }}
                </a>
              </li>
            </ng-template>

            <ng-container #footerContainer>
              <ng-template ng-footer-tmp [ngIf]="showFooter">
                <div class="bs-footer">
                  <ng-content select="[footer]"></ng-content>
                </div>
              </ng-template>
            </ng-container>
          </ng-select>
          <div class="invalid-feedback" *ngIf="error?.campoRiferimento">
            {{ errorPrefix + error.messaggio }}
          </div>
        </div>
      </div>
    </div>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectComponent),
      multi: true,
    },
  ],
})
export class SelectComponent
  implements ControlValueAccessor, OnInit, AfterViewInit
{
  control: FormControl;

  constructor(public injector: Injector) {}

  private _value = '';
  get value() {
    return this._value;
  }

  set value(val: string) {
    this._value = val;
    this.onChange(val);
    this.onTouch(val);
  }

  paginationService: PaginationService<any>;

  @Input() label: string;
  @Input() items: any[] = [];
  @Input() service: IPaginatedService<any>;
  @Input() pagination: Pagination;
  @Input() params: any;
  @Input() preload: boolean = true;
  @Input() concat: boolean = false;
  @Input() disabled: boolean;
  @Input() searchable: boolean;
  @Input() bindValue: string;
  @Input() bindLabel: string;
  @Input() labelPrefix: string;
  @Input() dropdownPosition: 'bottom' | 'top' | 'auto';
  @Input() multiple: boolean;
  @Input() closeOnSelect: boolean = true;
  @Input() virtualScroll: boolean;
  @Input() loadingText: string;
  @Input() typeahead: Subject<string> = new Subject<string>();
  @Input() clearable: boolean;
  @Input() clearAllText: string;
  @Input() placeholder: string = '';
  @Input() typeToSearchText: string = '';
  @Input() readonly: boolean;
  @Input() showFooter: boolean;
  @Input() mandatory = false;

  @Input() isFormGroup: boolean = true;
  @Input() minWidth: number;
  @Input() error: Errore;
  @Input() errorPrefix: string;
  @Input() filterKey = '';

  @Output() selectOpen: EventEmitter<void> = new EventEmitter<void>();
  @Output() selectChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() scrollToEnd: EventEmitter<void> = new EventEmitter<void>();
  @Output() clear: EventEmitter<any> = new EventEmitter<any>();

  onChange: any = () => {};

  onTouch: any = () => {};

  private isFirst = true;
  private itemsCopy: any[] = [];

  writeValue(value: any) {
    this.value = value;
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouch = fn;
  }

  onOpen() {
    this.selectOpen.emit();
  }

  onChanged(value: any) {
    this.value = this.bindValue ? value[this.bindValue] : value;
    this.selectChange.emit(this.value);
  }

  onScrollToEnd() {
    if (
      this.paginationService &&
      this.paginationService.currentPage <
        (this.paginationService?.totalPages ?? 0)
    ) {
      this.paginationService.currentPage++;
    }
    this.scrollToEnd.emit();
  }

  onClear(event: any) {
    this.clear.emit(event);
    this.selectChange.emit(event);
  }

  onSearch(event: KeyboardEvent) {
    this.typeahead.next((event.target as HTMLInputElement).value);
  }

  selectFilter(select: NgSelectComponent, event: Event) {
    const query = (event.target as HTMLInputElement).value;
    // select.filter();

    if (this.isFirst) {
      this.itemsCopy = this.items;
      this.isFirst = false;
    }

    if (query.length > 0) {
      this.items = [...this.itemsCopy];
      this.items = [
        ...this.items.filter((x) =>
          (x[this.filterKey] as string)
            .toLowerCase()
            .includes(query.toLowerCase())
        ),
      ];
    } else {
      this.items = [...this.itemsCopy];
    }
  }

  ngOnInit(): void {
    if (this.service) {
      this.paginationService = new PaginationService(
        this.service,
        this.pagination,
        this.params,
        this.typeahead,
        this.preload,
        this.concat
      );
      this.paginationService._data$.subscribe((data) => {
        this.items = data;
      });
    }
  }

  isAnyControlRequired() {
    if (!this.control) {
      return false;
    }

    const control = this.control as FormControl;
    return !(!control || !control?.validator);
  }

  ngAfterViewInit(): void {
    const ngControl: NgControl = this.injector.get(NgControl);
    if (ngControl) {
      setTimeout(() => {
        this.control = ngControl.control as FormControl;
      });
    }
  }
}
