import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { InformazioniTecniche } from 'src/app/model/attrezzatura-incidentata';
import { environment } from 'src/environments/environment';
import { DownloadFileHelper } from '../helpers/download-file-helper';
import { Observable } from 'rxjs';
import { mapper } from 'src/app/model/mapper';
import { InformazioniTecnicheDTO } from 'src/app/dto/attrezzatura-incidentata/informazioni-tecniche-dto';

@Injectable()
export class MezzoService {
  private readonly baseUrl = `${environment.baseUrl}/mezzo`;

  constructor(private http: HttpClient) {}

  getDettaglioMezzo(targa: string): Observable<InformazioniTecnicheDTO> {
    let params = new HttpParams();

    params = params.append('targa', targa);

    return this.http.get<InformazioniTecnicheDTO>(this.baseUrl, { params });
  }

  getDettaglioMezzoByTarga(targa: string): Observable<InformazioniTecniche> {
    return this.getDettaglioMezzo(targa).pipe(
      map((result) =>
        result
          ? JSON.parse(
              JSON.stringify(
                mapper.map(
                  result,
                  InformazioniTecniche,
                  InformazioniTecnicheDTO
                )
              )
            )
          : ({} as InformazioniTecniche)
      )
    );
  }
}
