import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { Attachment } from '../../model/attachment';
import { Documento } from 'src/app/model/common/documento';
import { map } from 'rxjs/operators';
import { DownloadFileHelper } from '../helpers/download-file-helper';
import { Dinamica } from 'src/app/model/dipendente-infortunato/dinamica-infortunio/dinamica';

@Injectable()
export class FileService {
  private readonly baseUrl = `${environment.baseUrl}/file`;

  constructor(private http: HttpClient) {}

  download(objectId: string, downloadName: string) {
    return this.http
      .get(`${this.baseUrl}/${objectId}/downloadName/${downloadName}`, {
        observe: 'response',
        responseType: 'blob',
      })
      .pipe(map((res) => DownloadFileHelper.getFileFromHttpResponse(res)));
  }

  upload(attachment: Attachment): Observable<Documento> {
    const encoder = new TextEncoder();
    const fileEncoded = encoder.encode(attachment.file);
    return this.http.post<Documento>(this.baseUrl, {
      file: Array.from(fileEncoded),
      name: attachment.name,
      type: attachment.type,
    });
  }

  delete(objectId: string): Observable<boolean> {
    if (!objectId) {
      return throwError('ObjectId not provided');
    }
    return this.http.delete<boolean>(`${this.baseUrl}/${objectId}`);
  }

  downloadSchedaInfortunio(scheda: Dinamica) {
    let params = new HttpParams();

    params = params.append('ProgressivoScheda', scheda?.progressivoScheda);
    params = params.append('NumeroScheda', scheda?.numeroScheda);
    params = params.append('Dominio', scheda?.dominio);
    params = params.append('DataIntervento', scheda?.dataIntervento);

    return this.http
      .get(`${this.baseUrl}/SchedaInfortunio`, {
        observe: 'response',
        params,
        responseType: 'blob',
      })
      .pipe(map((res) => DownloadFileHelper.getFileFromHttpResponse(res)));
  }
}
