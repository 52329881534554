import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RolesOnlyGuard } from './core/guards/roles-only.guard';
import { AuthGuard } from './core/store/auth/services/auth.guard';
import { ChangePasswordComponent } from './features/login/containers/change-password/change-password.component';
import { RoleEnum } from './model/role';

const routes: Routes = [
  {
    path: 'change/password/:token',
    component: ChangePasswordComponent,
  },
  {
    path: 'account',
    loadChildren: () =>
      import('./features/login/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'home',
    loadChildren: () =>
      import('./features/home/home.module').then((m) => m.HomeModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'infortuni',
    loadChildren: () =>
      import('./features/infortuni/infortuni.module').then(
        (m) => m.InfortuniModule
      ),
    canActivate: [AuthGuard, RolesOnlyGuard],
    data: {
      role: [
        RoleEnum.SEA_USER,
        RoleEnum.SEA_INSERITORE,
        RoleEnum.SEA_RESPONSABILE,
        RoleEnum.SEA_AMMINISTRATORE,
        RoleEnum.SEA_DATORE_LAVORO,
        RoleEnum.SEA_MEDICO_COMPETENTE,
        RoleEnum.SEA_MEDICO_ISPETTIVO,
        RoleEnum.SEA_RESPONSABILE_ISPETTIVO,
        RoleEnum.SEA_RESPONSABILE_SPP,
        RoleEnum.SEA_RESPONSABILE_PS,
        RoleEnum.SEA_RESPONSABILE_SPI,
        RoleEnum.SEA_RESPONSABILE_DCRU,
      ],
    },
  },
  {
    path: 'incidenti',
    loadChildren: () =>
      import('./features/incidenti/incidenti.module').then(
        (m) => m.IncidentiModule
      ),
    canActivate: [AuthGuard, RolesOnlyGuard],
    data: {
      role: [
        RoleEnum.SEA_INSERITORE,
        RoleEnum.SEA_RESPONSABILE,
        RoleEnum.SEA_AMMINISTRATORE,
        RoleEnum.SEA_DATORE_LAVORO,
        RoleEnum.SEA_RESPONSABILE_ISPETTIVO,
      ],
    },
  },
  {
    path: 'miei-infortuni',
    loadChildren: () =>
      import('./features/miei-infortuni/miei-infortuni.module').then(
        (m) => m.MieiInfortuniModule
      ),
    canActivate: [AuthGuard, RolesOnlyGuard],
    data: {
      role: [
        RoleEnum.SEA_USER,
        RoleEnum.SEA_INSERITORE,
        RoleEnum.SEA_AMMINISTRATORE,
      ],
    },
  },
  {
    path: 'manage-manuals',
    loadChildren: () =>
      import('./features/gestione-manuali/gestione-manuali.module').then(
        (m) => m.GestioneManualiModule
      ),
  },
  {
    path: 'manuali-operativi',
    loadChildren: () =>
      import('./features/manuals/manuals.module').then((m) => m.ManualsModule),
    canActivate: [AuthGuard, RolesOnlyGuard],
    data: {
      role: [
        RoleEnum.SEA_USER,
        RoleEnum.SEA_INSERITORE,
        RoleEnum.SEA_RESPONSABILE,
        RoleEnum.SEA_AMMINISTRATORE,
        RoleEnum.SEA_DATORE_LAVORO,
        RoleEnum.SEA_MEDICO_COMPETENTE,
        RoleEnum.SEA_MEDICO_ISPETTIVO,
        RoleEnum.SEA_RESPONSABILE_ISPETTIVO,
        RoleEnum.SEA_RESPONSABILE_SPP,
        RoleEnum.SEA_RESPONSABILE_PS,
        RoleEnum.SEA_RESPONSABILE_SPI,
        RoleEnum.SEA_RESPONSABILE_DCRU,
      ],
    },
  },
  {
    path: 'infortunio',
    loadChildren: () =>
      import('./features/infortunio/infortunio.module').then(
        (m) => m.InfortunioModule
      ),
  },
  {
    path: 'incidente',
    loadChildren: () =>
      import('./features/incidente/incidente.module').then(
        (m) => m.IncidenteModule
      ),
    canActivate: [AuthGuard, RolesOnlyGuard],
    data: {
      role: [RoleEnum.SEA_INSERITORE, RoleEnum.SEA_RESPONSABILE_SPP, RoleEnum.SEA_RESPONSABILE],
    },
  },
  {
    path: 'utenti-esterni',
    loadChildren: () =>
      import('./features/utenti-esterni/utenti-esterni.module').then(
        (m) => m.UtentiEsterniModule
      ),
    canActivate: [AuthGuard, RolesOnlyGuard],
    data: {
      role: [RoleEnum.SEA_RESPONSABILE, RoleEnum.SEA_AMMINISTRATORE],
    },
  },
  {
    path: 'utenti-interni',
    loadChildren: () =>
      import('./features/utenti-interni/utenti-interni.module').then(
        (m) => m.UtentiInterniModule
      ),
    canActivate: [AuthGuard, RolesOnlyGuard],
    data: {
      role: [RoleEnum.SEA_RESPONSABILE, RoleEnum.SEA_AMMINISTRATORE],
    },
  },
  {
    path: 'registro-infortuni',
    loadChildren: () =>
      import('./features/registro-infortuni/registro-infortuni.module').then(
        (m) => m.RegistroInfortuniModule
      ),
    canActivate: [AuthGuard, RolesOnlyGuard],
    data: {
      role: [
        RoleEnum.SEA_RESPONSABILE_ISPETTIVO,
        RoleEnum.SEA_DATORE_LAVORO,
        RoleEnum.SEA_MEDICO_COMPETENTE,
      ],
    },
  },

  {
    path: 'reportistica',
    loadChildren: () =>
      import('./features/reportistica/reportistica.module').then(
        (m) => m.ReportisticaModule
      ),
    canActivate: [AuthGuard, RolesOnlyGuard],
    data: {
      role: [
        RoleEnum.SEA_RESPONSABILE,
        RoleEnum.SEA_DATORE_LAVORO,
        RoleEnum.SEA_AMMINISTRATORE,
      ],
    },
  },
  { path: '**', redirectTo: '/home' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
