import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Option } from 'src/app/model/common/option';
@Injectable()
export class AmbientiOperativiService {


  constructor(private http: HttpClient) { }

  getAmbienti() {
    return this.http.get<Option[]>('/assets/mocks/ambienti-operativi.json');
  }

}
