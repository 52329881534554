import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  QueryList,
  SimpleChanges,
} from '@angular/core';
import { StepperTabComponent } from './stepper-tab.component';

@Component({
  selector: 'shared-stepper-tabs',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div class="steppers sticky-top shadow bg-white p-3">
      <ul class="steppers-header mb-0">
        <li
          *ngFor="let tab of tabs; let index = index"
          (click)="!disabled ? changeTab(index) : null"
          [ngClass]="{
            'active no-line': active === index,
            warning: errors[index]
          }"
        >
          <span class="steppers-number">
            <i
              class="icon material-icons"
              *ngIf="index === active && !errors[index]"
            >
              edit
            </i>
            <i class="icon material-icons" *ngIf="errors[index]">
              report_problem
            </i>

            <span class="number" *ngIf="index !== active && !errors[index]">
              {{ index + 1 }}
            </span>
          </span>
          <span class="label">{{ tab.title }}</span>
        </li>
      </ul>
    </div>

    <div class="p-3">
      <ng-content></ng-content>
    </div>
  `,
})
export class StepperTabsComponent implements OnChanges, AfterContentInit {
  @Input() active = 0;
  @Input() disabled = false;
  @Input() errors: boolean[] = [];
  @Output() navChange = new EventEmitter<number>();

  @ContentChildren(StepperTabComponent) tabs: QueryList<StepperTabComponent> =
    new QueryList<StepperTabComponent>();

  constructor(private cd: ChangeDetectorRef) {}

  ngAfterContentInit(): void {
    // get all active tabs
    const activeTabs = this.tabs.filter((tab) => tab.active);

    // if there is no active tab set, activate the first
    if (activeTabs.length === 0) {
      this.selectTab();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.active && this.tabs) {
      this.selectTab();
    }
  }

  changeTab(index: number) {
    this.active = index ?? this.active;
    this.selectTab();
    this.navChange.emit(this.active);
  }

  selectTab() {
    const tab = this.tabs.get(this.active);

    // deactivate all tabs
    this.tabs.toArray().forEach((t) => (t.active = false));

    // activate the tab the user has clicked on.
    if (tab) {
      tab.active = true;
    }
  }
}
