import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TempoLuogo } from 'src/app/model/dipendente-infortunato/tempo-luogo';

@Component({
  selector: 'vvf-riepilogo-infortunio-tempo-luogo',
  template: `
    <div class="row mt-3">
      <div class="col-12 col-md-6 col-lg-3">
        <vvf-input-date
          [ngModel]="tempoLuogo.dataLuogo.data"
          label="Data"
          [readOnly]="true"
        ></vvf-input-date>
      </div>
      <div class="col-12 col-md-6 col-lg-3">
        <vvf-input-text
          [ngModel]="
            'nuovoInfortunio.stepTempoLuogo.detail.luogo.' +
              tempoLuogo.dataLuogo.luogo | translate
          "
          label="Luogo"
          [readOnly]="true"
        ></vvf-input-text>
      </div>
      <div
        *ngIf="!!tempoLuogo.dataLuogo.ambientiOperativi"
        class="col-12 col-md-6 col-lg-5"
      >
        <vvf-input-text
          [ngModel]="
            'nuovoInfortunio.stepTempoLuogo.detail.ambientiOperativi.' +
              tempoLuogo.dataLuogo.ambientiOperativi | translate
          "
          label="Ambienti operativi"
          [readOnly]="true"
        ></vvf-input-text>
      </div>
      <div
        *ngIf="!!tempoLuogo.dataLuogo.specificaLuogo"
        class="col-12 col-md-6 col-lg-3"
      >
        <vvf-input-text
          [ngModel]="
            'nuovoInfortunio.stepTempoLuogo.detail.specificaLuogo.' +
              tempoLuogo.dataLuogo.specificaLuogo | translate
          "
          label="Specifica luogo"
          [readOnly]="true"
        ></vvf-input-text>
      </div>
      <div
        *ngIf="!!tempoLuogo.dataLuogo.areaOperativa"
        class="col-12 col-md-6 col-lg-5"
      >
        <vvf-input-text
          [ngModel]="
            'nuovoInfortunio.stepTempoLuogo.detail.areaOperativa.' +
              tempoLuogo.dataLuogo.areaOperativa | translate
          "
          label="Area operativa"
          [readOnly]="true"
        ></vvf-input-text>
      </div>
      <div
        *ngIf="!!tempoLuogo.dataLuogo.modalitaInfortunioSpostamenti"
        class="col-12 col-md-6 col-lg-3"
      >
        <vvf-input-text
          [ngModel]="
            'nuovoInfortunio.stepTempoLuogo.detail.modalitaInfortunioSpostamenti.' +
              tempoLuogo.dataLuogo.modalitaInfortunioSpostamenti | translate
          "
          label="Modalità infortunio"
          [readOnly]="true"
        ></vvf-input-text>
      </div>
      <div
        *ngIf="!!tempoLuogo.dataLuogo.modalitaInfortunioItinere"
        class="col-12 col-md-6 col-lg-3"
      >
        <vvf-input-text
          [ngModel]="
            'nuovoInfortunio.stepTempoLuogo.detail.modalitaInfortunioItinere.' +
              tempoLuogo.dataLuogo.modalitaInfortunioItinere | translate
          "
          label="Modalità infortunio"
          [readOnly]="true"
        ></vvf-input-text>
      </div>
      <div
        *ngIf="!!tempoLuogo.dataLuogo.serviziIstituto"
        class="col-12 col-md-6 col-lg-6"
      >
        <vvf-input-text
          [ngModel]="tempoLuogo.dataLuogo.serviziIstituto"
          label="DGS/ODG"
          [readOnly]="true"
        ></vvf-input-text>
      </div>
      <div class="col-12 col-md-6 col-lg-3">
        <vvf-input-text
          [ngModel]="tempoLuogo.dataLuogo.orario"
          label="Orario"
          [readOnly]="true"
        ></vvf-input-text>
      </div>
      <div class="col-12 col-md-6 col-lg-3">
        <vvf-input-text
          [ngModel]="
            tempoLuogo.dataLuogo.orarioLavoroStraordinario | boolToString
          "
          label="Orario di lavoro straordinario?"
          [readOnly]="true"
        ></vvf-input-text>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-6 col-lg-4">
        <vvf-input-text
          [ngModel]="
            'nuovoInfortunio.stepTempoLuogo.detail.sede.' +
              tempoLuogo.indirizzo.sede | translate
          "
          label="Sede accadimento"
          [readOnly]="true"
        ></vvf-input-text>
      </div>
    </div>
    <div *ngIf="tempoLuogo?.indirizzo?.sede != 1" class="row">
      <div class="col-12 col-md-6 col-lg-3">
        <vvf-input-text
          [ngModel]="tempoLuogo.indirizzo.via"
          label="Via"
          [readOnly]="true"
        ></vvf-input-text>
      </div>
      <div class="col-12 col-md-6 col-lg-3">
        <vvf-input-text
          [ngModel]="tempoLuogo.indirizzo.comune"
          label="Comune"
          [readOnly]="true"
        ></vvf-input-text>
      </div>
      <div class="col-12 col-md-6 col-lg-3">
        <vvf-input-text
          [ngModel]="tempoLuogo.indirizzo.provincia"
          label="Provincia"
          [readOnly]="true"
        ></vvf-input-text>
      </div>
      <div class="col-12 col-md-6 col-lg-3">
        <vvf-input-text
          [ngModel]="tempoLuogo.indirizzo.cap"
          label="CAP"
          [readOnly]="true"
        ></vvf-input-text>
      </div>
    </div>

    <div class="row">
      <div
        class="col-12 col-md-6 col-lg-3"
        *ngIf="tempoLuogo?.intervento?.numeroIntervento"
      >
        <vvf-input-text
          [ngModel]="tempoLuogo.intervento.numeroIntervento"
          label="numeroIntervento"
          [readOnly]="true"
        ></vvf-input-text>
      </div>
      <div class="col-12 col-md-6 col-lg-3">
        <ng-container *ngIf="tempoLuogo?.intervento?.nomeFile">
          <div
            class="chip chip-lg"
            (click)="
              download.emit({
                objectId: tempoLuogo.intervento.objectId,
                name: tempoLuogo.intervento.nomeFile
              })
            "
          >
            <span class="chip-label">{{ tempoLuogo.intervento.nomeFile }}</span>
          </div>
        </ng-container>
      </div>
    </div>
  `,
})
export class RiepilogoInfortunioTempoLuogoComponent {
  @Input() tempoLuogo: TempoLuogo = {} as TempoLuogo;
  @Output() download = new EventEmitter<{ objectId: string; name: string }>();
}
