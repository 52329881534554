<div class="account-container">
  <div class="it-header-wrapper">
    <div class="it-header-slim-wrapper bg-primary">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <div class="it-header-slim-wrapper-content">
              <img
                class="navbar-brand"
                src="../../../assets/img/logo-ministero.png"
                alt="logo ministero"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="login-container">
    <div class="logo d-flex justify-content-center">
      <img src="assets/img/logo-vvf.svg" class="logo" alt="logo vvf" />
    </div>
    <form
      [formGroup]="changePasswordForm"
      (ngSubmit)="changePassword()"
      class="form-group"
    >
      <div class="input-group">
        <div class="input-group-prepend bg-transparent">
          <div class="input-group-text">
            <i class="material-icons">lock</i>
          </div>
        </div>
        <input
          [type]="passwordVisible ? 'text' : 'password'"
          class="form-control"
          pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,18}$"
          [placeholder]="'login.password' | translate"
          formControlName="password"
          [ngModel]
        />
        <div class="input-group-append bg-transparent">
          <button
            class="btn"
            type="button"
            (click)="passwordVisible = !passwordVisible"
          >
            <i class="material-icons">{{
              passwordVisible ? "visibility_off" : "visibility"
            }}</i>
          </button>
        </div>
      </div>

      <div class="input-group">
        <div class="input-group-prepend bg-transparent">
          <div class="input-group-text">
            <i class="material-icons">lock</i>
          </div>
        </div>
        <input
          [type]="confirmPasswordVisible ? 'text' : 'password'"
          class="form-control"
          pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*.?&])[A-Za-z\d@$!%*.?&]{8,18}$"
          [placeholder]="'login.confirmPassword' | translate"
          formControlName="confirmPassword"
          [ngModel]
        />
        <div class="input-group-append bg-transparent">
          <button
            class="btn"
            type="button"
            (click)="confirmPasswordVisible = !confirmPasswordVisible"
          >
            <i class="material-icons">{{
              confirmPasswordVisible ? "visibility_off" : "visibility"
            }}</i>
          </button>
        </div>
      </div>

      <button
        type="submit"
        class="btn btn-primary btn-lg btn-block"
        [disabled]="changePasswordForm.invalid"
      >
        {{ "login.salva" | translate }}
      </button>
    </form>
  </div>
</div>
