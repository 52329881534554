import { AutoMap } from '@automapper/classes';
import {
  CamelCaseNamingConvention,
  mapFrom,
  MappingProfile,
} from '@automapper/core';
import { LuogoInfortunioDTO } from 'src/app/dto/dipendente-infortunato/luogo-infortunio-dto';

export class Indirizzo {
  via: string;

  @AutoMap()
  provincia: string;

  @AutoMap()
  cap: string;

  @AutoMap()
  comune: string;

  @AutoMap()
  sede: number;

  @AutoMap()
  specificaSedeInfortunio: string;
}

export const indirizzoProfile: MappingProfile = (mapper) => {
  mapper
    .createMap(LuogoInfortunioDTO, Indirizzo, {
      namingConventions: new CamelCaseNamingConvention(),
    })
    .forMember(
      (dest) => dest.via,
      mapFrom((src) => src.indirizzo)
    )
    .forMember(
      (dest) => dest.sede,
      mapFrom((src) => src.sedeAccadimento)
    )
};
