import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { LoaderService } from '../core/services/loader/loader.service';

@Component({
  selector: 'vvf-loader',
  template: `
    <div *ngIf="isLoading" class="grid-loader">
      <div class="progress-spinner progress-spinner-active">
        <span class="sr-only">Caricamento...</span>
      </div>
    </div>
  `
})
export class LoaderComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];
  isLoading: boolean = false;

  constructor(
    private loaderService: LoaderService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.subscriptions.push(
      this.loaderService.isLoading$.subscribe((isLoading: boolean) => {
        this.isLoading = isLoading;
        this.cd.detectChanges();
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }
}
