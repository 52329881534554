import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'vvf-modal',
  template: `
    <div
      id="backdrop"
      class="modal-backdrop fade"
      [ngClass]="{ show: show }"
    ></div>
    <div
      class="modal d-block fade"
      [ngClass]="{ show: show }"
      (click)="backdrop ? dismissHandler() : null"
      tabindex="-1"
      role="dialog"
    >
      <div
        class="modal-dialog modal-dialog-scrollable {{ className }}"
        role="document"
        (click)="onDialogClick($event)"
      >
        <div class="modal-content">
          <div class="modal-header">
            <ng-content select="[title]"></ng-content>
          </div>
          <div class="modal-body">
            <ng-content select="[body]"></ng-content>
          </div>
          <div class="modal-footer">
            <ng-content select="[footer]"></ng-content>
          </div>
        </div>
      </div>
    </div>
  `,
})
export class ModalComponent {
  @Input() show: boolean;
  @Input() title: string;
  @Input() className: string;
  @Input() backdrop: boolean = true;
  @Output() dismiss: EventEmitter<void> = new EventEmitter<void>();
  @Output() close: EventEmitter<void> = new EventEmitter<void>();

  onDialogClick(event: UIEvent) {
    // Capture click on dialog and prevent it from bubbling to the modal background.
    event.stopPropagation();
    event.cancelBubble = true;
  }

  public dismissHandler() {
    this.show = false;
    setTimeout(() => {
      this.dismiss.emit();
    }, 100);
  }

  public closeHandler() {
    this.show = false;
    setTimeout(() => {
      this.close.emit();
    }, 100);
  }
}
