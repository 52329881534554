import { createSelector } from '@ngrx/store';
import { ProfileState } from '../profile.state';
import { ProfileSelectors } from '../profile.selectors';

export namespace UserSelectors {
  export const getUser = createSelector(
    ProfileSelectors.getProfile,
    (profile: ProfileState) => profile.user
  );

  export const getFullName = createSelector(
    getUser,
    (user) => `${user.name} ${user.surname}`
  );

  export const getInitials = createSelector(
    getUser,
    (user) =>
      `${user.name?.charAt(0).toUpperCase()}${user.surname
        ?.charAt(0)
        .toUpperCase()}`
  );

  export const getRoles = createSelector(getUser, (user) => user.roles);
}
