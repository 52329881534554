import {BehaviorSubject, Observable, Subject, Subscription} from 'rxjs';
import {IPaginationService} from './pagination-iterface.service';
import {Pagination, SortDirection} from '../models/pagination.model';
import {switchMap, tap} from 'rxjs/operators';
import {PaginatedResult} from '../models/paginated-result.model';
import {IPaginatedService} from './paginated-iterface.service';

export class PaginationService<T> implements IPaginationService<T> {

  private subscriptions: Subscription[] = [];

  // tslint:disable-next-line: variable-name
  private _pagination: Pagination = {
    currentPage: 1,
    itemsPerPage: 5,
    orderBy: '',
    searchTerm: '',
    orderDirection: 'asc'
  };

  // tslint:disable-next-line: variable-name
  _params: any = {};
  get params() { return this._params; }
  set params(params: any) {
    this._setParams(params);
  }

  // tslint:disable-next-line: variable-name
  private _search$ = new Subject<void>();

  // tslint:disable-next-line: variable-name
  private readonly _searchTerm$ = new Subject<string>();
  get searchTerm$() { return this._searchTerm$; }

  // tslint:disable-next-line: variable-name
  _data$: BehaviorSubject<T[]> = new BehaviorSubject<T[]>([]);
  get data() { return this._data$.value; }
  set data(data: any) { this.data = data; }
  get data$() { return this._data$.asObservable(); }

  // tslint:disable-next-line: variable-name
  _total$ = new BehaviorSubject<number>(0);
  get total$() { return this._total$.asObservable(); }

  // tslint:disable-next-line: variable-name
  _loading$ = new BehaviorSubject<boolean>(false);
  get loading$() { return this._loading$.asObservable(); }

  // tslint:disable-next-line: variable-name
  _firstTime$ = new BehaviorSubject<boolean>(true);
  get firstTime$() { return this._firstTime$.asObservable(); }

  get currentPage() { return this._pagination.currentPage ?? 0; }
  set currentPage(currentPage: number) {
    if (this._pagination.currentPage !== currentPage && currentPage <= (this._pagination?.totalPages ?? 0)) {
      this._set({ currentPage });
    }
  }

  get itemsPerPage() { return this._pagination.itemsPerPage ?? 0; }
  set itemsPerPage(itemsPerPage: number) {
    if (this._pagination.itemsPerPage !== itemsPerPage) {
      const resto = this._pagination?.totalItems ?? 0 % itemsPerPage;
      const currentPage = resto > 0
        ? Math.floor(this._pagination?.totalItems ?? 0 / itemsPerPage) + 1
        : Math.floor(this._pagination?.totalItems ?? 0 / itemsPerPage);

      if (currentPage < (this._pagination?.currentPage ?? 0)) {
        this._set({itemsPerPage, currentPage});
        return;
      }

      this._set({ itemsPerPage });
    }
  }

  get totalPages() { return this._pagination.totalPages; }

  get orderBy() { return this._pagination.orderBy ?? ''; }
  set orderBy(orderBy: string) { this._set({ orderBy }); }

  get ascending() { return this._pagination.ascending ?? false; }
  set ascending(ascending: boolean) { this._set({ ascending }); }

  get orderDirection() { return this._pagination.orderDirection ?? ''; }
  set orderDirection(orderDirection: SortDirection) { this._set({ orderDirection }); }

  get searchTerm() { return this._pagination.searchTerm ?? ''; }
  set searchTerm(searchTerm: string) { this._setParams({ searchTerm }); }

  protected _set(patch: Partial<Pagination>, search = true) {
    this._pagination = { ...this._pagination, ...patch };
    if (search) {
      this.search();
    }
  }

  private _setParams(params: any) {
    this._params = params;
    this.currentPage = 1;
    this.search();
  }

  constructor(
    private paginatedService: IPaginatedService<T>,
    pagination?: Pagination,
    params?: any,
    searchTerm$?: Subject<string>,
    preload = true,
    concat = false
  ) {
    if (pagination) {
      this._pagination = pagination;
    }

    if (params) {
      this._params = params;
    }

    if (searchTerm$) {
      this._searchTerm$ = searchTerm$;
    }

    this.subscriptions.push(
      this._search$.pipe(
        tap(() => this._loading$.next(true)),
        // debounceTime(200),
        // distinctUntilChanged(),
        switchMap(() => this._search())
      ).subscribe((data: PaginatedResult<T>) => {
        this._loading$.next(false);
        this._firstTime$.next(false);

        concat
          ? this._data$.next([...this._data$.value, ...data.result])
          : this._data$.next(data.result);

        this._pagination = { ...this._pagination, ...data.pagination };

        if (data.pagination) {
          if (data.pagination.totalItems != null) {
            this._total$.next(data.pagination.totalItems);
          }
        }
      }),
      this._searchTerm$.subscribe((searchTerm: string) => this.searchTerm = searchTerm)
    );

    if (preload) {
      this.search();
    }
  }

  sort(pagination: Pagination): void {
    this._set(pagination);
  }

  private _search(): Observable<PaginatedResult<T>> {
    return this.paginatedService.call(this._pagination, this._params);
  }

  search() {
    this._search$.next();
  }

}
