import {
  Component, EventEmitter, Output,
} from '@angular/core';

@Component({
  selector: 'vvf-button-go-back',
  template: `
    <button type="button" class="btn btn-primary go-back mt-5"
          (click)="actionClick.emit()">
            Torna indietro</button>
  `,
})
export class ButtonGoBackComponent {

  @Output() actionClick = new EventEmitter<void>();
}
