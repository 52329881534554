import { PaginationBaseService } from '../../shared/services/pagination-base.service';
import { IPaginatedService } from '../../shared/services/paginated-iterface.service';
import { Pagination } from '../../shared/models/pagination.model';
import { Observable } from 'rxjs';
import { PaginatedResult } from '../../shared/models/paginated-result.model';
import { Comando } from '../../model/comando';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class ComandiService
  extends PaginationBaseService<Comando>
  implements IPaginatedService<Comando>
{
  private readonly baseUrl = `${environment.baseUrl}/comandi`;

  constructor(private http: HttpClient) {
    super();
  }

  call(
    pagination: Pagination | undefined,
    queryParams: any
  ): Observable<PaginatedResult<Comando>> {
    const params = this.getPaginationHttpParams(pagination);
    return this.getPaginatedResult(
      this.http.get<Comando[]>(this.baseUrl, { observe: 'response', params })
    );
  }

  getAll() {
    return this.http.get<Comando[]>(this.baseUrl);
  }
}
