import {
  Directive,
  HostBinding,
  Input,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { AppState } from 'src/app/core/core.module';
import { RoleSelectors } from 'src/app/core/store/profile/store/role/role.selectors';
import { RoleEnum } from 'src/app/model/role';

@Directive({
  selector: '[readOnlyRoles]',
})
export class ReadOnlyRolesDirective {
  private destroy$ = new Subject();
  _isReadonly = false;

  @Input() readOnlyRoles: RoleEnum | RoleEnum[] | null = null;

  constructor(private store: Store<AppState>) {}

  @HostBinding('readOnly') readOnly: boolean;

  ngOnInit(): void {
    if (!this.readOnlyRoles) {
      this.readOnly = false;
      return;
    }

    this.store
      .select(RoleSelectors.rolesOnly, { roles: this.readOnlyRoles })
      .pipe(
        map((allowed) => !!allowed),
        takeUntil(this.destroy$)
      )
      .subscribe((allowed) => (this.readOnly = allowed));
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }
}
