import {Directive, OnDestroy, OnInit, TemplateRef, ViewContainerRef} from '@angular/core';
import {Subject} from 'rxjs';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../core/core.module';
import * as AuthSelectors from '../../core/store/auth/store/auth.selectors';
import {distinctUntilChanged, map, takeUntil} from 'rxjs/operators';

@Directive({
  selector: '[vvfIfNotLogged]'
})
export class IfNotLoggedDirective implements OnInit, OnDestroy {
  destroy$ = new Subject();

  constructor(
    private template: TemplateRef<any>,
    private view: ViewContainerRef,
    private store: Store<AppState>
  ) { }

  ngOnInit(): void {
    this.store.pipe(
      select(AuthSelectors.getToken),
      map(token => !!token),
      distinctUntilChanged(),
      takeUntil(this.destroy$)
    )
      .subscribe(isLogged => {
        if (!isLogged) {
          this.view.createEmbeddedView(this.template);
        } else {
          this.view.clear();
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }
}
