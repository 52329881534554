import { Injectable } from '@angular/core';
import { Fill, Font, Workbook } from 'exceljs';
import { FileSaverService } from './file-saver.service';
import { Reportistica } from './../../model/reportistica';

// FIXME: trovare un'altra libreria per generare i file excel
@Injectable()
export class ExportToExcelService {
  constructor(private readonly fileSaverService: FileSaverService) {}

  exportToExcel(data: Reportistica[]) {
    //Create workbook and worksheet
    const workbook = new Workbook();

    const titles: string[] = [];

    for (const item of data) {
      let anno = 'Anno ' + item.anno.toString();
      // let infortuniRuolo = 'Numero infortuni per ruolo';
      // let amministrativi = 'Amministrativi = ' + item.amministrativi.toString();
      // let dirigentiDirettivi = 'Dirigenti direttivi = ' + item.dirigentiDirettivi.toString();
      // let volontari = 'Volontari = ' + item.volontari.toString();
      let infortuniFasciaOraria = 'Numero infortuni per fascia oraria';
      let ottoNove = 'Otto-Nove = ' + item.ottoNove.toString();
      let noveQuattordici = 'Nove-Quattordici = ' + item.noveQuartordici.toString();
      let quattordiciVenti = 'Quattordici-Venti = ' + item.quartordiciVenti.toString();
      let ventiVentuno = 'Venti-Ventuno = ' + item.ventiVentuno.toString();
      let ventunoDue = 'Ventuno-Due = ' + item.ventunoDue.toString();
      let dueOtto = 'Due-Otto = ' + item.dueOtto.toString();
      let giorniProgonsi = 'Giorni di prognosi';
      let minoreTre = 'Meno di 3 giorni = ' + item.minoreTre.toString();
      let treTrenta = 'Tra 3 e 30 giorni = ' + item.compresoTreTrenta.toString();
      let maggioreNovanta = 'Maggiore di 90 giorni = ' + item.maggioreNovanta.toString();
      let decessiLesioni = 'Decessi e/o lesioni';
      let corpo = 'Corpo = ' + item.corpo.toString();
      let testa = 'Testa = ' + item.testa.toString();
      let piediGambe = 'Piedi e/o gambe = ' + item.piedigambe.toString();
      let corpoIntero = 'Corpo intero = ' + item.corpointero.toString();
      let altro = 'Altro = ' + item.altro.toString();
      let decessi = 'Decessi = ' + item.decessino.toString();
      let infortuniAttivita = 'Numero infortuni per attività';
      let areaEsterna = 'Area esterna = ' + item.areaEsterna.toString();
      let areaInterna = 'Area interna = ' + item.areaInterna.toString();
      let itinere = 'In itinere = ' + item.itinere.toString();
      let spostamento = 'Per spostamento = ' + item.spostamento.toString();
      // titles.push(anno);

      // const altreadyExistsCount = titles.filter((t) => t === anno).length - 1;

      // if (altreadyExistsCount) {
      //   anno += `(${altreadyExistsCount})`;
      // }

      const worksheet = workbook.addWorksheet(anno);

      //Add Row and formatting
      const annoRow = worksheet.addRow([anno]);
      annoRow.font = {
        name: 'Arial',
        family: 4,
        size: 15,
        bold: true,
      };
      // const infortuniRuoloRow = worksheet.addRow([infortuniRuolo]);
      // infortuniRuoloRow.font = {
      //   name: 'Arial',
      //   family: 4,
      //   size: 14,
      //   bold: true,
      // };
      // const amministrativiRow = worksheet.addRow([amministrativi]);
      // amministrativiRow.font = {
      //   name: 'Arial',
      //   family: 4,
      //   size: 12,
      // };
      // const dirigentiDirettiviRow = worksheet.addRow([dirigentiDirettivi]);
      // dirigentiDirettiviRow.font = {
      //   name: 'Arial',
      //   family: 4,
      //   size: 12,
      // };
      // const volontariRow = worksheet.addRow([volontari]);
      // volontariRow.font = {
      //   name: 'Arial',
      //   family: 4,
      //   size: 12,
      // };
      const infortuniFasciaOrariaRow = worksheet.addRow([infortuniFasciaOraria]);
      infortuniFasciaOrariaRow.font = {
        name: 'Arial',
        family: 4,
        size: 14,
        bold: true,
      };
      const ottoNoveRow = worksheet.addRow([ottoNove]);
      ottoNoveRow.font = {
        name: 'Arial',
        family: 4,
        size: 12,
      };
      const noveQuattordiciRow = worksheet.addRow([noveQuattordici]);
      noveQuattordiciRow.font = {
        name: 'Arial',
        family: 4,
        size: 12,
      };
      const quattordiciVentiRow = worksheet.addRow([quattordiciVenti]);
      quattordiciVentiRow.font = {
        name: 'Arial',
        family: 4,
        size: 12,
      };
      const ventiVentunoRow = worksheet.addRow([ventiVentuno]);
      ventiVentunoRow.font = {
        name: 'Arial',
        family: 4,
        size: 12,
      };
      const ventunoDueRow = worksheet.addRow([ventunoDue]);
      ventunoDueRow.font = {
        name: 'Arial',
        family: 4,
        size: 12,
      };
      const dueOttoRow = worksheet.addRow([dueOtto]);
      dueOttoRow.font = {
        name: 'Arial',
        family: 4,
        size: 12,
      };
      const giorniPrognosiRow = worksheet.addRow([giorniProgonsi]);
      giorniPrognosiRow.font = {
        name: 'Arial',
        family: 4,
        size: 14,
        bold: true,
      };
      const minoreTreRow = worksheet.addRow([minoreTre]);
      minoreTreRow.font = {
        name: 'Arial',
        family: 4,
        size: 12,
      };
      const treTrentaRow = worksheet.addRow([treTrenta]);
      treTrentaRow.font = {
        name: 'Arial',
        family: 4,
        size: 12,
      };
      const maggioreNovantaRow = worksheet.addRow([maggioreNovanta]);
      maggioreNovantaRow.font = {
        name: 'Arial',
        family: 4,
        size: 12,
      };
      const decessiLesioniRow = worksheet.addRow([decessiLesioni]);
      decessiLesioniRow.font = {
        name: 'Arial',
        family: 4,
        size: 14,
        bold: true,
      };
      const corpoRow = worksheet.addRow([corpo]);
      corpoRow.font = {
        name: 'Arial',
        family: 4,
        size: 12,
      };
      const testaRow = worksheet.addRow([testa]);
      testaRow.font = {
        name: 'Arial',
        family: 4,
        size: 12,
      };
      const piediGambeRow = worksheet.addRow([piediGambe]);
      piediGambeRow.font = {
        name: 'Arial',
        family: 4,
        size: 12,
      };
      const corpoInteroRow = worksheet.addRow([corpoIntero]);
      corpoInteroRow.font = {
        name: 'Arial',
        family: 4,
        size: 12,
      };
      const altroRow = worksheet.addRow([altro]);
      altroRow.font = {
        name: 'Arial',
        family: 4,
        size: 12,
      };
      const decessiRow = worksheet.addRow([decessi]);
      decessiRow.font = {
        name: 'Arial',
        family: 4,
        size: 12,
      };
      const infortuniAttivitaRow = worksheet.addRow([infortuniAttivita]);
      infortuniAttivitaRow.font = {
        name: 'Arial',
        family: 4,
        size: 14,
        bold: true,
      };
      const areaEsternaRow = worksheet.addRow([areaEsterna]);
      areaEsternaRow.font = {
        name: 'Arial',
        family: 4,
        size: 12,
      };
      const areaInternaRow = worksheet.addRow([areaInterna]);
      areaInternaRow.font = {
        name: 'Arial',
        family: 4,
        size: 12,
      };
      const itinereRow = worksheet.addRow([itinere]);
      itinereRow.font = {
        name: 'Arial',
        family: 4,
        size: 12,
      };
      const spostamentoRow = worksheet.addRow([spostamento]);
      spostamentoRow.font = {
        name: 'Arial',
        family: 4,
        size: 12,
      };

      // worksheet.mergeCells('A1:B2');

      //Blank Row
      // worksheet.addRow([]);

      const color = '#0066CB';

      const fill: Fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {
          argb: color.substring(1),
        },
      };

      const font: Partial<Font> = {
        color: { argb: 'FFFFFF' },
      };

      // const measureRow = worksheet.addRow([
      //   'Misura: ' + item.query.measures[index],
      //   ...dimensions.map(() => ''),
      // ]);
      // measureRow.eachCell((cell) => {
      //   cell.fill = fill;
      //   cell.font = font;
      // });

      // const emptyRow = worksheet.addRow([...dimensions.map(() => ''), '']);
      // emptyRow.eachCell((cell) => {
      //   cell.fill = fill;
      //   cell.font = font;
      // });

      // const dimensionsRow = worksheet.addRow([...dimensions, 'Conteggio']);

      // // Cell Style : Fill and Border
      // dimensionsRow.eachCell((cell) => {
      //   cell.fill = fill;
      //   cell.border = {
      //     top: { style: 'thin' },
      //     left: { style: 'thin' },
      //     bottom: { style: 'thin' },
      //     right: { style: 'thin' },
      //   };
      //   cell.font = font;
      // });

      // for (const key of Object.keys(data)) {
      //   const datasetRow = worksheet.addRow([
      //     key,
      //     item[key as keyof Reportistica],
      //   ]);
      //   datasetRow.eachCell((cell) => {
      //     cell.fill = fill;
      //     cell.font = font;
      //   });
      // }

      worksheet.getColumn(1).width = 50;
      // worksheet.getColumn(2).width = 30;
      // worksheet.addRow([]);
    }

    //Generate Excel File with given name
    workbook.xlsx.writeBuffer().then((data) => {
      this.fileSaverService.saveFile(
        data,
        `report-${new Date().toISOString()}.xlsx`,
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      );
    });
  }
}
