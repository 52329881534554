import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Allegati } from 'src/app/model/dipendente-infortunato/allegati';
import { Lesioni } from 'src/app/model/dipendente-infortunato/lesioni';
import { TempoLuogo } from 'src/app/model/dipendente-infortunato/tempo-luogo';

@Component({
  selector: 'vvf-riepilogo-infortunio-allegati',
  template: `
  <div class="row mt-3" *ngIf="!(!!allegati.attiIstruttori.length) && !(!!allegati.attiIstruttori.length)">
    <h6 class="ml-3">Non ci sono allegati per la pratica selezionata</h6>
  </div>
    <div class="row mt-3">
      <div class="col-12 col-md-6 col-lg-3" *ngIf="!!allegati.attiIstruttori.length">
        <vvf-input-text
          [ngModel]="allegati.attiIstruttori"
          label="Atti istruttori"
          [readOnly]="true"
        ></vvf-input-text>
      </div>
      <div class="col-12 col-md-6 col-lg-3" *ngIf="!!allegati.documentazioneSanitaria.length">
        <vvf-input-text
          [ngModel]="allegati.documentazioneSanitaria"
          label="Documentazione sanitaria"
          [readOnly]="true"
        ></vvf-input-text>
      </div>
    </div>
  `,
})
export class RiepilogoInfortunioAllegatiComponent {
  @Input() allegati: Allegati = {} as Allegati;
}
