import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Option } from 'src/app/model/common/option';
@Injectable()
export class TipologiaSpostamentoService {
  constructor(private http: HttpClient) {}

  getTipologiaSpostamenti() {
    return this.http.get<Option[]>('/assets/mocks/tipologia-spostamento.json');
  }
}
