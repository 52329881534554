import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { TipoPratica } from '../../../../model/enum/tipo-pratica';
import { Configuration } from '../store/configuration.state';
import { map } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { AmbientiOperativiService } from 'src/app/core/services/ambienti-operativi.service';
import { TipologiaSpostamentoService } from 'src/app/core/services/tipologia-spostamento.service';
import { TipologiaItinereService } from 'src/app/core/services/tipologia-itinere.service';
import { AreaOperativaService } from 'src/app/core/services/area-operativa.service';

@Injectable()
export class ConfigurationService {
  private readonly baseUrl = `${environment.baseUrl}/configuration`;

  constructor(
    private http: HttpClient,
    private ambientiOperativiService: AmbientiOperativiService,
    private tipologiaSpostamentoService: TipologiaSpostamentoService,
    private tipologiaItinereService: TipologiaItinereService,
    private areaOperativaService: AreaOperativaService
  ) {}

  configurations$ = (tipoPratica: TipoPratica) =>
    combineLatest([
      this.getConfiguration(tipoPratica),
      this.getConfiguration(TipoPratica.AttrezzaturaIncidentata),
    ]).pipe(
      map(([configurations, attrezzature]) => {
        return {
          typologics: [
            ...configurations.typologics,
            ...attrezzature.typologics,
          ],
        };
      })
    );

  getConfiguration(tipoPratica: TipoPratica) {
    return this.http.get<Configuration>(`${this.baseUrl}/${tipoPratica}`);
  }
}
