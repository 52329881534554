import { createSelector } from '@ngrx/store';
import { Role, RoleEnum } from 'src/app/model/role';
import { ProfileSelectors } from '../profile.selectors';

export namespace RoleSelectors {
  export const getRole = createSelector(
    ProfileSelectors.getProfile,
    (profile) => profile.role
  );

  export const getCommand = createSelector(
    getRole,
    (role) => role.codice_comando
  );

  export const getRoleName = createSelector(getRole, (role) => role.role_name);

  export const rolesOnly = createSelector(
    getRole,
    (role: Role, props: { roles: RoleEnum | RoleEnum[] }) => {
      const rolesToCheck: RoleEnum[] = Array.isArray(props.roles)
        ? props.roles
        : [props.roles];

      return rolesToCheck.includes(role.role_name);
    }
  );

  export const rolesExcept = createSelector(
    getRole,
    (role: Role, props: { roles: RoleEnum | RoleEnum[] }) => {
      const rolesToCheck: RoleEnum[] = Array.isArray(props.roles)
        ? props.roles
        : [props.roles];

      return !rolesToCheck.includes(role.role_name);
    }
  );
}
