import { AutoMap } from '@automapper/classes';
import { CamelCaseNamingConvention, mapFrom, MappingProfile } from '@automapper/core';
import { LuogoInfortunioDTO } from 'src/app/dto/dipendente-infortunato/luogo-infortunio-dto';

export class Intervento {
  @AutoMap()
  objectId: string;

  @AutoMap()
  nomeFile: string;

  @AutoMap()
  numeroIntervento: string;

  @AutoMap()
  tipologiaInterventoInfortunio: number;

  @AutoMap()
  tipo: string;
}

export const interventoProfile: MappingProfile = (mapper) => {
  mapper
    .createMap(LuogoInfortunioDTO, Intervento, {
      namingConventions: new CamelCaseNamingConvention(),
    });
};
