import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Reportistica, ReportisticaFilters } from 'src/app/model/reportistica';
import { environment } from 'src/environments/environment';

@Injectable()
export class ReportisticaService {
  private baseUrl = `${environment.baseUrl}/Pratiche`;

  constructor(private http: HttpClient) {}

  getStatisticheInfortuni(filters: ReportisticaFilters) {
    let params = new HttpParams();

    if (filters?.comando) {
      params = params.append('Comando', filters.comando);
    }

    if (filters?.provincia) {
      params = params.append('Provincia', filters.provincia?.nome);
    }

    if (filters?.regione) {
      params = params.append('Regione', filters.regione);
    }

    if (filters?.area) {
      params = params.append('Area', filters.area);
    }

    if (filters?.anno) {
      params = params.append('Anno', filters.anno.toString());
    }

    if (filters?.luogoInfortunio) {
      params = params.append(
        'LuogoInfortunio',
        filters.luogoInfortunio.toString()
      );
    }

    if (filters?.qualifica) {
      params = params.append('Qualifica', filters.qualifica.toString());
    }

    if (filters?.groupBy) {
      params = params.append('GroupBy', filters.groupBy?.value?.toString());
    }

    return this.http.get<Reportistica[]>(
      `${this.baseUrl}/Infortuni/Statistiche`,
      { params }
    );
  }

  getStatisticheAttrezzature(filters: ReportisticaFilters) {
    let params = new HttpParams();

    if (filters?.comando) {
      params = params.append('Comando', filters.comando);
    }

    if (filters?.provincia) {
      params = params.append('Provincia', filters.provincia?.nome);
    }

    if (filters?.regione) {
      params = params.append('Regione', filters.regione);
    }

    if (filters?.area) {
      params = params.append('Area', filters.area);
    }

    if (filters?.anno) {
      params = params.append('Anno', filters.anno.toString());
    }

    if (filters?.luogoInfortunio) {
      params = params.append(
        'LuogoInfortunio',
        filters.luogoInfortunio.toString()
      );
    }

    if (filters?.qualifica) {
      params = params.append('Qualifica', filters.qualifica.toString());
    }

    if (filters?.groupBy) {
      params = params.append('GroupBy', filters.groupBy?.value?.toString());
    }

    return this.http.get<Reportistica[]>(
      `${this.baseUrl}/AttrezzatureIncidentate/Statistiche`,
      { params }
    );
  }
}
