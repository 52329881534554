import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import {
  AllowedIncidentiActions,
  FascicoloIncidenteGridItem,
  IncidenteAction,
  IncidenteActionType,
  IncidentiAction,
  IncidentiActionType,
} from 'src/app/model/incidente';

@Component({
  selector: 'vvf-incidenti-grid-actions',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <button
      class="btn btn-xs"
      *ngFor="let action of allowedActionsArray"
      (click)="$event.stopPropagation(); actionHandler(action)"
      [ngbTooltip]="'infortuni.grid.actions.' + action | translate"
    >
      <i class="material-icons text-secondary">{{ actionButtons[action] }}</i>
    </button>
  `,
})
export class IncidentiGridActionsComponent {
  @Input() index = -1;
  @Input() fascicolo: FascicoloIncidenteGridItem =
    {} as FascicoloIncidenteGridItem;
  @Output() actionClick = new EventEmitter<IncidentiAction>();

  IncidentiActionType = IncidentiActionType;

  get allowedActions(): AllowedIncidentiActions {
    return this.fascicolo?.allowedActions ?? {};
  }

  get allowedActionsArray(): string[] {
    return Object.keys(this.allowedActions);
  }

  actionButtons = {
    [IncidentiActionType.DELETE as string]: 'delete',
    [IncidentiActionType.EDIT as string]: 'edit',
    [IncidentiActionType.VIEW as string]: 'visibility',
    [IncidentiActionType.PROTOCOLLA as string]: 'send',
  };

  actionHandler(actionType: string) {
    this.actionClick.emit({
      index: this.index,
      id: this.fascicolo.id,
      actionType: actionType as IncidentiActionType,
    });
  }
}
