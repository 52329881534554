import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs/operators';
import { AppState } from '../../core.module';
import { MessagesActions } from './messages.actions';
import { v4 as uuidv4 } from 'uuid';

@Injectable()
export class MessagesEffects {
  show$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MessagesActions.show),
      map(({ messageType, title, text, dismissable, autoDismiss }) => {
        const id = uuidv4();
        const isDismissable = dismissable === undefined || dismissable === true;
        const isAutoDismiss = autoDismiss === undefined || autoDismiss === true;

        if (isAutoDismiss) {
          setTimeout(() => {
            this.store.dispatch(MessagesActions.dismiss({ id }));
          }, 6000);
        }

        return MessagesActions.showComplete({
          id,
          messageType,
          title: this.translate.instant(title),
          text: this.translate.instant(text),
          dismissable: isDismissable,
        });
      })
    )
  );

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private translate: TranslateService
  ) {}
}
