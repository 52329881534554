import { AutoMap } from '@automapper/classes';
import { Documento } from '../../common/documento';

export class CertificatoMedico {
  id: string;

  @AutoMap()
  dataInizio: string;

  @AutoMap()
  dataFine: string;

  documento: Documento;
}
