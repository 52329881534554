import {
  CamelCaseNamingConvention,
  mapFrom,
  MappingProfile,
  mapWith,
} from '@automapper/core';
import { DipendenteInfortunatoDTO } from 'src/app/dto/dipendente-infortunato';
import { TestimoneDTO } from 'src/app/dto/dipendente-infortunato/testimone-dto';
import { Testimone } from './testimone';

export class Testimoni {
  enabled: boolean;

  items: Testimone[];
}

export const testimoniProfile: MappingProfile = (mapper) => {
  mapper
    .createMap(DipendenteInfortunatoDTO, Testimoni, {
      namingConventions: new CamelCaseNamingConvention(),
    })
    .forMember(
      (dest) => dest.items,
      mapWith(TestimoneDTO, Testimone, (src) => src.testimoni)
    )
    .forMember(
      (dest) => dest.enabled,
      mapFrom((src) => src.testimoniEnabled)
    );
};
