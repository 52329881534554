import {createFeatureSelector, createSelector} from '@ngrx/store';
import {AppState} from '../../../core.module';
import {AuthState} from './auth.reducer';

export const getAuth = createFeatureSelector<AppState, AuthState>('auth');

export const getToken = createSelector(
  getAuth,
  (auth) => auth.auth.accessToken
);
