import { createAction, props } from '@ngrx/store';
import { MessageType } from '../../../shared/models/message';

export namespace MessagesActions {
  export const show = createAction(
    '[Messages] show',
    props<{
      messageType: MessageType;
      title: string;
      text: string;
      dismissable?: boolean;
      autoDismiss?: boolean;
    }>()
  );

  export const showComplete = createAction(
    '[Messages] show complete',
    props<{
      id: string;
      messageType: MessageType;
      title: string;
      text: string;
      dismissable: boolean;
    }>()
  );

  export const dismiss = createAction(
    '[Messages] dismiss',
    props<{ id: string }>()
  );
}
