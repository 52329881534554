import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Attachment } from '../../../model/attachment';

@Component({
  selector: 'vvf-file',
  template: `
    <input
      type="file"
      #fileInput
      [attr.id]="id"
      name="fileUpload"
      [multiple]="multiple"
      [accept]="accept"
      style="display: none;"
    />

    <ng-content></ng-content>

    <div class="invalid-feedback" *ngIf="!!error">
      {{ error }}
    </div>
  `,
})
export class FileComponent implements OnChanges {
  @Input() id: string;
  @Input() accept = '*';
  @Input() multiple: boolean;
  @Input() error: string;

  @ViewChild('fileInput', { static: false }) fileInput: ElementRef;

  @Output() uploadFile: EventEmitter<Attachment> =
    new EventEmitter<Attachment>();

  constructor(private translate: TranslateService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.accept) {
      this.accept = changes.accept.currentValue
        ? changes.accept.currentValue.split(';').toString()
        : '*';
    }
  }

  public upload() {
    const fileInput = this.fileInput.nativeElement;

    fileInput.onchange = () => {
      const accept = this.accept ?? '*';

      for (const file of fileInput.files) {
        let attachment: Attachment;
        const reader = new FileReader();

        reader.onload = (e) => {
          attachment = {
            file: e.target?.result as string,
            name: file.name,
            type: file.type,
          };

          if (accept === '*') {
            this.uploadFile.emit(attachment);
            return;
          }

          const accepted =
            this.accept.split(';').findIndex((a) => a === attachment.type) >= 0;

          if (accepted) {
            this.uploadFile.emit(attachment);
            return;
          }

          this.error = this.translate.instant('fileUploader.formatNotValid');
        };

        reader.readAsText(file);
      }

      this.fileInput.nativeElement.value = '';
    };

    fileInput.click();
  }
}
