import { PaginationBaseService } from '../../shared/services/pagination-base.service';
import { IPaginatedService } from '../../shared/services/paginated-iterface.service';
import { Injectable } from '@angular/core';
import { Pagination } from '../../shared/models/pagination.model';
import { Observable } from 'rxjs';
import { PaginatedResult } from '../../shared/models/paginated-result.model';
import { HttpClient } from '@angular/common/http';
import { BaseRole } from '../../model/base-role';
import { environment } from '../../../environments/environment';

@Injectable()
export class RuoliService
  extends PaginationBaseService<BaseRole>
  implements IPaginatedService<BaseRole>
{
  private readonly baseUrl = `${environment.baseUrl}/roles`;

  constructor(private http: HttpClient) {
    super();
  }

  call(
    pagination: Pagination | undefined,
    queryParams: any
  ): Observable<PaginatedResult<BaseRole>> {
    let params = this.getPaginationHttpParams(pagination);

    if (queryParams?.external) {
      params = params.append('external', queryParams?.external);
    }

    return this.getPaginatedResult(
      this.http.get<BaseRole[]>(this.baseUrl, { observe: 'response', params })
    );
  }
}
