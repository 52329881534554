import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ProfileState } from './profile.state';
import { AppState } from '../../../core.module';

export namespace ProfileSelectors {
  export const getProfile = createFeatureSelector<AppState, ProfileState>(
    'profile'
  );

  export const getProfileRoles = createSelector(
    getProfile,
    (state: ProfileState) => state.user.roles
  );
}
