import { AutoMap } from '@automapper/classes';
import {
  CamelCaseNamingConvention,
  mapFrom,
  MappingProfile,
  mapWith,
  preCondition,
} from '@automapper/core';
import { DipendenteInfortunatoDTO } from 'src/app/dto/dipendente-infortunato';
import { Attrezzatura } from './attrezzatura';
import { AttrezzaturaDTO } from './../../../../dto/dipendente-infortunato/attrezzatura-dto';

export class Attrezzature {
  @AutoMap()
  enabled: boolean;

  @AutoMap({ typeFn: () => Attrezzatura })
  items: Attrezzatura[];
}

export const attrezzatureProfile: MappingProfile = (mapper) => {
  mapper
    .createMap(AttrezzaturaDTO, Attrezzatura, {
      namingConventions: new CamelCaseNamingConvention(),
    })
    .forMember(
      (dest) => dest.categoriaAttrezzatura?.id,
      preCondition((src) => !!src?.categoriaAttrezzaturaIdParent, null),
      mapFrom((src) => src.categoriaAttrezzaturaIdParent)
    )
    .forMember(
      (dest) => dest.attrezzaturaId,
      preCondition((src) => !!src?.categoriaAttrezzaturaId, null),
      mapFrom((src) => src.categoriaAttrezzaturaId)
    );

  mapper
    .createMap(Attrezzatura, AttrezzaturaDTO, {
      namingConventions: new CamelCaseNamingConvention(),
    })
    .forMember(
      (dest) => dest.categoriaAttrezzaturaId,
      preCondition((src) => !!src?.categoriaAttrezzatura?.id, null),
      mapFrom((src) => src.attrezzaturaId)
    )
    .forMember(
      (dest) => dest.categoriaAttrezzaturaIdParent,
      preCondition((src) => !!src?.categoriaAttrezzatura?.id, null),
      mapFrom((src) => src.categoriaAttrezzatura.id)
    );

  mapper
    .createMap(DipendenteInfortunatoDTO, Attrezzature, {
      namingConventions: new CamelCaseNamingConvention(),
    })
    .forMember(
      (dest) => dest.enabled,
      mapFrom((src) => src.attrezzatureEnabled)
    )
    .forMember(
      (dest) => dest.items,
      mapWith(Attrezzatura, AttrezzaturaDTO, (src) => src.attrezzature)
    );
};
