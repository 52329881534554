import { AutoMap } from '@automapper/classes';
import {
  CamelCaseNamingConvention,
  mapFrom,
  MappingProfile,
  mapWith,
  preCondition,
} from '@automapper/core';
import { DipendenteInfortunatoDTO } from 'src/app/dto/dipendente-infortunato';
import { MezzoDTO } from 'src/app/dto/dipendente-infortunato/mezzo-dto';
import { Mezzo } from './mezzo';

export class Mezzi {
  @AutoMap()
  enabled: boolean;

  @AutoMap({ typeFn: () => Mezzo })
  items: Mezzo[];
}

export const mezziProfile: MappingProfile = (mapper) => {
  mapper
    .createMap(MezzoDTO, Mezzo, {
      namingConventions: new CamelCaseNamingConvention(),
    })
    .forMember(
      (dest) => dest.categoriaAttrezzatura?.id,
      preCondition((src) => !!src?.categoriaAttrezzaturaIdParent, null),
      mapFrom((src) => src.categoriaAttrezzaturaIdParent)
    )
    .forMember(
      (dest) => dest.attrezzaturaId,
      preCondition((src) => !!src?.categoriaAttrezzaturaId, null),
      mapFrom((src) => src.categoriaAttrezzaturaId)
    )
    .forMember(
      (dest) => dest.targa,
      preCondition((src) => !!src?.targa, null),
      mapFrom((src) => src.targa)
    )
    .forMember(
      (dest) => dest.sigla,
      preCondition((src) => !!src?.sigla, null),
      mapFrom((src) => src.sigla)
    )
    .forMember(
      (dest) => dest.codiceGenereMezzo,
      preCondition((src) => !!src?.codiceGenereMezzo, null),
      mapFrom((src) => src.codiceGenereMezzo)
    )
    .forMember(
      (dest) => dest.descrizioneGenereMezzo,
      preCondition((src) => !!src?.descrizioneGenereMezzo, null),
      mapFrom((src) => src.descrizioneGenereMezzo)
    )
    .forMember(
      (dest) => dest.tipoGenereMezzo,
      preCondition((src) => !!src?.tipoGenereMezzo, null),
      mapFrom((src) => src.tipoGenereMezzo)
    );

  mapper
    .createMap(Mezzo, MezzoDTO, {
      namingConventions: new CamelCaseNamingConvention(),
    })
    .forMember(
      (dest) => dest.categoriaAttrezzaturaId,
      preCondition((src) => !!src?.categoriaAttrezzatura?.id, null),
      mapFrom((src) => src.attrezzaturaId)
    )
    .forMember(
      (dest) => dest.categoriaAttrezzaturaIdParent,
      preCondition((src) => !!src?.categoriaAttrezzatura?.id, null),
      mapFrom((src) => src.categoriaAttrezzatura.id)
    )
    .forMember(
      (dest) => dest.targa,
      preCondition((src) => !!src?.targa, null),
      mapFrom((src) => src.targa)
    )
    .forMember(
      (dest) => dest.sigla,
      preCondition((src) => !!src?.sigla, null),
      mapFrom((src) => src.sigla)
    )
    .forMember(
      (dest) => dest.codiceGenereMezzo,
      preCondition((src) => !!src?.codiceGenereMezzo, null),
      mapFrom((src) => src.codiceGenereMezzo)
    )
    .forMember(
      (dest) => dest.descrizioneGenereMezzo,
      preCondition((src) => !!src?.descrizioneGenereMezzo, null),
      mapFrom((src) => src.descrizioneGenereMezzo)
    )
    .forMember(
      (dest) => dest.tipoGenereMezzo,
      preCondition((src) => !!src?.tipoGenereMezzo, null),
      mapFrom((src) => src.tipoGenereMezzo)
    );

  mapper
    .createMap(DipendenteInfortunatoDTO, Mezzi, {
      namingConventions: new CamelCaseNamingConvention(),
    })
    .forMember(
      (dest) => dest.enabled,
      mapFrom((src) => src.mezziEnabled)
    )
    .forMember(
      (dest) => dest.items,
      mapWith(Mezzo, MezzoDTO, (src) => src.mezzi)
    );
};
