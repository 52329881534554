import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { InfortuniFilters } from 'src/app/model/infortunio';
import { TypedFormControls } from '../../models/form/typed-form-controls';

@Component({
  selector: 'vvf-infortuni-filters-form',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div class="container-fluid px-4 py-3 mb-3 bg-color">
      <h5 class="primary-color">{{ 'common.research' | translate }}</h5>
      <form
        [formGroup]="form"
        [connectForm]="filters"
        [debounceTime]="300"
        (changeForm)="valueChange.emit($event)"
        class="form-row mt-5"
      >
        <div class="form-group col-md-4">
          <input
            type="text"
            class="form-control"
            id="formCodiceFiscale"
            formControlName="fiscalCode"
          />
          <label
            for="formCodiceFiscale"
            [ngClass]="{ active: !!filters.fiscalCode }"
            >{{ 'fascicoli.filters.fiscalCode' | translate }}</label
          >
        </div>
        <div [class.col-md-4]="isRegistro" class="form-group col-md-3">
          <input
            type="text"
            class="form-control"
            id="formNome"
            formControlName="name"
          />
          <label for="formNome" [ngClass]="{ active: !!filters.name }">{{
            'fascicoli.filters.name' | translate
          }}</label>
        </div>
        <div [class.col-md-4]="isRegistro" class="form-group col-md-3">
          <input
            type="text"
            class="form-control"
            id="formCognome"
            formControlName="surname"
          />
          <label for="formCognome" [ngClass]="{ active: !!filters.surname }">{{
            'fascicoli.filters.surname' | translate
          }}</label>
        </div>
        <div *ngIf="!isRegistro" class="form-group col-md-2">
          <button
            class="btn btn-icon"
            data-toggle="collapse"
            (click)="togglePanelShow.emit(!isCollapsed)"
            [attr.aria-expanded]="!isCollapsed"
          >
            <span class="primary-color">{{
              'fascicoli.filters.showMoreFields' | translate
            }}</span>
            <span class="icon icon-primary material-icons"> expand_less </span>
          </button>
        </div>
        <div
          class="col-12"
          #collapse="ngbCollapse"
          [(ngbCollapse)]="isCollapsed"
        >
          <div class="form-row">
            <div class="form-group col-md-3">
              <div class="input-group">
                <label
                  for="input-group-data-evento"
                  [ngClass]="{ active: filters.eventDate }"
                >
                  {{ 'fascicoli.filters.eventDate' | translate }}
                </label>
                <input
                  type="text"
                  class="form-control"
                  id="input-group-data-evento"
                  formControlName="eventDate"
                  ngbDatepicker
                  #eventDatePicker="ngbDatepicker"
                />
                <div class="input-group-append">
                  <button
                    class="btn btn-icon"
                    type="button"
                    (click)="eventDatePicker.toggle()"
                  >
                    <span class="icon icon-primary material-icons">
                      event
                    </span>
                  </button>
                </div>
              </div>
            </div>
            <div *ngIf="!isRegistro" class="form-group col-md-3">
              <div class="input-group">
                <label
                  for="input-group-data-invio"
                  [ngClass]="{ active: filters.sendDate }"
                >
                  {{ 'fascicoli.filters.sendDate' | translate }}
                </label>
                <input
                  type="text"
                  class="form-control"
                  id="input-group-data-invio"
                  formControlName="sendDate"
                  ngbDatepicker
                  #sendDatePicker="ngbDatepicker"
                />
                <div class="input-group-append">
                  <button
                    class="btn btn-icon"
                    type="button"
                    (click)="sendDatePicker.toggle()"
                  >
                    <span class="icon icon-primary material-icons">
                      event
                    </span>
                  </button>
                </div>
              </div>
            </div>
            <div class="col-4">
              <vvf-select
                label="Stato infortunio"
                formControlName="stato"
                [items]="statoInfortunio"
                bindLabel="descrizione"
                bindValue="id"
              ></vvf-select>
            </div>
          </div>
        </div>
      </form>

      <div class="row">
        <button class="btn btn-primary btn-icon mr-2" (click)="search.emit()">
          <span>{{ 'common.search' | translate }}</span>
          <i class="icon icon-white material-icons">search</i>
        </button>
        <button
          type="reset"
          class="btn btn-sm btn-icon"
          (click)="resetHandler()"
          (click)="clearItems.emit()"
        >
          <span class="primary-color">{{ 'common.reset' | translate }}</span>
          <i class="icon icon-primary material-icons">close</i>
        </button>
      </div>
    </div>
  `,
})
export class InfortuniFiltersComponent {
  statoInfortunio = [
    { id: 1, descrizione: 'Bozza' },
    { id: 2, descrizione: 'Inserito' },
    { id: 3, descrizione: 'Rifiutata' },
    { id: 4, descrizione: 'Approvata' },
    { id: 5, descrizione: 'Protocollata' },
    { id: 6, descrizione: 'Cancellata' },
    { id: 7, descrizione: 'Da protocollare' },
  ];

  @Input() filters: InfortuniFilters = {} as InfortuniFilters;
  @Input() isCollapsed = true;
  @Input() isRegistro = false;
  @Output() togglePanelShow = new EventEmitter<boolean>();
  @Output() valueChange = new EventEmitter<InfortuniFilters>();
  @Output() search = new EventEmitter<Partial<InfortuniFilters>>();
  @Output() reset = new EventEmitter<void>();
  @Output() clearItems = new EventEmitter<void>();

  private controls: TypedFormControls<InfortuniFilters> = {
    fiscalCode: new FormControl(),
    name: new FormControl(),
    surname: new FormControl(),
    eventDate: new FormControl(),
    sendDate: new FormControl(),
    stato: new FormControl(),
  };

  form = new FormGroup(this.controls);

  resetHandler() {
    this.form.reset();
    this.reset.emit();
  }
}
