import { CamelCaseNamingConvention, MappingProfile } from '@automapper/core';
import { InformazioniTecnicheDTO } from 'src/app/dto/attrezzatura-incidentata/informazioni-tecniche-dto';
import { InformazioniTecniche } from '../../attrezzatura-incidentata';

export const InformazioniTecnicheProfile: MappingProfile = (mapper) => {
  mapper.createMap(InformazioniTecniche, InformazioniTecnicheDTO, {
    namingConventions: new CamelCaseNamingConvention(),
  });
  mapper.createMap(InformazioniTecnicheDTO, InformazioniTecniche, {
    namingConventions: new CamelCaseNamingConvention(),
  });
};
