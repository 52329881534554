import { AutoMap } from '@automapper/classes';

export class DocumentoDTO {
  @AutoMap()
  objectId: string;

  @AutoMap()
  name: string;

  @AutoMap()
  type: string;
}
