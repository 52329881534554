import {
  Pipe,
  PipeTransform
} from '@angular/core';
import {NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';

@Pipe({name: 'ngbDateStruct'})
export class NgbDateStructPipe implements PipeTransform {
  transform(value: number, ...args: any[]): NgbDateStruct {
    if (!value) {
      return {} as NgbDateStruct;
    }
    const date = new Date(+value);
    return {day: date.getDate(), month: date.getMonth() + 1, year: date.getFullYear()};
  }
}
