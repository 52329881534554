import {Directive, ElementRef, HostListener, Input} from '@angular/core';

@Directive({
  selector: '[onlyIntegers]'
})
export class OnlyIntegersDirective {
  @Input() isCurrency = false;

  constructor(private el: ElementRef) {
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    (this.el.nativeElement as HTMLInputElement).value.trim();

    if ([8, 46].indexOf(event.keyCode) !== -1 ||
      // Allow: Ctrl+A
      (event.keyCode === 65 && (event.ctrlKey || event.metaKey)) ||
      // Allow: Ctrl+C
      (event.keyCode === 67 && (event.ctrlKey || event.metaKey)) ||
      // Allow: Ctrl+V
      (event.keyCode === 86 && (event.ctrlKey || event.metaKey)) ||
      // Allow: Ctrl+X
      (event.keyCode === 88 && (event.ctrlKey || event.metaKey)) ||
      // Allow: home, end, left, right
      (event.keyCode >= 35 && event.keyCode <= 39)) {
      // let it happen, don't do anything
      return;
    }

    if (this.isCurrency) {
      // avoid multiple pointers
      if (event.keyCode === 110 || event.keyCode === 188 || event.keyCode === 190) {
        if (!(event.target as HTMLInputElement).value.includes('.')) {
          (this.el.nativeElement as HTMLInputElement).value += '.';
        }
        event.preventDefault();
        return;
      }

      // max two decimal digits
      const value = (event.target as HTMLInputElement).value;
      const values = value.split('.');
      if (values.length > 1) {
        const decimals = values[1];
        if (decimals.length > 1) {
          event.preventDefault();
          return;
        }
      }
    }

    // Ensure that it is a number and stop the keypress
    if (event.shiftKey || !((event.keyCode >= 48 && event.keyCode <= 57) || (event.keyCode >= 96 && event.keyCode <= 105))) {
      event.preventDefault();
    }
  }
}
