import { Component, Input } from '@angular/core';
import { InformazioniTecniche } from 'src/app/model/dipendente-infortunato/informazioni-tecniche';

@Component({
  selector: 'vvf-registro-infortuni-altre-informazioni',
  template: `
    <div class="row mt-3 m-0">
      <div class="col-12">
        <vvf-input-text
          [ngModel]="informazioniTecniche.altrePersone | boolToString"
          [label]="
            'nuovoInfortunio.stepInformazioniTecniche.otherInformations.question'
              | translate
          "
          [readOnly]="true"
        ></vvf-input-text>
      </div>
      <div class="col-12">
        <vvf-input-text
          [ngModel]="informazioniTecniche.testimoni.enabled | boolToString"
          [label]="
            'nuovoInfortunio.stepInformazioniTecniche.witnesses.question'
              | translate
          "
          [readOnly]="true"
        ></vvf-input-text>
      </div>
      <table
        class="table table-sm table-bordered"
        *ngIf="informazioniTecniche.testimoni.items.length"
      >
        <thead>
          <tr>
            <th scope="col" class="text-center">#</th>
            <th scope="col">
              {{
                'nuovoInfortunio.stepInformazioniTecniche.witnesses.grid.witness'
                  | translate
              }}
            </th>
            <th scope="col">
              {{
                'nuovoInfortunio.stepInformazioniTecniche.witnesses.grid.internalWitness'
                  | translate
              }}
            </th>
          </tr>
        </thead>
        <tbody>
          <ng-container>
            <tr
              *ngFor="
                let item of informazioniTecniche.testimoni.items;
                index as index;
                trackBy: trackByFn
              "
            >
              <td class="text-center align-middle">
                {{ index + 1 }}
              </td>
              <td class="align-middle">
                <span class="text-secondary font-weight-bold">
                  {{ item.nome }} {{ item.cognome }}
                </span>
              </td>
              <td class="align-middle">
                <span class="text-secondary font-weight-bold">
                  {{ item.interno | boolToString }}
                </span>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>

    <div class="row mt-5" *ngIf="informazioniTecniche.lavorazioneSvolta">
      <div class="col-12 col-md-6 col-md-4">
        <vvf-input-text
          [ngModel]="informazioniTecniche.lavorazioneSvolta"
          [readOnly]="true"
          [label]="'Lavorazione svolta'"
        ></vvf-input-text>
        <vvf-input-text
          [ngModel]="
            'nuovoInfortunio.stepInformazioniTecniche.detail.tipoSoccorso.' +
              informazioniTecniche.tipoSoccorso | translate
          "
          [readOnly]="true"
          [label]="
            'nuovoInfortunio.stepInformazioniTecniche.rescue.rescueType'
              | translate
          "
        ></vvf-input-text>
      </div>
    </div>
  `,
})
export class AltreInformazioniComponent {
  @Input() informazioniTecniche: InformazioniTecniche =
    {} as InformazioniTecniche;

  trackByFn = (index: number) => index;
}
