import { ActionReducerMap } from '@ngrx/store';
import { AppState } from '../core.module';
import { authReducer } from './auth/store/auth.reducer';
import { configurationReducer } from './configuration/store/configuration.reducer';
import { messagesReducer } from './messages/messages.reducer';
import { profileReducer } from './profile/store/profile.reducer';
import { sidebarReducer } from './sidebar/sidebar.reducer';
import { uiReducer } from './ui/store/ui.reducer';

export const coreReducer: ActionReducerMap<AppState> = {
  auth: authReducer,
  configuration: configurationReducer,
  messages: messagesReducer,
  profile: profileReducer,
  sidebar: sidebarReducer,
  ui: uiReducer,
};
