import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    template: `
    <div class="modal-header">
      <h5 class="title">
        {{ 'abilitazioniGrid.actions.noteDialog.title' | translate }}
      </h5>
      <button
        class="close"
        type="button"
        data-dismiss="modal"
        [attr.aria-label]="'common.close' | translate"
        [ngbTooltip]="'common.close' | translate"
        placement="bottom"
        (click)="activeModal.dismiss()"
      >
        <svg class="icon">
          <use
            xlink:href="./bootstrap-italia/dist/svg/sprite.svg#it-close"
          ></use>
        </svg>
      </button>
    </div>
    <div class="modal-body p-4">
      {{ 'manageManuals.dialogMessage' | translate }}
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-sm btn-outline-primary"
        (click)="activeModal.dismiss()"
      >
        {{ 'common.cancel' | translate }}
      </button>
      <button
        type="button"
        class="btn btn-sm btn-primary"
        (click)="activeModal.close(true)"
      >
        {{ 'common.confirm' | translate }}
      </button>
    </div>
  `,
})
export class ManageManualsDeleteDialogComponent {
    constructor(public activeModal: NgbActiveModal) { }
}
