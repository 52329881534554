import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Errore } from '../../../model/errore';

@Component({
  selector: 'vvf-input-text-area',
  template: `
    <div class="form-group">
      <div [class]="className">
        <textarea
          class="form-control"
          [id]="formControlName"
          [rows]="rows"
          [(ngModel)]="value"
          [placeholder]="placeholder"
          [readOnly]="readOnly"
        ></textarea>
        <label
          [attr.for]="formControlName"
          [class.active]="true"
          >{{ label }}</label
        >
        <small class="form-text text-muted">
          {{ infoText }}
        </small>
      </div>
    </div>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextAreaComponent),
      multi: true,
    },
  ],
})
export class TextAreaComponent implements ControlValueAccessor {
  private _value = '';
  get value() {
    return this._value;
  }

  set value(val: string) {
    this._value = val;
    this.onChange(val);
    this.onTouch(val);
  }

  @Input() formControlName = '';
  @Input() label = '';
  @Input() path = '';
  @Input() placeholder = '';
  @Input() rows = 3;
  @Input() readOnly = false;
  @Input() error: Errore;
  @Input() errorPrefix: string;
  @Input() infoText: string;
  @Input() model = '';

  _className!: string;
  get className(): string {
    return this._className ?? 'form-group';
  }

  @Input() set className(value: string) {
    this._className = value;
  }

  onChange: any = () => {};

  onTouch: any = () => {};

  writeValue(value: any) {
    this.value = value;
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouch = fn;
  }
}
