import { AutoMap } from '@automapper/classes';
import {
  CamelCaseNamingConvention,
  MappingProfile,
  mapWith,
} from '@automapper/core';
import { TestimoneDTO } from 'src/app/dto/dipendente-infortunato/testimone-dto';
import { DocumentoDTO } from 'src/app/dto/shared/documento-dto';
import { Documento } from 'src/app/model/common/documento';

export class Testimone {
  id: string;

  @AutoMap()
  nome: string;

  @AutoMap()
  cognome: string;

  @AutoMap()
  interno: boolean;

  documento: Documento;
}

export const testimoneProfile: MappingProfile = (mapper) => {
  mapper
    .createMap(TestimoneDTO, Testimone, {
      namingConventions: new CamelCaseNamingConvention(),
    })
    .forMember(
      (dest) => dest.documento,
      mapWith(Documento, DocumentoDTO, (src) => src.documento)
    );

  mapper
    .createMap(Testimone, TestimoneDTO, {
      namingConventions: new CamelCaseNamingConvention(),
    })
    .forMember(
      (dest) => dest.documento,
      mapWith(DocumentoDTO, Documento, (src) => src.documento)
    );
};
