import { Component, Input } from '@angular/core';
import { Dinamica } from 'src/app/model/dipendente-infortunato/dinamica-infortunio/dinamica';
import { Prognosi } from 'src/app/model/dipendente-infortunato/prognosi';

@Component({
  selector: 'vvf-registro-infortuni-prognosi-detail',
  template: `
    <div class="row mt-3">
      <div class="col-12 col-md-6 col-lg-4">
        <vvf-input-text
          [ngModel]="prognosi.giorniPS"
          [label]="'Giorni dati dal pronto soccorso' | translate"
          [readOnly]="true"
        ></vvf-input-text>
      </div>
      <div class="col-12 col-md-6 col-lg-4">
        <vvf-input-text
          [ngModel]="prognosi.decessoDipendente | boolToString"
          [label]="'Il dipendente è deceduto?' | translate"
          [readOnly]="true"
        ></vvf-input-text>
      </div>
    </div>

    <div class="row">
      <table class="table table-sm table-bordered">
        <thead>
          <th scope="col" class="text-center">#</th>
          <th scope="col">Data inizio prognosi</th>
          <th scope="col">Data fine prognosi</th>
        </thead>
        <tbody>
          <tr
            *ngFor="
              let item of prognosi.certificatiMedici;
              index as index;
              trackBy: trackByFn
            "
          >
            <td class="align-middle">
              <p style="font-size: 18px;">Certificato {{ index + 1 }}</p>
            </td>
            <td class="align-middle">
              <span class="text-secondary font-weight-bold">
                <p style="font-size: 18px;">
                  {{ item.dataInizio | date : 'dd/MM/yyyy' }}
                </p>
              </span>
            </td>
            <td class="align-middle">
              <span class="text-secondary font-weight-bold">
                <p style="font-size: 18px;">
                  {{ item.dataFine | date : 'dd/MM/yyyy' }}
                </p>
              </span>
            </td>
          </tr>
        </tbody>
      </table>
      <!-- <div
        *ngFor="let item of prognosi.certificatiMedici"
        class="col-12 col-md-6 col-lg-4"
      >
        <vvf-input-text
          [ngModel]="item.dataInizio | date : 'dd/MM/yyyy'"
          [label]="'Data inizio progonosi' | translate"
          [readOnly]="true"
        ></vvf-input-text>
      </div>
      <div
        *ngFor="let item of prognosi.certificatiMedici"
        class="col-12 col-md-6 col-lg-4"
      >
        <vvf-input-text
          [ngModel]="item.dataFine | date : 'dd/MM/yyyy'"
          [label]="'Data fine progonosi' | translate"
          [readOnly]="true"
        ></vvf-input-text>
      </div> -->
    </div>
  `,
})
export class PrognosiDetailComponent {
  @Input() prognosi: Prognosi = {} as Prognosi;
  trackByFn = (index: number) => index;
}
