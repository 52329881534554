import { Fascicolo } from './fascicolo';

export interface Infortunio {
  id: string;
  nome: string;
  cognome: string;
  codiceFiscale: string;
  creatoDa: string;
  modificatoDa: string;
  stato: string;
  fasciaOraria: string;
  numeroProtocoolo: string;
}

export interface FascicoloInfortunio extends Fascicolo {
  infortuni: Infortunio[];
}

export enum StatoInfortunio {
  BOZZA = 'bozza',
  COMPLETATO = 'completato',
  COMPLETATORESPONSABILE = 'completatoresponsabile',

  CANCELLATO = 'cancellato',
  INVIATARESPONSABILE = 'inviatoresponsabile',
}

export enum InfortuniActionType {
  DELETE = 'delete',
  EDIT = 'edit',
  EDIT_INFORTUNIO = 'editinfortunio',
  VIEW = 'view',
  PROTOCOLLA = 'protocolla',
}

export enum InfortunioActionType {
  DELETE = 'delete',
  EDIT = 'edit',
  SEND = 'send',
  SENDRESPONSABiLE = 'sendResponsabile',
  VIEW = 'view',
}

export interface InfortuniAction {
  index: number;
  id: string;
  infortunioId?: string;
  infortunioIndex?: number;
  actionType: InfortuniActionType;
}

export interface InfortunioAction {
  index: number;
  id: string;
  actionType: InfortunioActionType;
}

export type AllowedInfortunioActions = Partial<
  Record<InfortunioActionType, boolean>
>;
export type AllowedInfortuniActions = Partial<
  Record<InfortuniActionType, boolean>
>;

export interface InfortunioGridItem extends Infortunio {
  allowedActions: AllowedInfortunioActions;
}
export interface FascicoloInfortunioGridItem extends FascicoloInfortunio {
  allowedActions: AllowedInfortuniActions;
}

export interface InfortuniFilters {
  fiscalCode: string;
  name: string;
  surname: string;
  eventDate: number;
  sendDate: number;
  stato: string;
}
