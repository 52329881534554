import {
  Component,
  ChangeDetectionStrategy,
  Input,
  ViewChild,
} from '@angular/core';
import { ChartData, ChartOptions, ChartType } from 'chart.js';
import { BaseChartDirective, Label } from 'ng2-charts';

@Component({
  selector: 'vvf-chart',
  template: `
    <div class="chart-container">
      <canvas
        *ngIf="!!datasets?.length"
        baseChart
        [datasets]="datasets"
        [labels]="labels"
        [options]="chartOptions"
        [legend]="chartLegend"
        [chartType]="chartType"
        [colors]="colors"
        [plugins]="plugins"
      ></canvas>
      <!-- <canvas
        *ngIf="data"
        baseChart
        [data]="data"
        [labels]="labels"
        [legend]="chartLegend"
        [chartType]="chartType"
      ></canvas> -->
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChartComponent {
  @Input() chartOptions: ChartOptions = {
    responsive: true,
  };
  @Input() chartType: ChartType = 'bar';
  @Input() settings: any = {};
  @Input() datasets: any[] = [];
  @Input() data: any[] = [];

  @Input() labels: Label[] = [];
  @Input() chartLegend = true;
  @Input() showTitle = true;
  @Input() showGraph = true;
  @Input() loading = false;
  @Input() colors = [];

  @Input() plugins = [];

  @ViewChild(BaseChartDirective) chart: BaseChartDirective | undefined;

  get type() {
    this.datasets.length;
    return this.chartType as ChartType;
  }
}
