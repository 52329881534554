import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable()
export class NoteService {
  private readonly baseUrl = `${environment.baseUrl}/note`;

  constructor(private http: HttpClient) {}

  getAll(fascicoloId: string) {
    return this.http.get<string[]>(this.baseUrl);
  }

  insert(nota: string) {
    return this.http.post<string[]>(this.baseUrl, { nota });
  }
}
