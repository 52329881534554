import { Pipe, PipeTransform } from '@angular/core';

function tree(items: any[], groupKey = 'group') {
  if (!items) return [];

  const arr: any[] = [];
  const groups: any = {};
  let groupIndex = 0;

  for (const item of items) {
    const parentId = item[groupKey];

    if (!parentId) {
      groups[item.id] = groupIndex;
      arr[groupIndex] = { ...item, children: [] };
      groupIndex++;
      continue;
    }

    arr[groups[parentId]].children.push(item);
  }

  return arr;
}

@Pipe({ name: 'tree' })
export class TreePipe implements PipeTransform {
  transform(arr: any[], groupKey?: string): any {
    return tree(arr, groupKey);
  }
}
