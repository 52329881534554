import { createAction, props } from '@ngrx/store';
import { TipoPratica } from '../../../../model/enum/tipo-pratica';

export namespace ConfigurationActions {
  export const load = createAction(
    '[Configuration] load',
    props<{ tipoPratica: TipoPratica }>()
  );

  export const loadSuccess = createAction(
    '[Configuration] load success',
    props<{ configuration: any }>()
  );
}
