import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { Role } from 'src/app/model/role';
import { Auth } from 'src/app/model/auth';

@Injectable()
export class AccountService {
  private readonly baseUrl = `${environment.baseUrl}/account`;

  constructor(private http: HttpClient) {}

  resetPassword(userId: string): Observable<void> {
    return this.http.post<void>(`${this.baseUrl}/resetPassword`, { userId });
  }

  impersonate(role: Role): Observable<Auth> {
    let obj = {
      roleId: role.roleId,
      comando: role.codice_comando,
    };
    return this.http.post<Auth>(`${this.baseUrl}/impersonate`, obj);
  }
}
