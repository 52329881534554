import { createReducer, on } from '@ngrx/store';
import { Message } from '../../../shared/models/message';
import { MessagesActions } from './messages.actions';

const initialState: Message[] = [];

export const messagesReducer = createReducer(
  initialState,
  on(
    MessagesActions.showComplete,
    (state, { id, messageType, title, text, dismissable }) => [
      ...state,
      { id, messageType, title, text, dismissable: dismissable ?? true },
    ]
  ),
  on(MessagesActions.dismiss, (state, { id }) =>
    state.filter((message) => message.id !== id)
  )
);
