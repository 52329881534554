import {
  Directive,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormGroupDirective } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import isEqual from 'lodash-es/isEqual';

@Directive({ selector: '[connectForm]' })
export class ConnectFormDirective implements OnInit, OnChanges, OnDestroy {
  private destroy$ = new Subject();

  @Input() connectForm!: any;
  @Input() debounceTime = 0;
  @Output() loadForm: EventEmitter<any> = new EventEmitter<any>();
  @Output() changeForm: EventEmitter<any> = new EventEmitter<any>();

  constructor(private formGroupDirective: FormGroupDirective) {}

  ngOnInit(): void {
    this.formGroupDirective.form.valueChanges
      .pipe(
        debounceTime(this.debounceTime),
        distinctUntilChanged(isEqual),
        takeUntil(this.destroy$)
      )
      .subscribe((values) => this.changeForm.emit(values));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.connectForm) {
      this.formGroupDirective.form.patchValue(changes.connectForm.currentValue);
      this.loadForm.emit(changes.connectForm.currentValue);
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }
}
