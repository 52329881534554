import { LuogoDTO } from './luogo-dto';

export class AnagraficaDTO {
  userName: string;
  name: string;
  surname: string;
  email: null;
  sesso: string;
  dataNascita: string;
  luogoNascita: string;
  contatti: string[];
  residenza: LuogoDTO;
  domicilio: LuogoDTO;
  sede: string;
  turno: string;
  qualifica: string;
  ruolo: string;
  uo: string;
  articolazioneOrdinaria: string;
  sipec: boolean;
}
