import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  NgbTooltipModule,
  NgbCollapseModule,
  NgbModalModule,
  NgbDatepickerModule,
} from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    NgbTooltipModule,
    NgSelectModule,
    NgbCollapseModule,
    NgbModalModule,
    NgbDatepickerModule,
  ],
  exports: [
    NgbTooltipModule,
    NgSelectModule,
    NgbCollapseModule,
    NgbModalModule,
    NgbDatepickerModule,
  ]
})
export class NgBootstrapModule { }
