import { AutoMap } from '@automapper/classes';
import { Attrezzature } from './attrezzature';
import { Dpi } from './dpi';
import { Mezzi } from './mezzi';
import { Testimoni } from './testimoni';

export class InformazioniTecniche {
  @AutoMap({ typeFn: () => Attrezzature })
  attrezzature: Attrezzature;

  @AutoMap({ typeFn: () => Mezzi })
  mezzi: Mezzi;

  @AutoMap({ typeFn: () => Dpi })
  dpi: Dpi;

  @AutoMap()
  altrePersone: boolean;

  @AutoMap({ typeFn: () => Testimoni })
  testimoni: Testimoni;

  @AutoMap()
  tipoSoccorso: number;

  @AutoMap()
  attivitaConsueta: boolean;

  @AutoMap()
  lavorazioneSvolta: string;
}
