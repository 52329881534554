import { AutoMap } from '@automapper/classes';
import {
  CamelCaseNamingConvention,
  mapFrom,
  MappingProfile,
} from '@automapper/core';
import { DipendenteInfortunatoDTO } from 'src/app/dto/dipendente-infortunato';

export class DataLuogo {
  @AutoMap()
  data: number;

  @AutoMap()
  luogo: number;

  @AutoMap()
  ambientiOperativi: number | null;

  @AutoMap()
  fasciaOraria: number;

  @AutoMap()
  sede: number;

  @AutoMap()
  specificaLuogo: number | null;

  @AutoMap()
  areaOperativa: string | null;

  @AutoMap()
  specificaSedeInfortunio: string;

  @AutoMap()
  orario: string;

  @AutoMap()
  serviziIstituto: number | null;

  @AutoMap()
  modalitaInfortunioSpostamenti: number | null;

  @AutoMap()
  modalitaInfortunioItinere: number | null;

  @AutoMap()
  orarioLavoroStraordinario: boolean;
}

export const dataLuogoProfile: MappingProfile = (mapper) => {
  mapper
    .createMap(DipendenteInfortunatoDTO, DataLuogo, {
      namingConventions: new CamelCaseNamingConvention(),
    })
    .forMember(
      (dest) => dest.data,
      mapFrom((src) => src.dataInfortunio)
    )
    .forMember(
      (dest) => dest.orario,
      mapFrom((src) => src?.luogoInfortunio?.orario)
    )
    .forMember(
      (dest) => dest.luogo,
      mapFrom((src) => +src.luogoInfortunio.tipoLuogo)
    )
    .forMember(
      (dest) => dest.fasciaOraria,
      mapFrom((src) => +src.luogoInfortunio?.fasciaOraria)
    )
    .forMember(
      (dest) => dest.sede,
      mapFrom((src) => +src.luogoInfortunio?.sedeAccadimento)
    )
    .forMember(
      (dest) => dest.specificaSedeInfortunio,
      mapFrom((src) => src.luogoInfortunio.specificaSedeInfortunio)
    )
    .forMember(
      (dest) => dest.ambientiOperativi,
      mapFrom((src) => +src.luogoInfortunio?.ambientiOperativi)
    )
    .forMember(
      (dest) => dest.areaOperativa,
      mapFrom((src) => src.luogoInfortunio?.areaOperativa)
    )
    .forMember(
      (dest) => dest.serviziIstituto,
      mapFrom((src) => src.luogoInfortunio?.serviziIstituto)
    )
    .forMember(
      (dest) => dest.modalitaInfortunioItinere,
      mapFrom((src) => src.luogoInfortunio?.modalitaInfortunio)
    )
    .forMember(
      (dest) => dest.modalitaInfortunioSpostamenti,
      mapFrom((src) => src.luogoInfortunio?.modalitaInfortunio)
    )
    .forMember(
      (dest) => dest.orarioLavoroStraordinario,
      mapFrom((src) => src.luogoInfortunio.orarioLavoroStraordinario)
    )
    .forMember(
      (dest) => dest.specificaLuogo,
      mapFrom((src) => src.luogoInfortunio?.specificaLuogo)
    );
};
