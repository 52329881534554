import {AbstractControl, NG_VALIDATORS, ValidationErrors, Validator} from '@angular/forms';
import {Directive} from '@angular/core';

@Directive({
  selector: '[vvfFiscalCode]',
  providers: [{ provide: NG_VALIDATORS, useExisting: FiscalCodeValidatorDirective, multi: true }]
})
export class FiscalCodeValidatorDirective implements Validator {
  validate(control: AbstractControl): ValidationErrors | null {
    const fiscalCode = control.value;

    if (!fiscalCode?.length) {
      return null;
    }

    const matchFiscalCode = fiscalCode
      .match(/^([A-Za-z]{6}[0-9]{2}[[A-Za-z][0-9]{2}[[A-Za-z][0-9]{3}[[A-Za-z])$/);

    if (matchFiscalCode?.length > 0) {
      return null;
    }

    return {invalidFiscalCode: true};
  }
}
