import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { StringHelper } from '../../providers/string-helper.provider';

@Component({
  selector: 'vvf-radio-group',
  template: `
    <div class="d-flex flex-wrap align-items-center ">
      <label class="flex-grow-1" *ngIf="label">{{ label | translate }}</label>
      <div
        class="form-check form-check-inline"
        [class.radio-disabled]="disabled"
        *ngFor="let option of options; let i = index"
      >
        <input
          type="radio"
          [attr.name]="name"
          [attr.id]="name + '-' + i"
          [value]="option[bindValue]"
          [checked]="value === option[bindValue]"
          (change)="value = option[bindValue]"
        />
        <label [attr.for]="name + '-' + i">
          <ng-container *ngIf="labelTemplate; else defaultLabel">
            {{ format(labelTemplate, option[bindLabel]) }}
          </ng-container>
          <ng-template #defaultLabel>
            {{ option[bindLabel] }}
          </ng-template>
        </label>
      </div>
    </div>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RadioGroupComponent),
      multi: true,
    },
  ],
})
export class RadioGroupComponent implements ControlValueAccessor {
  private _value = '';
  get value() {
    return this._value;
  }

  set value(val: string) {
    this._value = val;
    this.onChange(val);
    this.onTouch(val);
  }

  @Input() disabled: boolean;
  @Input() options: any[];
  @Input() name: string;
  @Input() label: string;
  @Input() bindLabel: string;
  @Input() bindValue: string;
  @Input() labelTemplate: string;

  onChange: any = () => {};

  onTouch: any = () => {};

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(value: any): void {
    this.value = value;
  }

  format(text: string, value: string) {
    return StringHelper.format(text, value);
  }
}
