import {MetaReducer} from '@ngrx/store';
import {storageMetaReducer} from './storage.metareducer';
import {LocalStorageService} from '../../services/local-storage.service';

export function getStorageMetaReducer(
  saveKeys: string[],
  localStorageKey: string,
  localStorageService: LocalStorageService
): MetaReducer {
  return storageMetaReducer(saveKeys, localStorageKey, localStorageService);
}
