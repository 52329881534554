import { AutoMap } from '@automapper/classes';
import { DocumentoDTO } from '../shared/documento-dto';

export class TestimoneDTO {
  @AutoMap()
  nome: string;

  @AutoMap()
  cognome: string;

  @AutoMap()
  interno: boolean;

  @AutoMap({ typeFn: () => DocumentoDTO })
  documento: DocumentoDTO;
}
