import { AutoMap } from '@automapper/classes';
import { CertificatoMedico } from './certificato-medico';

export class Prognosi {
  @AutoMap()
  giorniPS: string;

  @AutoMap()
  decessoDipendente: boolean;

  @AutoMap({ typeFn: () => CertificatoMedico })
  certificatiMedici: CertificatoMedico[];
}
