import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'vvf-input-text',
  template: `
    <div [class]="className">
      <ng-container *ngIf="type === 'number'; else inputText">
        <div
          [ngClass]="{
            'input-number input-number--custom': type === 'number',
            'input-number-currency': currency
          }"
        >
          <input
            type="text"
            [class]="formControlClassName"
            [disabled]="disabled"
            [id]="name"
            [name]="name"
            [(ngModel)]="value"
            [placeholder]="placeholder"
            [readOnly]="readOnly"
            onlyIntegers
            [isCurrency]="currency"
            [min]="minValue"
            [class.is-invalid]="!!error"
          />

          <ng-container *ngIf="!readOnly">
            <div class="d-flex flex-column">
              <button class="input-number-add" (click)="addValue()">
                <span class="sr-only">Aumenta valore</span>
              </button>
              <button class="input-number-sub" (click)="subValue()">
                <span class="sr-only">Diminuisci valore</span>
              </button>
            </div>
          </ng-container>
        </div>
      </ng-container>

      <ng-template #inputText>
        <input
          type="text"
          [class]="formControlClassName"
          [id]="name"
          [name]="name"
          [disabled]="disabled"
          [(ngModel)]="value"
          [placeholder]="placeholder"
          [readOnly]="readOnly"
          [class.is-invalid]="!!error"
        />
      </ng-template>
      <label
        [attr.for]="formControlName"
        [class.active]="true"
      >
        <span class="text-danger" *ngIf="mandatory">* </span>
        {{ label }}
      </label>
      <div class="invalid-feedback" *ngIf="!!error">
        {{ error }}
      </div>
    </div>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextComponent),
      multi: true,
    },
  ],
})
export class TextComponent implements ControlValueAccessor {
  private _value: string | number = '';
  get value() {
    return this._value;
  }

  set value(val: string | number) {
    this._value = val;
    this.onChange(val);
    this.onTouch(val);
  }

  get name() {
    return this.id ?? this.formControlName;
  }

  @Input() disabled = false;
  @Input() id = '';
  @Input() formControlName = '';
  @Input() type = 'text';
  @Input() noBgColor = false;
  @Input() currency = false;
  @Input() label = '';
  @Input() placeholder = '';
  @Input() minValue!: number;
  @Input() readOnly = false;
  @Input() path!: string;
  @Input() mandatory = false;
  @Input() error!: string;
  @Input() negativeAllowed: boolean = false;

  private _className!: string;
  get className(): string {
    return this._className ?? 'form-group';
  }

  @Input() set className(value: string) {
    this._className = value;
  }

  private _formControlClassName!: string;
  get formControlClassName(): string {
    return this._formControlClassName ?? 'form-control';
  }

  @Input() set formControlClassName(value: string) {
    this._formControlClassName = value;
  }

  onChange: any = () => {};

  onTouch: any = () => {};

  writeValue(value: any) {
    this.value = value;
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouch = fn;
  }

  addValue() {
    this.value = this.currency
      ? parseFloat((this.value ? +this.value + 1 : 1).toString()).toFixed(2)
      : +this.value + 1;
  }

  subValue() {
    if (+this.value - 1 < this.minValue) {
      return;
    }

    if (!this.negativeAllowed && (this.value === undefined || this.value < 1)) {
      return;
    }

    this.value = this.currency
      ? parseFloat((this.value ? +this.value - 1 : -1).toString()).toFixed(2)
      : +this.value - 1;
  }
}
