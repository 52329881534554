import { HttpResponse } from '@angular/common/http';
import { File } from 'src/app/model/file';

export class DownloadFileHelper {
  static getFileFromHttpResponse(
    response: HttpResponse<Blob>,
    fileName?: string
  ): File {
    const contentDisposition = response.headers.get('content-disposition');
    if (contentDisposition) {
      fileName = contentDisposition.split(';')[1];
      fileName = fileName.split('filename')[1];

      fileName = fileName.split('=')[1];
      let fileNamep = fileName.split('"');
      if (fileNamep.length > 1) {
        fileName = fileNamep[1];
      }
      fileName = fileName.trim();
    }
    return { blob: response.body as Blob, name: fileName ?? 'attachment' };
  }
}
