export enum RoleEnum {
  SEA_USER = 'SEA-User',
  SEA_MEDICO_COMPETENTE = 'SEA-MedicoCompetente',
  SEA_DATORE_LAVORO = 'SEA-DatoreLavoro',
  SEA_AMMINISTRATORE = 'SEA-Amministratore',
  SEA_INSERITORE = 'SEA-Inseritore',
  SEA_RESPONSABILE_ISPETTIVO = 'SEA-ResponsabileIspettivo',
  SEA_MEDICO_ISPETTIVO = 'SEA-MedicoIspettivo',
  SEA_RESPONSABILE = 'SEA-Responsabile',
  SEA_RESPONSABILE_SPP = 'SEA-ResponsabileSPP',
  SEA_RESPONSABILE_PS = 'SEA-ResponsabilePS',
  SEA_RESPONSABILE_SPI = 'SEA-ResponsabileSPI',
  SEA_RESPONSABILE_DCRU = 'SEA-ResponsabileDCRU',
}

export interface Role {
  roleId: string;
  role_name: RoleEnum;
  codice_comando: string;
}
