import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Lesioni } from 'src/app/model/dipendente-infortunato/lesioni';
import { TempoLuogo } from 'src/app/model/dipendente-infortunato/tempo-luogo';

@Component({
  selector: 'vvf-riepilogo-infortunio-descrizione-lesioni',
  template: `
    <div *ngIf="!lesioni?.conseguenzaLesioni?.length">
      <h6 class="ml-3">
        Non ci sono descrizioni delle lesioni per la pratica selezionata
      </h6>
    </div>

    <table
      class="table table-sm table-bordered"
      *ngIf="lesioni?.conseguenzaLesioni?.length"
    >
      <thead>
        <tr>
          <th scope="col" class="text-center">#</th>
          <th scope="col">
            {{ 'Tipo lesione' | translate }}
          </th>
          <th scope="col">
            {{ 'Parti del corpo lesionate' | translate }}
          </th>
        </tr>
      </thead>
      <tbody>
        <ng-container>
          <tr
            *ngFor="
              let item of lesioni.items;
              index as index;
              trackBy: trackByFn
            "
          >
            <td class="text-center align-middle">
              {{ index + 1 }}
            </td>
            <td class="align-middle">
              <span class="text-secondary font-weight-bold">
                <p style="font-size: 18px;">
                  {{
                    'nuovoInfortunio.stepDescrizioneLesioni.detail.tipoLesione.' +
                      item.lesione.tipo | translate
                  }}
                </p>
              </span>
            </td>
            <td class="align-middle">
              <span class="text-secondary font-weight-bold">
                <p
                  style="font-size: 18px;"
                  *ngFor="let item of item.partiCorpo.parti"
                >
                  {{
                    'nuovoInfortunio.stepDescrizioneLesioni.detail.parteCorpo.' +
                      item | translate
                  }}
                </p>
              </span>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>

    <!-- <div *ngIf="!!lesioni" class="row mt-3">
      <div
        *ngFor="let item of lesioni.items; index as index; trackBy: trackByFn"
        class="col-12 col-md-6 col-lg-3"
      >
        <vvf-input-text
          [ngModel]="
            'nuovoInfortunio.stepDescrizioneLesioni.detail.tipoLesione.' +
              item.lesione.tipo | translate
          "
          label="Tipo di lesione"
          [readOnly]="true"
        ></vvf-input-text>
      </div>
      <div
        *ngFor="let item of lesioni.items; index as index; trackBy: trackByFn"
        class="col-12 col-md-6 col-lg-3"
      >
        <vvf-input-text
          [ngModel]="
            'nuovoInfortunio.stepDescrizioneLesioni.detail.parteCorpo.' +
              item.partiCorpo.parti | translate
          "
          label="Parti del corpo lesionate"
          [readOnly]="true"
        ></vvf-input-text>
      </div>
      <div class="col-12 col-md-6 col-lg-3">
        <vvf-input-text
          [ngModel]="lesioni.conseguenzaLesioni"
          label="In conseguenza a cosa ciò è avvenuto"
          [readOnly]="true"
        ></vvf-input-text>
      </div>
    </div> -->
  `,
})
export class RiepilogoInfortunioDescrizioneLesioniComponent {
  @Input() lesioni: Lesioni = {} as Lesioni;
  trackByFn = (index: number) => index;
}
