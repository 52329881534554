import { AutoMap } from '@automapper/classes';

export class InformazioniTecnicheDTO {
  @AutoMap()
  categoriaAttrezzaturaId: number;
  @AutoMap()
  attrezzaturaId: number;

  @AutoMap()
  targa: string;
  @AutoMap()
  sigla: string;
  @AutoMap()
  codiceGenereMezzo: string;
  @AutoMap()
  descrizioneGenereMezzo: string;
  @AutoMap()
  tipoGenereMezzo: string;
}
