import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Action, ActionReducer, META_REDUCERS, StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { RouterState, StoreRouterConnectingModule } from '@ngrx/router-store';
import { EffectsModule } from '@ngrx/effects';
import { ProfileState } from './store/profile/store/profile.state';
import { AuthService } from './store/auth/services/auth.service';
import { AuthState } from './store/auth/store/auth.reducer';
import { AuthGuard } from './store/auth/services/auth.guard';
import { AuthInterceptor } from './store/auth/services/auth.interceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { UIState } from './store/ui/store/ui.reducer';
import { getStorageMetaReducer } from './store/meta';
import { LocalStorageService } from './services/local-storage.service';
import { ROOT_LOCAL_STORAGE_KEY, ROOT_STORAGE_KEYS } from './core.tokens';
import { ConfigurationService } from './store/configuration/services/configuration.service';
import { PROVIDERS } from './services';
import { LoaderInterceptor } from './services/loader/loader.interceptor';
import { coreEffects } from './store/core.effects';
import { GUARDS } from './guards';
import { Message } from '../shared/models/message';
import { INTERCEPTORS } from './interceptors';
import { Configuration } from './store/configuration/store/configuration.state';
import { coreReducer } from './store/core.reducer';
import { SidebarItem } from './store/sidebar/sidebar.reducer';

export interface AppState {
  auth: AuthState;
  configuration: Configuration;
  messages: Message[];
  profile: ProfileState;
  // router: RouterReducerState;
  sidebar: SidebarItem[];
  ui: UIState;
}

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forRoot(coreReducer, {
      runtimeChecks: {
        strictActionImmutability: true,
        strictActionSerializability: true,
        strictStateImmutability: true,
        strictStateSerializability: true,
      },
    }),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
    }),
    StoreRouterConnectingModule.forRoot({
      routerState: RouterState.Minimal,
    }),
    EffectsModule.forRoot(coreEffects),
  ],
  providers: [
    ...PROVIDERS,
    ...INTERCEPTORS,
    ...GUARDS,
    AuthService,
    AuthGuard,
    ConfigurationService,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    {
      provide: ROOT_STORAGE_KEYS,
      useValue: ['auth', 'configuration', 'profile', 'ui'],
      multi: true,
    },
    {
      provide: ROOT_LOCAL_STORAGE_KEY,
      useValue: '__app_storage__',
      multi: true,
    },
    {
      provide: META_REDUCERS,
      deps: [ROOT_STORAGE_KEYS, ROOT_LOCAL_STORAGE_KEY, LocalStorageService],
      useFactory: getStorageMetaReducer,
      multi: true,
    },
  ],
})
export class CoreModule {}

export function clearState(reducer: ActionReducer<any>): ActionReducer<any> {
  return function (state: any, action: Action): any {
    if (action?.type.includes('clearState')) {
      state = undefined;
    }
    return reducer(state, action);
  };
}
