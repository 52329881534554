import { Component, Input } from '@angular/core';

@Component({
  selector: 'vvf-main-card',
  template: `
    <div class="content" [class.content--card]="mainCard">
      <div class="card-wrapper card-space">
        <div class="card card-bg" [ngClass]="{ 'no-after': noAfter }">
          <div class="card-body p-0">
            <div class="card-title card-title--main" *ngIf="title">
              <h3 class="flex-grow-1">{{ title }}</h3>
              <ng-content select="[status]"></ng-content>
              <ng-content select="[btn-action]"></ng-content>
            </div>
            <div class="card-body--main" [ngClass]="{ 'pb-0': noAfter }">
              <ng-content select="[content]"></ng-content>
            </div>
          </div>
        </div>
      </div>
    </div>
  `,
})
export class MainCardComponent {
  @Input() title: string = '';
  @Input() mainCard = true;
  @Input() noAfter = false;
}
