import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { User } from 'src/app/model/user';

@Injectable()
export class UserInfoService {
  private readonly baseUrl = `${environment.baseUrl}/user/info`;

  constructor(private http: HttpClient) {}

  load() {
    return this.http.get<User>(this.baseUrl);
  }

  edit(user: User) {
    return this.http.patch<User>(this.baseUrl, user);
  }
}
