import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import {
  AllowedInfortuniActions,
  FascicoloInfortunioGridItem,
  InfortuniAction,
  InfortuniActionType,
} from 'src/app/model/infortunio';

@Component({
  selector: 'vvf-infortuni-grid-actions',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <button
      class="btn btn-xs"
      *ngFor="let action of allowedActionsArray"
      (click)="$event.stopPropagation(); actionHandler(action)"
      [ngbTooltip]="'infortuni.grid.actions.' + action | translate"
    >
      <i class="material-icons text-secondary">{{ actionButtons[action] }}</i>
    </button>
  `,
})
export class InfortuniGridActionsComponent {
  @Input() index = -1;
  @Input() fascicolo: FascicoloInfortunioGridItem =
    {} as FascicoloInfortunioGridItem;
  @Output() actionClick = new EventEmitter<InfortuniAction>();

  InfortuniActionType = InfortuniActionType;

  get allowedActions(): AllowedInfortuniActions {
    return this.fascicolo?.allowedActions ?? {};
  }

  get allowedActionsArray(): string[] {
    return Object.keys(this.allowedActions).filter(
      (k) => k !== InfortuniActionType.EDIT_INFORTUNIO
    );
  }

  actionButtons = {
    [InfortuniActionType.DELETE as string]: 'delete',
    [InfortuniActionType.EDIT as string]: 'edit',
    [InfortuniActionType.VIEW as string]: 'visibility',
    [InfortuniActionType.PROTOCOLLA as string]: 'send',
  };

  actionHandler(actionType: string) {
    this.actionClick.emit({
      index: this.index,
      id: this.fascicolo.id,
      actionType: actionType as InfortuniActionType,
    });
  }
}
