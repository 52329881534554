import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Errore } from '../../../model/errore';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'vvf-input-date',
  template: `
    <div [class]="className">
      <div class="input-group">
        <input
          type="text"
          class="form-control"
          [attr.id]="id"
          [(ngModel)]="value"
          ngbDatepicker
          #datePicker="ngbDatepicker"
          [ngClass]="{ 'is-invalid': !!error }"
          [minDate]="minDate"
          [maxDate]="maxDate"
          [readonly]="readOnly"
        />
        <label [attr.for]="id" [class.active]="!!value">
        <span class="text-danger" *ngIf="mandatory">* </span>
          {{ label }}
        </label>
        <div class="input-group-append" *ngIf="!readOnly">
          <button
            class="btn btn-icon"
            type="button"
            (click)="datePicker.toggle()"
          >
            <span class="icon icon-primary material-icons"> event </span>
          </button>
        </div>
      </div>
      <div class="invalid-feedback" *ngIf="!!error">
        {{ error }}
      </div>
    </div>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateComponent),
      multi: true,
    },
  ],
})
export class DateComponent implements ControlValueAccessor {
  private _value = '';
  get value() {
    return this._value;
  }

  set value(val: string) {
    this._value = val;
    this.onChange(val);
    this.onTouch(val);
  }

  @Input() id: string;
  @Input() label: string;
  @Input() error!: string;
  @Input() readOnly: boolean;
  @Input() mandatory: boolean = false;
  @Input() errorPrefix: string;

  private _className!: string;
  get className(): string {
    return this._className ?? 'form-group';
  }

  @Input() set className(value: string) {
    this._className = value;
  }

  @Input('minDate') _minDate: string;
  get minDate(): NgbDateStruct {
    if (!this._minDate) {
      return {} as NgbDateStruct;
    }
    const date = new Date(+this._minDate);
    return {
      day: date?.getDate(),
      month: date?.getMonth() + 1,
      year: date?.getFullYear(),
    };
  }

  @Input('maxDate') _maxDate: string;
  get maxDate(): NgbDateStruct {
    if (!this._maxDate) {
      return {} as NgbDateStruct;
    }
    const date = new Date(+this._maxDate);
    return {
      day: date?.getDate(),
      month: date?.getMonth() + 1,
      year: date?.getFullYear(),
    };
  }

  onChange: any = () => {};

  onTouch: any = () => {};

  writeValue(value: any) {
    this.value = value;
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouch = fn;
  }
}
