import { Lesione } from './lesione';
import { PartiCorpo } from './parti-corpo';

export class LesioneItem {
  id: string;

  lesione: Lesione;

  partiCorpo: PartiCorpo;
}
