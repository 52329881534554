import { AutoMap } from '@automapper/classes';
import { Documento } from './common/documento';
import { Typologic } from './common/typologic';

export class AltreInformazioni {
  @AutoMap()
  descrizione: string;
  @AutoMap()
  primaVolta: boolean;
}

export class InformazioniTecniche {
  id: string;
  categoriaAttrezzatura: Typologic;
  @AutoMap()
  categoriaAttrezzaturaId: number;
  @AutoMap()
  attrezzaturaId: number;
  @AutoMap()
  categoriaAttrezzaturaIdParent: number;
  @AutoMap()
  targa: string;
  @AutoMap()
  sigla: string;
  @AutoMap()
  codiceGenereMezzo: string;
  @AutoMap()
  descrizioneGenereMezzo: string;
  @AutoMap()
  tipoGenereMezzo: string;
  @AutoMap()
  tipoDPI: string;
}

export class MisureTecniche {
  @AutoMap()
  misureTecniche: string;
  @AutoMap()
  misureOrganizzative: string;
  @AutoMap()
  misureProcedurali: string;
}

export class AttrezzaturaIncidentata {
  @AutoMap()
  causeIncidente: number[];

  @AutoMap({ typeFn: () => Documento })
  documentazione: Documento[];

  @AutoMap({ typeFn: () => AltreInformazioni })
  altreInformazioni: AltreInformazioni;

  misureTecniche: MisureTecniche;

  @AutoMap({ typeFn: () => InformazioniTecniche })
  informazioniTecniche: InformazioniTecniche;
}
