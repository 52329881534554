import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'shared-navigation',
  template: `
    <div class="d-flex justify-content-between mt-5">
      <div>
        <button
          class="btn btn-icon btn-sm"
          (click)="backHandler()"
          *ngIf="activePage > 0"
        >
          <i class="icon icon-primary material-icons">arrow_back</i>
          <span class="primary-color">
            {{ prevLabel }}
          </span>
        </button>
      </div>

      <div>
        <button
          class="btn btn-icon btn-sm"
          (click)="forwardHandler()"
          *ngIf="activePage < maxTabNumber - 1"
        >
          <span class="primary-color">
            {{ nextLabel }}
          </span>
          <i class="icon icon-primary material-icons">arrow_forward</i>
        </button>
      </div>
    </div>
  `,
})
export class NavigationComponent {
  @Input() activePage = 0;
  @Input() maxTabNumber = 3;
  @Input() prevLabel = '';
  @Input() nextLabel = '';
  @Output() back = new EventEmitter<void>();
  @Output() forward = new EventEmitter<void>();
  @Output() send = new EventEmitter<void>();

  backHandler() {
    window.scrollTo({ top: 0 });
    this.back.emit();
  }

  forwardHandler() {
    window.scrollTo({ top: 0 });
    this.forward.emit();
  }
}
