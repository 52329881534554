import { Component, Input } from '@angular/core';
import { InformazioniTecniche } from 'src/app/model/dipendente-infortunato/informazioni-tecniche';

@Component({
  selector: 'vvf-registro-infortuni-dipendente-informazioni-tecniche',
  template: `
    <div class="row mt-3">
      <div class="col-12 mt-2">
        <vvf-registro-infortuni-dipendente-attrezzatura-detail-items
          [items]="informazioniTecniche.attrezzature.items"
          label="nuovoInfortunio.stepInformazioniTecniche.equipment.grid.equipment"
        ></vvf-registro-infortuni-dipendente-attrezzatura-detail-items>
      </div>

      <div class="col-12 mt-2">
        <vvf-registro-infortuni-dipendente-mezzo-detail-items
          [items]="informazioniTecniche.mezzi.items"
          label="nuovoInfortunio.stepInformazioniTecniche.vehicle.grid.vehicle"
        ></vvf-registro-infortuni-dipendente-mezzo-detail-items>
      </div>

      <div class="col-12 mt-2">
        <vvf-registro-infortuni-dipendente-dpi-detail-items
          [items]="informazioniTecniche.dpi.items"
          label="nuovoInfortunio.stepInformazioniTecniche.dpi.grid.dpi"
        ></vvf-registro-infortuni-dipendente-dpi-detail-items>
      </div>
    </div>
  `,
})
export class InformazioniTecnicheComponent {
  @Input() informazioniTecniche: InformazioniTecniche =
    {} as InformazioniTecniche;
}
