import {
  CamelCaseNamingConvention,
  mapFrom,
  MappingProfile,
  mapWith,
} from '@automapper/core';
import { CertificatoMedicoDTO } from 'src/app/dto/dipendente-infortunato/certificato-medico-dto';
import { DocumentoDTO } from 'src/app/dto/shared/documento-dto';
import { Documento } from 'src/app/model/common/documento';
import { CertificatoMedico } from './certificato-medico';

export const certificatoMedicoProfile: MappingProfile = (mapper) => {
  mapper
    .createMap(CertificatoMedico, CertificatoMedicoDTO, {
      namingConventions: new CamelCaseNamingConvention(),
    })
    .forMember(
      (destination) => destination.dataInizio,
      mapFrom((source) => !source.dataInizio ? null : source.dataInizio)
    )
    .forMember(
      (destination) => destination.dataFine,
      mapFrom((source) => !source.dataFine ? null : source.dataFine)
    )
    .forMember(
      (destination) => destination.certificato,
      mapWith(DocumentoDTO, Documento, (source) => source.documento)
    );

  mapper
    .createMap(CertificatoMedicoDTO, CertificatoMedico, {
      namingConventions: new CamelCaseNamingConvention(),
    })
    .forMember(
      (destination) => destination.documento,
      mapWith(Documento, DocumentoDTO, (source) => source.certificato)
    );
};
