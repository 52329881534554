import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { map, withLatestFrom } from 'rxjs/operators';
import { RoleEnum } from 'src/app/model/role';
import { AppState } from '../../core.module';
import { RoleSelectors } from '../profile/store/role/role.selectors';
import { SidebarActions } from './sidebar.actions';
import { SidebarItem } from './sidebar.reducer';

interface SidebarItemWithRoles extends SidebarItem {
  roles?: RoleEnum[];
}

const items: SidebarItemWithRoles[] = [
  {
    text: 'home.title',
    class: 'fas fa-home',
    link: ['/home'],
  },
  {
    text: 'infortuni.title',
    class: 'fas fa-bolt',
    link: ['/infortuni'],
    roles: [
      RoleEnum.SEA_INSERITORE,
      RoleEnum.SEA_RESPONSABILE,
      RoleEnum.SEA_AMMINISTRATORE,
      RoleEnum.SEA_DATORE_LAVORO,
      RoleEnum.SEA_MEDICO_COMPETENTE,
      RoleEnum.SEA_MEDICO_ISPETTIVO,
      RoleEnum.SEA_RESPONSABILE_ISPETTIVO,
      RoleEnum.SEA_RESPONSABILE_SPP,
      RoleEnum.SEA_RESPONSABILE_PS,
      RoleEnum.SEA_RESPONSABILE_SPI,
      RoleEnum.SEA_RESPONSABILE_DCRU,
    ],
  },
  {
    text: 'incidenti.title',
    class: 'material-icons',
    icon: 'feedback',
    link: ['/incidenti'],
    roles: [
      RoleEnum.SEA_INSERITORE,
      RoleEnum.SEA_RESPONSABILE,
      RoleEnum.SEA_AMMINISTRATORE,
      RoleEnum.SEA_DATORE_LAVORO,
      RoleEnum.SEA_RESPONSABILE_ISPETTIVO,
    ],
  },
  {
    text: 'mieiInfortuni.title',
    class: 'material-icons',
    icon: 'assignment_ind',
    link: ['/miei-infortuni'],
    roles: [
      RoleEnum.SEA_USER,
      RoleEnum.SEA_INSERITORE,
      RoleEnum.SEA_AMMINISTRATORE,
    ],
  },
  {
    text: 'manuali.title',
    class: 'material-icons',
    icon: 'menu_book',
    link: ['/manuali-operativi'],
    roles: [
      RoleEnum.SEA_USER,
      RoleEnum.SEA_INSERITORE,
      RoleEnum.SEA_RESPONSABILE,
      RoleEnum.SEA_AMMINISTRATORE,
      RoleEnum.SEA_DATORE_LAVORO,
      RoleEnum.SEA_MEDICO_COMPETENTE,
      RoleEnum.SEA_MEDICO_ISPETTIVO,
      RoleEnum.SEA_RESPONSABILE_ISPETTIVO,
      RoleEnum.SEA_RESPONSABILE_SPP,
      RoleEnum.SEA_RESPONSABILE_PS,
      RoleEnum.SEA_RESPONSABILE_SPI,
      RoleEnum.SEA_RESPONSABILE_DCRU,
    ],
  },
  {
    text: 'manageManuals.title',
    class: 'material-icons',
    icon: 'note_alt',
    link: ['/manage-manuals'],
    roles: [RoleEnum.SEA_AMMINISTRATORE],
  },
  {
    text: 'utenteEsterno.utenti',
    class: 'material-icons',
    icon: 'app_registration',
    link: ['/utenti-esterni'],
    roles: [RoleEnum.SEA_AMMINISTRATORE, RoleEnum.SEA_RESPONSABILE],
  },
  {
    text: 'utenteInterno.utenti',
    class: 'material-icons',
    icon: 'list_alt',
    link: ['/utenti-interni'],
    roles: [RoleEnum.SEA_AMMINISTRATORE],
  },
  {
    text: 'registroInfortuni.title',
    class: 'material-icons',
    icon: 'article',
    link: ['/registro-infortuni'],
    roles: [
      RoleEnum.SEA_RESPONSABILE_ISPETTIVO,
      RoleEnum.SEA_DATORE_LAVORO,
      RoleEnum.SEA_MEDICO_COMPETENTE,
    ],
  },
  {
    text: 'statistiche.title',
    class: 'material-icons',
    icon: 'analytics',
    link: ['/reportistica'],
    roles: [
      RoleEnum.SEA_RESPONSABILE,
      RoleEnum.SEA_DATORE_LAVORO,
      RoleEnum.SEA_AMMINISTRATORE,
    ],
  },
];

@Injectable()
export class SidebarEffects {
  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SidebarActions.load),
      withLatestFrom(this.store.select(RoleSelectors.getRoleName)),
      map(([, role]) =>
        SidebarActions.loadComplete({
          items: items
            .filter((i) => (i.roles ? i.roles.includes(role) : true))
            .map((i) => ({
              text: this.translate.instant(i.text),
              class: i.class,
              icon: i.icon,
              link: i.link,
            })),
        })
      )
    )
  );

  constructor(
    private actions$: Actions,
    private translate: TranslateService,
    private store: Store<AppState>
  ) {}
}
