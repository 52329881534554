import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMapTo } from 'rxjs/operators';
import { UserEffects } from './user/user.effects';
import { ProfileActions } from './profile.actions';
import { UserActions } from './user/user.actions';
import { RoleActions } from './role/role.actions';
import { RoleEffects } from './role/role.effects';

@Injectable()
export class ProfileEffects {
  logout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileActions.logout),
      switchMapTo([UserActions.clear(), RoleActions.clear()])
    )
  );

  constructor(private actions$: Actions) {}
}

export const profileEffects = [ProfileEffects, RoleEffects, UserEffects];
