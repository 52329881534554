import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import {
  FascicoloInfortunioGridItem,
  InfortuniAction,
} from '../../../model/infortunio';

@Component({
  selector: 'vvf-infortuni-grid',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <ng-container *ngIf="!fascicoli?.length; else table">
      <div class="d-flex justify-content-center w-100">
        {{ 'common.noResult' | translate }}
      </div>
    </ng-container>

    <ng-template #table>
      <table class="table table-bordered">
        <thead>
          <tr>
            <th scope="col">
              {{ 'fascicoli.eventDate' | translate }}
            </th>
            <th scope="col">
              {{ 'fascicoli.items' | translate }}
            </th>
            <th scope="col">
              {{ 'fascicoli.createdBy' | translate }}
            </th>
            <th scope="col">
              {{ 'fascicoli.lastEdit' | translate }}
            </th>
            <th scope="col" class="text-center">
              {{ 'fascicoli.state' | translate }}
            </th>
            <th scope="col" class="text-center">
              {{ 'common.actions' | translate }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            *ngFor="
              let fascicolo of fascicoli;
              let fascicoloIndex = index;
              trackBy: fascicoliTrackByFn
            "
          >
            <td class="align-middle">
              {{ fascicolo.dataEvento | date }}
            </td>
            <td class="align-middle">
              <ng-container
                *ngFor="
                  let infortunio of fascicolo.infortuni;
                  let infortunioIndex = index;
                  trackBy: infortuniTrackByFn
                "
              >
                <vvf-infortuni-grid-infortunato
                  [fascicoloIndex]="fascicoloIndex"
                  [infortunioIndex]="infortunioIndex"
                  [fascicolo]="fascicolo"
                  [infortunio]="infortunio"
                  (actionClick)="actionClick.emit($event)"
                ></vvf-infortuni-grid-infortunato>
              </ng-container>
              <ng-container
                *ngIf="fascicolo.infortuni && fascicolo.infortuni.length > 3"
              >
                <div class="chip chip-lg">
                  <span class="chip-label">
                    {{ 'fascicoli.others' | translate }}
                    {{ fascicolo.infortuni.length - 3 }}...
                  </span>
                </div>
              </ng-container>
            </td>
            <td class="align-middle">
              {{ fascicolo.creatoDa }} <br />
              il {{ fascicolo.dataCreazione | date }}
            </td>
            <td class="align-middle">
              <ng-container *ngIf="fascicolo.ultimaModifica; else empty">
                {{ fascicolo.modificatoDa }} <br />
                il {{ fascicolo.ultimaModifica | date }}
              </ng-container>
              <ng-template #empty>--</ng-template>
            </td>
            <td class="text-center align-middle">
              <span class="text-secondary font-weight-bold">
                {{
                  'fascicoli.status.' + fascicolo.stato | translate | uppercase
                }}
              </span>
            </td>
            <td class="text-center align-middle">
              <vvf-infortuni-grid-actions
                [index]="fascicoloIndex"
                [fascicolo]="fascicolo"
                (actionClick)="actionClick.emit($event)"
              ></vvf-infortuni-grid-actions>
            </td>
          </tr>
        </tbody>
      </table>
    </ng-template>
  `,
})
export class InfortuniGridComponent {
  @Input() fascicoli: FascicoloInfortunioGridItem[];
  @Output() actionClick = new EventEmitter<InfortuniAction>();

  fascicoliTrackByFn = (index: number) => index;
  infortuniTrackByFn = (index: number) => index;
}
