import {
  Directive,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { isEqual } from 'lodash-es';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Directive({ selector: '[connectArray]' })
export class ConnectArrayDirective implements OnChanges, OnDestroy {
  private subscriptions: Subscription[] = [];

  @Input() connectArray!: any;
  @Input() bindValue: string | string[] = '';
  @Input() formArray: FormArray;
  @Input() debounceTime = 0;
  @Output() changeItem = new EventEmitter<{ index: number; values: any }>();

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.connectArray && changes.connectArray.currentValue) {
      this.formArray.clear();

      for (const [index, item] of changes.connectArray.currentValue.entries()) {
        const group = new FormGroup({});

        const bindValue = Array.isArray(this.bindValue)
          ? this.bindValue
          : [this.bindValue];
        if (this.bindValue) {
          for (const key of bindValue) {
            if (Array.isArray(item[key])) {
              group.addControl(key, new FormArray([]));
              continue;
            }
            group.addControl(key, new FormControl(item[key]));
          }

          this.subscriptions.push(
            group.valueChanges
              .pipe(
                debounceTime(this.debounceTime),
                distinctUntilChanged(isEqual)
              )
              .subscribe((values) => this.changeItem.emit({ index, values }))
          );

          this.formArray.push(group);
        }
      }
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }
}
