import { AutoMap } from '@automapper/classes';
import { Typologic } from 'src/app/model/common/typologic';

export class Attrezzatura {
  @AutoMap()
  id: string;

  @AutoMap()
  numeroSeriale: string;

  @AutoMap({ typeFn: () => Typologic })
  categoriaAttrezzatura: Typologic;

  @AutoMap()
  attrezzaturaId: number;

}
