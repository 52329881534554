import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { CreateUtenteEsterno, UtenteEsterno } from '../../model/utente-esterno';
import { Observable } from 'rxjs';

@Injectable()
export class ExternalUserService {
  private readonly baseUrl = `${environment.baseUrl}/account/external`;

  constructor(private http: HttpClient) {}

  get(userId: string): Observable<UtenteEsterno> {
    return this.http.get<UtenteEsterno>(`${this.baseUrl}/${userId}`);
  }

  create(utenteEsterno: CreateUtenteEsterno): Observable<void> {
    return this.http.post<void>(this.baseUrl, utenteEsterno);
  }

  update(utenteEsterno: CreateUtenteEsterno): Observable<void> {
    return this.http.put<void>(
      `${this.baseUrl}/${utenteEsterno.uuid}`,
      utenteEsterno
    );
  }
}
