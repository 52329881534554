export class StringHelper {
  static format(value: string, ...keys: string[]): string {
    // tslint:disable-next-line:forin
    for (const key in keys) {
      value = value.replace('{' + key + '}', keys[key]);
    }
    return value;
  }

  static parseFromString(value: string): string {
    const parser = new DOMParser();
    return parser.parseFromString(value, 'text/html').body.innerHTML;
  }

  static toNestedProperty = (obj: any, path: string) => {
    path = path.replace(/\[(\w+)\]/g, '.$1');
    path = path.replace(/^\./, '');
    var a = path.split('.');
    for (var i = 0, n = a.length; i < n; ++i) {
      var k = a[i];
      if (k in obj) {
        obj = obj[k];
      } else {
        return;
      }
    }
    return obj;
  };
}
