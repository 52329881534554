import { ModuleWithProviders, NgModule } from '@angular/core';
import { NgBootstrapModule } from './modules/ng-bootstrap.module';
import {
  NgbDateAdapter,
  NgbDateParserFormatter,
  NgbDropdownModule,
  NgbPaginationModule,
} from '@ng-bootstrap/ng-bootstrap';
import { CustomAdapter } from './providers/custom-adapter';
import { CustomDateParserFormatter } from './providers/custom-date-parser-formatter';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { COMPONENTS } from './components';
import { DIRECTIVES } from './directives';
import { PIPES } from './pipes';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PROVIDERS } from './services';
import { ChartsModule } from 'ng2-charts';

@NgModule({
  declarations: [...COMPONENTS, ...DIRECTIVES, ...PIPES],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgBootstrapModule,
    TranslateModule,
    NgbPaginationModule,
    ChartsModule,
  ],
  providers: [...PROVIDERS],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    NgBootstrapModule,
    NgbDropdownModule,
    TranslateModule,
    NgbPaginationModule,
    ...COMPONENTS,
    ...DIRECTIVES,
    ...PIPES,
  ],
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        { provide: NgbDateAdapter, useClass: CustomAdapter },
        {
          provide: NgbDateParserFormatter,
          useClass: CustomDateParserFormatter,
        },
      ],
    };
  }
}
