import {
  CamelCaseNamingConvention,
  MappingProfile,
  mapWith,
} from '@automapper/core';
import { AllegatoDTO } from 'src/app/dto/shared/allegato-dto';
import { DocumentoDTO } from 'src/app/dto/shared/documento-dto';
import { Documento } from '../../common/documento';
import { Allegato } from './allegato';

export const allegatoProfile: MappingProfile = (mapper) => {
  mapper
    .createMap(Allegato, AllegatoDTO, {
      namingConventions: new CamelCaseNamingConvention(),
    })
    .forMember(
      (destination) => destination.documento,
      mapWith(DocumentoDTO, Documento, (source) => source.documento)
    );

  mapper
    .createMap(AllegatoDTO, Allegato, {
      namingConventions: new CamelCaseNamingConvention(),
    })
    .forMember(
      (destination) => destination.documento,
      mapWith(Documento, DocumentoDTO, (source) => source.documento)
    );
};
