import {
  CamelCaseNamingConvention,
  mapFrom,
  MappingProfile,
} from '@automapper/core';
import { LuogoInfortunioDTO } from 'src/app/dto/dipendente-infortunato/luogo-infortunio-dto';
import { TempoLuogo } from '.';

export const tempoLuogoProfile: MappingProfile = (mapper) => {
  mapper
    .createMap(TempoLuogo, LuogoInfortunioDTO, {
      namingConventions: new CamelCaseNamingConvention(),
    })
    .forMember(
      (dest) => dest.indirizzo,
      mapFrom((src) => src.indirizzo.via)
    )

    .forMember(
      (dest) => dest.provincia,
      mapFrom((src) => src.indirizzo.provincia)
    )
    .forMember(
      (dest) => dest.cap,
      mapFrom((src) => src.indirizzo.cap)
    )
    .forMember(
      (dest) => dest.comune,
      mapFrom((src) => src.indirizzo.comune)
    )
    .forMember(
      (dest) => dest.areaOperativa,
      mapFrom((src) => src.dataLuogo.areaOperativa)
    )
    .forMember(
      (dest) => dest.specificaLuogo,
      mapFrom((src) => src.dataLuogo.specificaLuogo)
    )
    .forMember(
      (dest) => dest.tipo,
      mapFrom((src) => src.dataLuogo.luogo.toString())
    )
    .forMember(
      (dest) => dest.objectId,
      mapFrom((src) => src.intervento.objectId)
    )
    .forMember(
      (dest) => dest.tipo,
      mapFrom((src) => src.intervento.tipo)
    )
    .forMember(
      (dest) => dest.tipoLuogo,
      mapFrom((src) => src.dataLuogo.luogo)
    )
    .forMember(
      (dest) => dest.orario,
      mapFrom((src) => src.dataLuogo.orario)
    )
    .forMember(
      (dest) => dest.serviziIstituto,
      mapFrom((src) => src.dataLuogo.serviziIstituto)
    )
    .forMember(
      (dest) => dest.modalitaInfortunio,
      mapFrom((src) => src.dataLuogo.modalitaInfortunioItinere)
    )
    .forMember(
      (dest) => dest.modalitaInfortunio,
      mapFrom((src) => src.dataLuogo.modalitaInfortunioSpostamenti)
    )
    .forMember(
      (dest) => dest.ambientiOperativi,
      mapFrom((src) => src.dataLuogo.ambientiOperativi)
    )
    .forMember(
      (dest) => dest.fasciaOraria,
      mapFrom((src) => src.dataLuogo.fasciaOraria)
    )
    .forMember(
      (dest) => dest.orarioLavoroStraordinario,
      mapFrom((src) => src.dataLuogo.orarioLavoroStraordinario)
    )
    .forMember(
      (dest) => dest.nomeFile,
      mapFrom((src) => src.intervento.nomeFile)
    )
    .forMember(
      (dest) => dest.numeroIntervento,
      mapFrom((src) => src.intervento.numeroIntervento)
    )
    .forMember(
      (dest) => dest.tipologiaInterventoInfortunio,
      mapFrom((src) => src.intervento.tipologiaInterventoInfortunio)
    )
    .forMember(
      (dest) => dest.sedeAccadimento,
      mapFrom((src) => src.indirizzo.sede)
    )
    .forMember(
      (dest) => dest.specificaSedeInfortunio,
      mapFrom((src) => src.indirizzo.specificaSedeInfortunio)
    )
};
