import { AutoMap } from '@automapper/classes';

export class Documento {
  @AutoMap()
  objectId: string;

  @AutoMap()
  name: string;

  @AutoMap()
  type: string;
  id: string;
}
