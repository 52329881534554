import {Injectable} from '@angular/core';
import {CanActivate} from '@angular/router';
import {Observable} from 'rxjs';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../core.module';
import {getToken} from '../store/auth.selectors';
import {map, tap} from 'rxjs/operators';
import { RouterActions } from '../../router/store/router.actions';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private store: Store<AppState>) { }

  canActivate(): Observable<boolean> {
    return this.store.pipe(
      select(getToken),
      map(token => !!token),
      tap(isLogged => {
        if (!isLogged) {
          this.store.dispatch(RouterActions.go({ path: ['/account'] }));
        }
      })
    );
  }

}
