import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import * as dayjs from 'dayjs';
import 'dayjs/locale/it';
import { AppState } from './core/core.module';
import { MessagesActions } from './core/store/messages/messages.actions';
import { MessagesSelectors } from './core/store/messages/messages.selectors';

@Component({
  selector: 'app-root',
  template: `
    <vvf-loader></vvf-loader>
    <vvf-header *vvfIfLogged></vvf-header>
    <main>
      <vvf-sidebar *vvfIfLogged></vvf-sidebar>
      <div class="container-fluid p-0">
        <router-outlet></router-outlet>
      </div>
      <shared-toast
        [messages]="(messages$ | async)!"
        (dismiss)="dismissHandler($event)"
      ></shared-toast>
    </main>
  `,
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  messages$ = this.store.select(MessagesSelectors.getMessages);

  constructor(private store: Store<AppState>) {
    dayjs.locale('it');
  }

  dismissHandler(id: string) {
    this.store.dispatch(MessagesActions.dismiss({ id }));
  }
}
