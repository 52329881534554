import {
  CamelCaseNamingConvention,
  fromValue,
  mapFrom,
  MappingProfile,
} from '@automapper/core';
import { LesioneDTO } from 'src/app/dto/dipendente-infortunato/lesione-dto';
import { GuidProvider } from 'src/app/shared/providers/guid.provider';
import { LesioneItem } from '.';
import {v4 as uuidv4} from 'uuid';

export const lesioneItemProfile: MappingProfile = (mapper) => {
  mapper
    .createMap(LesioneItem, LesioneDTO, {
      namingConventions: new CamelCaseNamingConvention(),
    })
    .forMember(
      (dest) => dest.tipoLesione,
      mapFrom((src) => src.lesione.tipo)
    )
    .forMember(
      (dest) => dest.notaLesione,
      mapFrom((src) => src.lesione.nota)
    )
    .forMember(
      (dest) => dest.tipoParteCorpo,
      mapFrom((src) => src.partiCorpo.parti)
    )
    .forMember(
      (dest) => dest.notaParteCorpo,
      mapFrom((src) => src.partiCorpo.nota)
    );

  mapper
    .createMap(LesioneDTO, LesioneItem, {
      namingConventions: new CamelCaseNamingConvention(),
    })
    .forMember(
      (dest) => dest.lesione.tipo,
      mapFrom((src) => src.tipoLesione)
    )
    .forMember(
      (dest) => dest.lesione.nota,
      mapFrom((src) => src.notaLesione)
    )
    .forMember(
      (dest) => dest.partiCorpo.parti,
      mapFrom((src) => src.tipoParteCorpo)
    )
    .forMember(
      (dest) => dest.partiCorpo.nota,
      mapFrom((src) => src.notaParteCorpo)
    );
};
