import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Provincia } from 'src/app/model/provincia';

@Injectable()
export class ProvinceService {
  constructor(private http: HttpClient) {}

  getAll() {
    return this.http.get<Provincia[]>('assets/mocks/province-italiane.json');
  }
}
