import { Provider } from '@angular/core';
import { FileService } from './file.service';
import { InfortuniService } from './infortuni.service';
import { LocalStorageService } from './local-storage.service';
import { PraticaService } from './pratica.service';
import { ComandiService } from './comandi.service';
import { RuoliService } from './ruoli.service';
import { ExternalUserService } from './external-user.service';
import { PasswordService } from './password.service';
import { AccountsService } from './accounts.service';
import { AccountService } from './account.service';
import { InfortunioService } from './infortunio.service';
import { IncidentiService } from './incidenti.service';
import { IncidenteService } from './incidente.service';
import { UserInfoService } from './user-info.service';
import { AnagraficheUtentiService } from './anagrafiche-utenti.service';
import { TranslateTypologicService } from './translate-typologic.service';
import { TypologicService } from './typologic.service';
import { ManualsService } from './manuals.service';
import { ManualService } from './manuale.service';
import { NoteService } from './note.service';
import { ReportisticaService } from './reportistica.service';
import { ProvinceService } from './province.service';
import { LuoghiService } from './luoghi.service';
import { AmbientiOperativiService } from './ambienti-operativi.service';
import { TipologiaSpostamentoService } from './tipologia-spostamento.service';
import { TipologiaItinereService } from './tipologia-itinere.service';
import { AreaOperativaService } from './area-operativa.service';
import { FileSaverService } from './file-saver.service';
import { ExportToExcelService } from './export-to-excel.service';
import { MezzoService } from './mezzo.service';

export const PROVIDERS: Provider[] = [
  FileService,
  InfortuniService,
  IncidentiService,
  LocalStorageService,
  PraticaService,
  ComandiService,
  RuoliService,
  ExternalUserService,
  PasswordService,
  AccountsService,
  AccountService,
  InfortunioService,
  IncidenteService,
  UserInfoService,
  AnagraficheUtentiService,
  TranslateTypologicService,
  TypologicService,
  ManualsService,
  ManualService,
  NoteService,
  ReportisticaService,
  ProvinceService,
  LuoghiService,
  AmbientiOperativiService,
  TipologiaSpostamentoService,
  TipologiaItinereService,
  AreaOperativaService,
  FileSaverService,
  ExportToExcelService,
  MezzoService
];
