import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

@Component({
  selector: 'shared-checkbox',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div class="custom-control custom-checkbox">
      <input
        [attr.id]="name + '-check'"
        class="custom-control-input"
        type="checkbox"
        [value]="value"
        [checked]="checked"
        (change)="valueChangeHandler($event)"
      />
      <label class="custom-control-label" [attr.for]="name + '-check'">
        {{ description }}
      </label>
    </div>
  `,
})
export class CheckboxComponent {
  @Input() name = '';
  @Input() value: any;
  @Input() checked = false;
  @Input() description = '';
  @Output() valueChange = new EventEmitter<{ value: any; checked: boolean }>();

  valueChangeHandler(event: Event) {
    const { value, checked } = event.target as HTMLInputElement;
    this.valueChange.emit({ value: +value, checked });
  }
}
