import { CamelCaseNamingConvention, MappingProfile } from '@automapper/core';
import { DocumentoDTO } from 'src/app/dto/shared/documento-dto';
import { Documento } from '.';

export const documentoProfile: MappingProfile = (mapper) => {
  mapper.createMap(Documento, DocumentoDTO, {
    namingConventions: new CamelCaseNamingConvention(),
  });
  mapper.createMap(DocumentoDTO, Documento, {
    namingConventions: new CamelCaseNamingConvention(),
  });
};
