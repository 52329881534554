import {ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, EventEmitter} from '@angular/core';
import {Observable, Subject, Subscription} from 'rxjs';
import {PaginationService} from '../../services/pagination.service';
import {IPaginatedService} from '../../services/paginated-iterface.service';
import {Pagination} from '../../models/pagination.model';
import {DataTableColumn} from '../../../model/data-table';

@Component({
  selector: 'vvf-data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.scss']
})
export class DataTableComponent implements OnInit, OnDestroy, OnChanges {
  private subscriptions: Subscription[] = [];

  data$: Observable<any[]>;
  total$: Observable<number>;

  itemsPerPageOptions = [5, 10, 20];
  paginationService: PaginationService<any>;

  @Input() service: IPaginatedService<any>;
  @Input() className: string;
  @Input('searchTerm') searchTerm$: Subject<string>;
  @Input() pagination: Pagination;
  @Input() params: any;
  @Input() columns: DataTableColumn[] = [];
  @Input() head = true;
  @Input() preload = true;
  firstTime = true;
  total: number;

  @Output() actionClicked = new EventEmitter();


  get page() {
    if (this.paginationService) {
      return this.paginationService.currentPage;
    }
    return 0;
  }

  set page(value: number) {
    if (value) {
      this.paginationService.currentPage = value;
    }
  }

  get pageSize() {
    if (this.paginationService) {
      return this.paginationService.itemsPerPage;
    }
    return 5;
  }

  set pageSize(value: number) {
    if (value) {
      this.paginationService.itemsPerPage = value;
    }
  }

  constructor(private cd: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    if (this.service) {
      this.paginationService = new PaginationService(this.service, this.pagination, this.params, this.searchTerm$, this.preload);
      this.data$ = this.paginationService.data$;
      this.total$ = this.paginationService.total$;
      this.subscriptions.push(
        this.data$.subscribe(() => {
          this.firstTime = false;
          this.cd.detectChanges();
        }),
        this.total$.subscribe(total => {
          this.total = total;
        })
      );
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.params) {
      if (this.paginationService) {
        this.paginationService.params = changes.params.currentValue;
      }
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  onActionClicked(action: any){
    this.actionClicked.emit(action);
  }

}
