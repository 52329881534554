import {
  Component,
  forwardRef,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgSelectComponent } from '@ng-select/ng-select';

@Component({
  selector: 'shared-select',
  template: `
    <div [class]="className">
      <label [class.active]="isSetAnyValue" (click)="select.open()">
        <span class="text-danger" *ngIf="mandatory">* </span>
        {{ label }}
      </label>
      <div class="bootstrap-select-wrapper">
        <div class="dropdown bootstrap-select">
          <ng-select
            #select
            class="dropdown-menu"
            [ngStyle]="{ 'width.px': minWidth ? minWidth : 'auto' }"
            [ngClass]="{ 'is-invalid': invalid }"
            [class.ng-select-readonly]="readOnly"
            [(ngModel)]="value"
            [items]="items ? items : []"
            [disabled]="disabled"
            [searchable]="searchable"
            [bindValue]="bindValue"
            [dropdownPosition]="dropdownPosition"
            [multiple]="multiple"
            [closeOnSelect]="closeOnSelect"
            [virtualScroll]="virtualScroll"
            [loadingText]="loadingText + '...'"
            [clearable]="clearable"
            [clearAllText]="clearAllText"
            [placeholder]="placeholder"
            [typeToSearchText]="typeToSearchText"
            [readonly]="readOnly"
            (open)="selectOpen.emit()"
            (change)="selectChange.emit($event)"
            (scrollToEnd)="onScrollToEnd()"
            (clear)="onClear($event)"
            [appendTo]="appendTo"
          >
            <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
              <div class="text-center">
                <small class="text-muted font-weight-light">
                  {{ 'common.noElements' | translate }}
                </small>
              </div>
            </ng-template>
            <ng-template
              ng-loadingtext-tmp
              let-searchTerm="searchTerm"
            ></ng-template>

            <ng-container *ngIf="searchable">
              <ng-template ng-header-tmp>
                <div class="p-3">
                  <input
                    type="text"
                    class="form-control"
                    [placeholder]="typeToSearchText"
                    (input)="selectFilter(select, $event)"
                  />
                </div>
              </ng-template>
            </ng-container>

            <ng-template ng-label-tmp let-item="item">
              <ng-container *ngIf="!multiple; else multipleValues">
                {{ item | bindLabel: bindLabel:labelSeparator:labelPrefix }}
              </ng-container>
              <ng-template #multipleValues>
                <div class="chip chip-simple chip-disabled">
                  <span class="chip-label">
                    {{ item | bindLabel: bindLabel:labelSeparator:labelPrefix }}
                  </span>
                </div>
              </ng-template>
            </ng-template>

            <ng-template ng-option-tmp let-item="item" let-index="index">
              <li>
                <a class="dropdown-item">
                  {{ item | bindLabel: bindLabel:labelSeparator:labelPrefix }}
                </a>
              </li>
            </ng-template>

            <ng-container #footerContainer>
              <ng-template ng-footer-tmp [ngIf]="showFooter">
                <div class="bs-footer">
                  <ng-content select="[footer]"></ng-content>
                </div>
              </ng-template>
            </ng-container>
          </ng-select>
        </div>
      </div>
    </div>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectComponent),
      multi: true,
    },
  ],
})
export class SelectComponent implements ControlValueAccessor {
  private _value = '';
  get value() {
    return this._value;
  }

  set value(val: any) {
    this._value = val;
    this.onChange(val);
    this.onTouch(val);
  }

  get isSetAnyValue(): boolean {
    const value = this.value;

    if (!value) return false;

    return (
      (typeof value === 'object' && !!Object.keys(value).length) ||
      (typeof value !== 'object' && !!value)
    );
  }

  @Input() label = '';
  @Input() items: any[] = [];
  @Input() invalid = false;
  @Input() params: any;
  @Input() preload = true;
  @Input() concat = false;
  @Input() disabled = false;
  @Input() searchable = false;
  @Input() bindValue = '';
  @Input() bindLabel: string | string[] = '';
  @Input() labelSeparator: string = ' ';
  @Input() labelPrefix: string | string[] = '';
  @Input() dropdownPosition: 'bottom' | 'top' | 'auto' = 'bottom';
  @Input() multiple = false;
  @Input() closeOnSelect = true;
  @Input() virtualScroll = false;
  @Input() loadingText = '';
  @Input() clearable = false;
  @Input() clearAllText = '';
  @Input() placeholder = '';
  @Input() typeToSearchText = '';
  @Input() readOnly = false;
  @Input() showFooter = false;
  @Input() minWidth!: number;
  @Input() appendTo!: string;
  @Input() mandatory = false;

  private _className!: string;
  get className(): string {
    return this._className ?? 'form-group';
  }

  @Input() set className(value: string) {
    this._className = value;
  }

  @Output() selectOpen: EventEmitter<void> = new EventEmitter<void>();
  @Output() selectChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() search: EventEmitter<string> = new EventEmitter<string>();
  @Output() scrollToEnd: EventEmitter<void> = new EventEmitter<void>();
  @Output() clear: EventEmitter<any> = new EventEmitter<any>();

  constructor() {}

  onChange: any = () => {};

  onTouch: any = () => {};

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(value: any): void {
    this.value = value;
  }

  onScrollToEnd() {
    this.scrollToEnd.emit();
  }

  onClear(event: any) {
    this.clear.emit(event);
    this.selectChange.emit(event);
  }

  onSearch(event: KeyboardEvent) {
    this.search.emit((event.target as HTMLInputElement).value);
  }

  selectFilter(select: NgSelectComponent, event: Event) {
    select.filter((event.target as HTMLInputElement).value);
  }
}
