import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '../../../core.module';
import { getToken } from '../store/auth.selectors';
import { catchError, switchMap, take } from 'rxjs/operators';
import { RouterActions } from '../../router/store/router.actions';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private store: Store<AppState>) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return this.store.select(getToken).pipe(
      take(1),
      switchMap((token) => {
        const clonedReq = !!token
          ? req.clone({
              setHeaders: { Authorization: 'Bearer ' + token },
            })
          : req;

        return next.handle(clonedReq).pipe(
          catchError((err) => {
            if (err instanceof HttpErrorResponse) {
              if (err.status === 401) {
                this.store.dispatch(RouterActions.go({ path: ['/account'] }));
              }
            }
            return throwError(err);
          })
        );
      })
    );
  }
}
