import { AutoMap } from '@automapper/classes';
import { Documento } from '../../common/documento';
import { Dinamica } from './dinamica';
import { Configs } from './configs';
import { Comando } from '../../comando';

export class DinamicaInfortunio {
  configs: Configs;

  dinamica: Dinamica;

  @AutoMap({ typeFn: () => Documento })
  documenti: Documento[];
}
