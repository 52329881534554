import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { UtenteEsternoLight } from '../../model/utente-esterno';
import { HttpClient } from '@angular/common/http';
import { Pagination } from '../../shared/models/pagination.model';
import { PaginatedResult } from '../../shared/models/paginated-result.model';
import { PaginationBaseService } from '../../shared/services/pagination-base.service';

@Injectable()
export class AccountsService extends PaginationBaseService<UtenteEsternoLight> {
  private readonly baseUrl = environment.baseUrl;

  constructor(private http: HttpClient) {
    super();
  }

  getAllExternal(
    pagination: Partial<Pagination>,
    queryParams?: any
  ): Observable<PaginatedResult<UtenteEsternoLight>> {
    let params = this.getPaginationHttpParams(pagination);
    if (queryParams?.fiscalCode) {
      params = params.append('username', queryParams?.fiscalCode);
    }
    return this.getPaginatedResult(
      this.http.get<UtenteEsternoLight[]>(`${this.baseUrl}/accounts`, {
        observe: 'response',
        params,
      })
    );
  }
}
