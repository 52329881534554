import { AutoMap } from '@automapper/classes';
import { Documento } from '../../common/documento';

export class Allegato {
  id: string;

  @AutoMap()
  titolo: string;

  @AutoMap({ typeFn: () => Documento })
  documento: Documento;
}
