import {
  CamelCaseNamingConvention,
  ignore,
  mapFrom,
  MappingProfile,
  nullSubstitution,
  preCondition,
} from '@automapper/core';
import { AttrezzaturaIncidentataDTO } from 'src/app/dto/attrezzatura-incidentata';
import { AttrezzaturaIncidentata } from '../attrezzatura-incidentata';

export const AttrezzaturaIncidentataProfile: MappingProfile = (mapper) => {
  mapper
    .createMap(AttrezzaturaIncidentata, AttrezzaturaIncidentataDTO, {
      namingConventions: new CamelCaseNamingConvention(),
    })
    .forMember(
      (dest) => dest.categoriaAttrezzaturaId,
      mapFrom((src) => src.informazioniTecniche.attrezzaturaId)
    )
    .forMember(
      (dest) => dest.descrizione,
      mapFrom((src) => src.altreInformazioni.descrizione)
    )
    .forMember(
      (dest) => dest.primaVolta,
      mapFrom((src) => src.altreInformazioni.primaVolta)
    )
    .forMember(
      (dest) => dest.misureOrganizzative,
      mapFrom((src) => src.misureTecniche.misureOrganizzative)
    )
    .forMember(
      (dest) => dest.misureProcedurali,
      mapFrom((src) => src.misureTecniche.misureProcedurali)
    )
    .forMember(
      (dest) => dest.misureTecniche,
      mapFrom((src) => src.misureTecniche.misureTecniche)
    )
    .forMember(
      (dest) => dest.targa,
      mapFrom((src) => src.informazioniTecniche.targa)
    )
    .forMember(
      (dest) => dest.sigla,
      mapFrom((src) => src.informazioniTecniche.sigla)
    )
    .forMember(
      (dest) => dest.codiceGenereMezzo,
      mapFrom((src) => src.informazioniTecniche.codiceGenereMezzo)
    )
    .forMember(
      (dest) => dest.descrizioneGenereMezzo,
      mapFrom((src) => src.informazioniTecniche.descrizioneGenereMezzo)
    )
    .forMember(
      (dest) => dest.tipoGenereMezzo,
      mapFrom((src) => src.informazioniTecniche.tipoGenereMezzo)
    )
    .forMember(
      (dest) => dest.tipoDPI,
      mapFrom((src) => src.informazioniTecniche.tipoDPI)
    );

  mapper
    .createMap(AttrezzaturaIncidentataDTO, AttrezzaturaIncidentata, {
      namingConventions: new CamelCaseNamingConvention(),
    })
    .forMember(
      (dest) => dest.informazioniTecniche.attrezzaturaId,
      mapFrom((src) => src.categoriaAttrezzaturaId)
    )
    .forMember(
      (dest) => dest.informazioniTecniche.categoriaAttrezzaturaId,
      mapFrom((src) => src.categoriaAttrezzaturaIdParent)
    )
    .forMember(
      (dest) => dest.altreInformazioni.descrizione,
      mapFrom((src) => src.descrizione)
    )
    .forMember(
      (dest) => dest.altreInformazioni.primaVolta,
      mapFrom((src) => src.primaVolta)
    )
    .forMember(
      (dest) => dest.misureTecniche.misureOrganizzative,
      mapFrom((src) => src.misureOrganizzative)
    )
    .forMember(
      (dest) => dest.misureTecniche.misureProcedurali,
      mapFrom((src) => src.misureProcedurali)
    )
    .forMember(
      (dest) => dest.misureTecniche.misureTecniche,
      mapFrom((src) => src.misureTecniche)
    )
    .forMember(
      (dest) => dest.informazioniTecniche.targa,
      mapFrom((src) => src.targa)
    )
    .forMember(
      (dest) => dest.informazioniTecniche.sigla,
      mapFrom((src) => src.sigla)
    )
    .forMember(
      (dest) => dest.informazioniTecniche.codiceGenereMezzo,
      mapFrom((src) => src.codiceGenereMezzo)
    )
    .forMember(
      (dest) => dest.informazioniTecniche.descrizioneGenereMezzo,
      mapFrom((src) => src.descrizioneGenereMezzo)
    )
    .forMember(
      (dest) => dest.informazioniTecniche.tipoGenereMezzo,
      mapFrom((src) => src.tipoGenereMezzo)
    )
    .forMember(
      (dest) => dest.informazioniTecniche.tipoDPI,
      mapFrom((src) => src.tipoDPI)
    );
};
