import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'translateWithPrefix',
})
export class TranslateWithPrefixPipe implements PipeTransform {
  constructor(private translate: TranslateService) {}

  transform(value: string, prefix: string): any {
    if (!value) {
      return '';
    }

    if (!prefix) {
      return this.translate.instant(value);
    }

    return this.translate.instant(`${prefix}.${value}`);
  }
}
