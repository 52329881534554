import { catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { MessagesActions } from '../store/messages/messages.actions';
import { AppState } from '../core.module';
import { HttpCustomParams } from 'src/app/model/enum/http-custom-params.enum';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private store: Store<AppState>) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let request = req;
    const bypassErrorHandler =
      req.params.get(HttpCustomParams.BYPASS_ERROR_HANDLER) === 'true';

    if (bypassErrorHandler) {
      request = req.clone({
        params: req.params.delete(HttpCustomParams.BYPASS_ERROR_HANDLER),
      });
    }

    return next.handle(req).pipe(
      catchError((error) => {
        if (error instanceof HttpErrorResponse) {
          const errors = error.error.errors;
          let text = '';

          for (const errorKeyProp of Object.keys(errors)) {
            const errorCodes = errors[errorKeyProp];

            text = bypassErrorHandler
              ? errorCodes[0]
              : `errors.${errorCodes[0]}`;
          }

          this.store.dispatch(MessagesActions.show({ messageType: 'error', title: 'common.error', text }))
        }
        return throwError(error);
      })
    );
  }
}

export const ErrorInterceptorProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: ErrorInterceptor,
  multi: true,
};
