import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from 'src/app/core/core.module';
import { AccountService } from 'src/app/core/services/account.service';
import { Role } from 'src/app/model/role';
import { AuthActions } from '../../../auth/store/auth.actions';
import { SidebarActions } from '../../../sidebar/sidebar.actions';
import { UserSelectors } from '../user/user.selectors';
import { RoleActions } from './role.actions';

@Injectable()
export class RoleEffects {
  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RoleActions.load),
      withLatestFrom(this.store.select(UserSelectors.getRoles)),
      switchMap(([, roles]) =>
        roles.length ? [RoleActions.set({ role: roles[0] })] : []
      )
    )
  );

  set$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RoleActions.set),
      switchMap(({ role }) => [
        RoleActions.save({ role: {} as Role }),
        RoleActions.save({ role }),
        RoleActions.impersonate({ role }),
        SidebarActions.load(),
      ])
    )
  );

  impersonate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RoleActions.impersonate),
      mergeMap(({ role }) =>
        this.accountService
          .impersonate(role)
          .pipe(map((auth) => AuthActions.saveAuth({ auth })))
      )
    )
  );

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private accountService: AccountService
  ) {}
}
