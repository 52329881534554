import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({ name: 'bindLabel' })
export class BindLabelPipe implements PipeTransform {
  constructor(private translate: TranslateService) {}

  transform(value: any, ...args: any[]): string {
    if (!value || (typeof value === 'object' && !Object.keys(value).length))
      return '';

    let bindLabel = args[0];
    if (!Array.isArray(bindLabel)) {
      bindLabel = [bindLabel];
    }

    let labelSeparator = ' ';
    if (args[1]) {
      labelSeparator = args[1];
    }

    let labelPrefix = [];
    if (args[2]) {
      labelPrefix = args[2];
      if (!Array.isArray(args[2])) {
        labelPrefix = [labelPrefix];
      }
    }

    let result = '';

    for (let index = 0; index < bindLabel.length; index++) {
      const valueToPrint =
        typeof value === 'object' ? value[bindLabel[index]] : value;

      if (valueToPrint === undefined) {
        continue;
      }

      result += labelPrefix[index]
        ? this.translate.instant(`${labelPrefix[index]}.${valueToPrint}`)
        : valueToPrint;

      if (index < bindLabel.length - 1) {
        result += labelSeparator;
      }
    }

    return result;
  }
}
