import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { FascicoloIncidente } from '../../model/incidente';
import { environment } from '../../../environments/environment';
import { StringHelper } from '../../shared/providers/string-helper.provider';
import { AttrezzaturaIncidentata } from '../../model/attrezzatura-incidentata';
import { BozzaResponse } from 'src/app/model/bozza-response';
import { mapper } from 'src/app/model/mapper';
import { AttrezzaturaIncidentataDTO } from 'src/app/dto/attrezzatura-incidentata';
import { map } from 'rxjs/operators';
import { Revisione } from 'src/app/model/revisione';

@Injectable()
export class IncidenteService {
  private readonly baseUrl = `${environment.baseUrl}/pratica/{0}/attrezzaturaIncidentata`;

  constructor(private http: HttpClient) {}

  getAttrezzature(idPratica: string): Observable<FascicoloIncidente> {
    return this.http.get<FascicoloIncidente>(
      StringHelper.format(this.baseUrl, idPratica)
    );
  }

  getDraft(idPratica: string, idInfortunio: string) {
    return this.http
      .get<AttrezzaturaIncidentataDTO>(
        `${StringHelper.format(this.baseUrl, idPratica)}/bozza/${idInfortunio}`
      )
      .pipe(
        map((incidenteDto) =>
          mapper.map(
            incidenteDto,
            AttrezzaturaIncidentata,
            AttrezzaturaIncidentataDTO
          )
        )
      );
  }

  saveDraft(idPratica: string, scheda: AttrezzaturaIncidentata) {
    return this.http.put<BozzaResponse>(
      `${StringHelper.format(this.baseUrl, idPratica)}/bozza`,
      mapper.map(scheda, AttrezzaturaIncidentataDTO, AttrezzaturaIncidentata)
    );
  }

  updateDraft(
    idPratica: string,
    idAttrezzaturaIncidente: string,
    scheda: AttrezzaturaIncidentata
  ) {
    return this.http.put<BozzaResponse>(
      `${StringHelper.format(
        this.baseUrl,
        idPratica
      )}/bozza/${idAttrezzaturaIncidente}`,
      mapper.map(scheda, AttrezzaturaIncidentataDTO, AttrezzaturaIncidentata)
    );
  }

  delete(idPratica: string, idAttrezzaturaIncidente: string) {
    return this.http.delete<AttrezzaturaIncidentataDTO>(
      `${StringHelper.format(
        this.baseUrl,
        idPratica
      )}/${idAttrezzaturaIncidente}`
    );
  }

  getRevisioni(idPratica: string) {
    return this.http.get<Revisione[]>(
      `${StringHelper.format(this.baseUrl, idPratica)}/revisioni`
    );
  }

  sendDraftInseritore(idPratica: string, idIncidente: string) {
    return this.http.put<BozzaResponse>(
      `${StringHelper.format(
        this.baseUrl,
        idPratica
      )}/bozza/inviaResponsabile/${idIncidente}`,
      {}
    );
  }
}
