import {createFeatureSelector, createSelector} from '@ngrx/store';
import {AppState} from '../../../core.module';
import {UIState} from './ui.reducer';

export const getUI = createFeatureSelector<AppState, UIState>('ui');

export const isSidebarOpened = createSelector(
  getUI,
  (state: UIState) => state.sidebarOpened
);
