import { Component, Input } from '@angular/core';
import { Anagrafica } from 'src/app/model/common/anagrafica';

@Component({
  selector: 'vvf-registro-infortuni-dipendente-anagrafica',
  template: `
    <div class="row mt-3">
      <div class="col-12 col-md-6 col-lg-3">
        <vvf-input-text
          [ngModel]="anagrafica.codiceFiscale"
          label="Codice Fiscale"
          [readOnly]="true"
        ></vvf-input-text>
      </div>
      <div class="col-12 col-md-6 col-lg-3">
        <vvf-input-date
          [ngModel]="anagrafica.dataNascita"
          label="Data Di Nascita"
          [readOnly]="true"
        ></vvf-input-date>
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-md-6 col-lg-3">
        <vvf-input-text
          [ngModel]="anagrafica.nome"
          label="Nome"
          [readOnly]="true"
        ></vvf-input-text>
      </div>
      <div class="col-12 col-md-6 col-lg-3">
        <vvf-input-text
          [ngModel]="anagrafica.cognome"
          label="Cognome"
          [readOnly]="true"
        ></vvf-input-text>
      </div>

      <div class="col-12 col-md-6 col-lg-3">
        <vvf-input-text
          [ngModel]="anagrafica.sesso"
          label="Sesso"
          [readOnly]="true"
        ></vvf-input-text>
      </div>

      <div class="col-12 col-md-6 col-lg-3">
        <vvf-input-text
          [ngModel]="anagrafica.luogoNascita"
          label="Luogo Di Nascita"
          [readOnly]="true"
        ></vvf-input-text>
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-md-6 col-lg-5">
        <vvf-input-text
          [ngModel]="anagrafica.indirizzo"
          label="Indirizzo"
          [readOnly]="true"
        ></vvf-input-text>
      </div>
      <div class="col-12 col-md-6 col-lg-3">
        <vvf-input-text
          [ngModel]="anagrafica.comune"
          label="Comune"
          [readOnly]="true"
        ></vvf-input-text>
      </div>
      <div class="col-12 col-md-6 col-lg-2">
        <vvf-input-text
          [ngModel]="anagrafica.provincia"
          label="Provincia"
          [readOnly]="true"
        ></vvf-input-text>
      </div>
      <div class="col-12 col-md-6 col-lg-2">
        <vvf-input-text
          [ngModel]="anagrafica.cap"
          label="CAP"
          [readOnly]="true"
        ></vvf-input-text>
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-md-6 col-lg-3">
        <vvf-input-text
          [ngModel]="anagrafica.uo"
          label="UO"
          [readOnly]="true"
        ></vvf-input-text>
      </div>
      <div class="col-12 col-md-6 col-lg-3">
        <vvf-input-text
          [ngModel]="anagrafica.ruolo"
          label="Ruolo"
          [readOnly]="true"
        ></vvf-input-text>
      </div>
      <div class="col-12 col-md-6 col-lg-6">
        <vvf-input-text
          [ngModel]="anagrafica.qualifica"
          label="Qualifica"
          [readOnly]="true"
        ></vvf-input-text>
      </div>
    </div>
    <div class="col-12 col-md-6 col-lg-3">
      <vvf-input-text
        [ngModel]="anagrafica.articolazioneOrdinaria"
        label="Articolazione Orario Lavoro"
        [readOnly]="true"
      ></vvf-input-text>
    </div>
  `,
})
export class AnagraficaDetailComponent {
  @Input() anagrafica: Anagrafica = {} as Anagrafica;
}
