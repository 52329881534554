import { Component } from '@angular/core';
import { isSidebarOpened } from '../core/store/ui/store/ui.selectors';
import { Store } from '@ngrx/store';
import { AppState } from '../core/core.module';
import { TranslateService } from '@ngx-translate/core';
import { SidebarItem } from '../core/store/sidebar/sidebar.reducer';
import { SidebarSelectors } from '../core/store/sidebar/sidebar.selectors';

@Component({
  selector: 'vvf-sidebar',
  template: `
    <nav id="sidebar" [ngClass]="{ active: isSidebarOpened$ | async }">
      <div class="link-list-wrapper link-list-wrapper--sidebar pt-4">
        <ul class="link-list">
          <li *ngFor="let item of sidebarItems$ | async">
            <a
              class="list-item left-icon"
              [routerLink]="item.link"
              [routerLinkActive]="'active'"
            >
              <i [class]="item.class">{{ item.icon }}</i>
              <span>{{ item.text | translate }}</span>
            </a>
          </li>
        </ul>
      </div>
    </nav>
  `,
})
export class SidebarComponent {
  items: SidebarItem[] = [
    {
      text: this.translate.instant('home.title'),
      class: 'fas fa-home',
      link: ['/home'],
    },
    {
      text: this.translate.instant('infortuni.title'),
      class: 'fas fa-bolt',
      link: ['/infortuni'],
    },
    {
      text: this.translate.instant('incidenti.title'),
      class: 'material-icons',
      icon: 'feedback',
      link: ['/incidenti'],
    },
    {
      text: this.translate.instant('mieiInfortuni.title'),
      class: 'material-icons',
      icon: 'assignment_ind',
      link: ['/miei-infortuni'],
    },
    {
      text: this.translate.instant('manuali.title'),
      class: 'material-icons',
      icon: 'menu_book',
      link: ['/manuali-operativi'],
    },
    {
      text: this.translate.instant('Utenti Esterni'),
      class: 'material-icons',
      icon: 'app_registration',
      link: ['/utenti-esterni'],
    },
    {
      text: this.translate.instant('Utenti Interni'),
      class: 'material-icons',
      icon: 'list_alt',
      link: ['/utenti-interni'],
    },
  ];

  sidebarItems$ = this.store.select(SidebarSelectors.getItems);
  isSidebarOpened$ = this.store.select(isSidebarOpened);

  constructor(
    private store: Store<AppState>,
    private translate: TranslateService
  ) {}
}
