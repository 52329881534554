import {
  Directive,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil, map } from 'rxjs/operators';
import { AppState } from 'src/app/core/core.module';
import { RoleSelectors } from 'src/app/core/store/profile/store/role/role.selectors';
import { Role, RoleEnum } from 'src/app/model/role';

@Directive({ selector: '[rolesExcept]' })
export class RolesExceptDirective implements OnInit, OnDestroy {
  private destroy$ = new Subject();

  @Input() rolesExcept: RoleEnum | RoleEnum[] | null = null;

  constructor(
    private templateRef: TemplateRef<any>,
    private view: ViewContainerRef,
    private store: Store<AppState>
  ) {}

  ngOnInit(): void {
    if (!this.rolesExcept) {
      this.view.clear();
      this.view.createEmbeddedView(this.templateRef);
      return;
    }

    this.store
      .select(RoleSelectors.rolesExcept, { roles: this.rolesExcept })
      .pipe(
        map((allowed) => !!allowed),
        takeUntil(this.destroy$)
      )
      .subscribe((allowed) =>
        allowed
          ? this.view.createEmbeddedView(this.templateRef)
          : this.view.clear()
      );
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }
}
