import {Injectable} from '@angular/core';

@Injectable()
export class LocalStorageService {

  setSavedState(state: any, localStorageKey: string) {
    localStorage.setItem(localStorageKey, JSON.stringify(state));
  }

  getSavedState(localStorageKey: string) {
    return JSON.parse(localStorage.getItem(localStorageKey) as string);
  }

}

