import {Pipe, PipeTransform} from '@angular/core';
import duration, {DurationUnitType} from 'dayjs/plugin/duration';
import * as dayjs from 'dayjs';

@Pipe({
  name: 'duration'
})
export class DurationPipe implements PipeTransform {

  transform(value: number, unit: DurationUnitType): string {
    dayjs.extend(duration);
    const result = dayjs.duration(value, 'milliseconds').get(unit);
    return result.toString();
  }

}
