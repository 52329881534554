import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { FascicoloInfortunio, InfortuniFilters } from '../../model/infortunio';
import { PaginationBaseService } from '../../shared/services/pagination-base.service';
import { PaginatedResult } from '../../shared/models/paginated-result.model';
import { Pagination } from '../../shared/models/pagination.model';

@Injectable()
export class InfortuniService extends PaginationBaseService<FascicoloInfortunio> {
  private readonly baseUrl = `${environment.baseUrl}/pratiche/infortuni`;

  constructor(private http: HttpClient) {
    super();
  }

  getInfortuni(
    pagination: Partial<Pagination>,
    filters?: Partial<InfortuniFilters>
  ): Observable<PaginatedResult<FascicoloInfortunio>> {
    let params = this.getPaginationHttpParams(pagination);

    if (filters?.fiscalCode) {
      params = params.append('codiceFiscale', filters.fiscalCode);
    }

    if (filters?.name) {
      params = params.append('nome', filters.name);
    }

    if (filters?.surname) {
      params = params.append('cognome', filters.surname);
    }

    if (filters?.eventDate) {
      params = params.append('dataEvento', filters.eventDate.toString());
    }

    if (filters?.sendDate) {
      params = params.append('dataInvio', filters.sendDate.toString());
    }

    if (filters?.stato) {
      params = params.append('stato', filters.stato);
    }

    return this.getPaginatedResult(
      this.http.get<FascicoloInfortunio[]>(this.baseUrl, {
        observe: 'response',
        params,
      })
    );
  }

  getMyInfortuni(pagination: Partial<Pagination>) {
    let params = this.getPaginationHttpParams(pagination);
    return this.getPaginatedResult(
      this.http.get<FascicoloInfortunio[]>(`${this.baseUrl}/owner`, {
        observe: 'response',
        params,
      })
    );
  }
}
