import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Injectable} from '@angular/core';

@Injectable()
export class PasswordService {

  private readonly baseUrl = environment.baseUrl;

  constructor(private http: HttpClient) {
  }

  forgottenPassword(username: string) {
    return this.http.post(`${this.baseUrl}/Account/ForgotPassword`, {username});
  }

  resetPassword(userId: string) {
    return this.http.post(`${this.baseUrl}/Account/ResetPassword`, {userId});
  }

  changePassword(password: string, token?: string | null) {
    return this.http.post(`${this.baseUrl}/Account/password`, {token, password});
  }

}
