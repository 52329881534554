import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import {
  FascicoloIncidenteGridItem,
  IncidenteAction,
  IncidentiAction,
} from '../../../model/incidente';

@Component({
  selector: 'vvf-incidenti-grid',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <ng-container *ngIf="!fascicoli?.length; else table">
      <div class="d-flex justify-content-center w-100">
        {{ 'common.noResult' | translate }}
      </div>
    </ng-container>

    <ng-template #table>
      <table class="table table-bordered">
        <thead>
          <tr>
            <th scope="col">
              {{ 'fascicoli.eventDate' | translate }}
            </th>
            <th scope="col">
              {{ 'fascicoli.createdBy' | translate }}
            </th>
            <th scope="col">
              {{ 'fascicoli.lastEdit' | translate }}
            </th>
            <th scope="col" class="text-center">
              {{ 'fascicoli.state' | translate }}
            </th>
            <th scope="col" class="text-center">
              {{ 'common.actions' | translate }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            *ngFor="
              let fascicolo of fascicoli;
              let fascicoloIndex = index;
              trackBy: fascicoliTrackByFn
            "
          >
            <td class="align-middle">
              {{ fascicolo.dataEvento | date }}
            </td>
            <td class="align-middle">
              {{ fascicolo.creatoDa }} <br />
              il {{ fascicolo.dataCreazione | date }}
            </td>
            <td class="align-middle">
              <ng-container *ngIf="fascicolo.ultimaModifica; else empty">
                {{ fascicolo.modificatoDa }} <br />
                il {{ fascicolo.ultimaModifica | date }}
              </ng-container>
              <ng-template #empty>--</ng-template>
            </td>
            <td class="text-center align-middle">
              <span class="text-secondary font-weight-bold">
                {{
                  'fascicoli.status.' + fascicolo.stato | translate | uppercase
                }}
              </span>
            </td>
            <td class="text-center align-middle">
              <vvf-incidenti-grid-actions
                [index]="fascicoloIndex"
                [fascicolo]="fascicolo"
                (actionClick)="actionClick.emit($event)"
              ></vvf-incidenti-grid-actions>
            </td>
          </tr>
        </tbody>
      </table>
    </ng-template>
  `,
})
export class IncidentiGridComponent {
  @Input() fascicoli: FascicoloIncidenteGridItem[];
  @Output() actionClick = new EventEmitter<IncidentiAction>();

  fascicoliTrackByFn = (index: number) => index;
}
