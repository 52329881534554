import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FiltersState as Filters } from 'src/app/features/gestione-manuali/features/list/store/filters/filters.state';
import { Manual } from 'src/app/model/manual';
import { PaginatedResult } from 'src/app/shared/models/paginated-result.model';
import { Pagination } from 'src/app/shared/models/pagination.model';
import { PaginationBaseService } from 'src/app/shared/services/pagination-base.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class ManualsService extends PaginationBaseService<Manual> {
  private readonly baseUrl = `${environment.baseUrl}/manuali`;

  constructor(private http: HttpClient) {
    super();
  }

  getAllActive(): Observable<Manual[]> {
    return this.http.get<Manual[]>(`${this.baseUrl}/attivi`);
  }

  getAll(
    pagination: Pagination,
    filter: Filters
  ): Observable<PaginatedResult<Manual>> {
    let params = this.getPaginationHttpParams(pagination);

    if (filter.stato) {
      params = params.append('StatoManuale', filter.stato);
    }

    if (filter.descrizione) {
      params = params.append('Descrizione', filter.descrizione);
    }

    if (filter.role) {
      params = params.append('Ruolo', filter.role);
    }

    return this.getPaginatedResult(
      this.http.get<Manual[]>(this.baseUrl, {
        observe: 'response',
        params,
      })
    );
  }
}
