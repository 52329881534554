import {createReducer, on} from '@ngrx/store';
import * as UIActions from './ui.actions';

export interface UIState {
  sidebarOpened: boolean;
}

export const initialState = {
  sidebarOpened: true
};

export const uiReducer = createReducer(
  initialState,
  on(UIActions.toggleSidebar, (state) => ({ ...state, sidebarOpened: !state.sidebarOpened }))
);
