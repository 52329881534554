import {
  CamelCaseNamingConvention,
  mapFrom,
  MappingProfile,
  mapWith,
  preCondition,
} from '@automapper/core';
import { DipendenteInfortunatoDTO } from 'src/app/dto/dipendente-infortunato';
import { AnagraficaDTO } from 'src/app/dto/dipendente-infortunato/anagrafica-dto';
import { CertificatoMedicoDTO } from 'src/app/dto/dipendente-infortunato/certificato-medico-dto';
import { LesioneDTO } from 'src/app/dto/dipendente-infortunato/lesione-dto';
import { LuogoInfortunioDTO } from 'src/app/dto/dipendente-infortunato/luogo-infortunio-dto';
import { TestimoneDTO } from 'src/app/dto/dipendente-infortunato/testimone-dto';
import { AllegatoDTO } from 'src/app/dto/shared/allegato-dto';
import { DocumentoDTO } from 'src/app/dto/shared/documento-dto';
import { DipendenteInfortunato } from '.';
import { Anagrafica } from '../common/anagrafica';
import { Documento } from '../common/documento';
import { Allegato } from './allegati/allegato';
import { Dinamica } from './dinamica-infortunio/dinamica';
import { Testimone } from './informazioni-tecniche/testimoni/testimone';
import { LesioneItem } from './lesioni/lesione-item';
import { CertificatoMedico } from './prognosi/certificato-medico';
import { TempoLuogo } from './tempo-luogo';
import { DataLuogo } from './tempo-luogo/data-luogo';
import { Indirizzo } from './tempo-luogo/indirizzo';
import { Intervento } from './tempo-luogo/intervento';
import { Attrezzature } from './informazioni-tecniche/attrezzature';
import { Mezzi } from './informazioni-tecniche/mezzi';
import { Dpi } from './informazioni-tecniche/dpi';
import { Testimoni } from './informazioni-tecniche/testimoni';
import { AttrezzaturaDTO } from './../../dto/dipendente-infortunato/attrezzatura-dto';
import { Attrezzatura } from 'src/app/model/dipendente-infortunato/informazioni-tecniche/attrezzature/attrezzatura';
import { MezzoDTO } from 'src/app/dto/dipendente-infortunato/mezzo-dto';
import { Mezzo } from './informazioni-tecniche/mezzi/mezzo';
import { DpiDTO } from 'src/app/dto/dipendente-infortunato/dpi-dto';
import { DpiItem } from 'src/app/model/dipendente-infortunato/informazioni-tecniche/dpi/dpi-item';

export const dipendenteInfortunatoProfile: MappingProfile = (mapper) => {
  mapper
    .createMap(DipendenteInfortunato, DipendenteInfortunatoDTO, {
      namingConventions: new CamelCaseNamingConvention(),
    })
    .forMember(
      (dest) => dest.infortunato,
      mapWith(AnagraficaDTO, Anagrafica, (src) => src.dipendente.infortunato)
    )
    .forMember(
      (dest) => dest.infortunato.codiceFiscale,
      mapFrom((src) => src.dipendente.infortunato.codiceFiscale)
    )
    .forMember(
      (dest) => dest.infortunato.nome,
      mapFrom((src) => src.dipendente.infortunato.nome)
    )
    .forMember(
      (dest) => dest.infortunato.cognome,
      mapFrom((src) => src.dipendente.infortunato.cognome)
    )
    .forMember(
      (dest) => dest.infortunato.sesso,
      mapFrom((src) => src.dipendente.infortunato.sesso)
    )
    .forMember(
      (dest) => dest.infortunato.indirizzo,
      mapFrom((src) => src.dipendente.infortunato.indirizzo)
    )
    .forMember(
      (dest) => dest.infortunato.provincia,
      mapFrom((src) => src.dipendente.infortunato.provincia)
    )
    .forMember(
      (dest) => dest.infortunato.lavoro,
      mapFrom((src) => src.dipendente.infortunato.lavoro)
    )
    .forMember(
      (dest) => dest.infortunato.ruolo,
      mapFrom((src) => src.dipendente.infortunato.ruolo)
    )
    .forMember(
      (dest) => dest.infortunato.qualifica,
      mapFrom((src) => src.dipendente.infortunato.qualifica)
    )
    .forMember(
      (dest) => dest.infortunato.cap,
      mapFrom((src) => src.dipendente.infortunato.cap)
    )
    .forMember(
      (dest) => dest.infortunato.comune,
      mapFrom((src) => src.dipendente.infortunato.comune)
    )
    .forMember(
      (dest) => dest.infortunato.dataNascita,
      mapFrom((src) => src.dipendente.infortunato.dataNascita)
    )
    .forMember(
      (dest) => dest.infortunato.luogoNascita,
      mapFrom((src) => src.dipendente.infortunato.luogoNascita)
    )
    .forMember(
      (dest) => dest.infortunato.uo,
      mapFrom((src) => src.dipendente.infortunato.sede)
    )
    .forMember(
      (dest) => dest.infortunato.articolazioneOrdinaria,
      mapFrom((src) => src.dipendente.infortunato.turno)
    )
    .forMember(
      (dest) => dest.infortunato.sipec,
      mapFrom((src) => src.dipendente.infortunato.sipec)
    )
    .forMember(
      (dest) => dest.dataInfortunio,
      mapFrom((src) => src.tempoLuogo.dataLuogo.data)
    )
    .forMember(
      (dest) => dest.causaInfortunioDescrizione,
      mapFrom(
        (src) => src.dinamicaInfortunio.dinamica.causaInfortunioDescrzione
      )
    )
    .forMember(
      (dest) => dest.tipoEventoDescrzione,
      mapFrom((src) => src.dinamicaInfortunio.dinamica.tipoEventoDescrzione)
    )
    .forMember(
      (dest) => dest.eventoImprovviso,
      mapFrom((src) => src.dinamicaInfortunio.dinamica.eventoImprovviso)
    )
    .forMember(
      (dest) => dest.luogoInfortunio,
      mapWith(LuogoInfortunioDTO, TempoLuogo, (src) => src.tempoLuogo)
    )
    .forMember(
      (dest) => dest.luogoInfortunio.orarioLavoroStraordinario,
      mapFrom((src) => src.tempoLuogo.dataLuogo.orarioLavoroStraordinario)
    )
    .forMember(
      (dest) => dest.sedeAccadimento,
      mapFrom((src) => src.tempoLuogo.indirizzo.sede)
    )
    .forMember(
      (dest) => dest.specificaSedeInfortunio,
      mapFrom((src) => src.tempoLuogo.indirizzo.specificaSedeInfortunio)
    )

    .forMember(
      (dest) => dest.progressivoScheda,
      mapFrom((src) => src.dinamicaInfortunio.dinamica.progressivoScheda)
    )
    .forMember(
      (dest) => dest.numeroScheda,
      mapFrom((src) => src.dinamicaInfortunio.dinamica.numeroScheda)
    )
    .forMember(
      (dest) => dest.dominio,
      mapFrom((src) => src.dinamicaInfortunio.dinamica.dominio)
    )
    .forMember(
      (dest) => dest.dataIntervento,
      mapFrom((src) => src.dinamicaInfortunio.dinamica.dataIntervento)
    )

    .forMember(
      (dest) => dest.causaInfortunio,
      mapFrom((src) => src.dinamicaInfortunio.dinamica.causaInfortunio)
    )
    .forMember(
      (dest) => dest.descrizioneCausaInfortunio,
      mapFrom(
        (src) => src.dinamicaInfortunio.dinamica.descrizioneCausaInfortunio
      )
    )
    .forMember(
      (dest) => dest.tipoEvento,
      mapFrom((src) => src.dinamicaInfortunio.dinamica.tipoEvento)
    )
    .forMember(
      (dest) => dest.descrizioneAttivita,
      mapFrom((src) => src.dinamicaInfortunio.dinamica.descrizioneInfortunio)
    )
    .forMember(
      (dest) => dest.tipoAttivitaDescrizione,
      mapFrom((src) => src.dinamicaInfortunio.dinamica.tipoAttivitaDescrizione)
    )
    .forMember(
      (dest) => dest.altezzaCaduta,
      preCondition((src) => src.dinamicaInfortunio.dinamica.tipoEvento === 1),
      mapFrom((src) => src.dinamicaInfortunio.dinamica.altezzaCaduta)
    )
    .forMember(
      (dest) => dest.documentazione,
      mapWith(
        DocumentoDTO,
        Documento,
        (src) => src.dinamicaInfortunio.documenti
      )
    )
    .forMember(
      (dest) => dest.attrezzatureEnabled,
      mapFrom((src) => src.informazioniTecniche.attrezzature.enabled)
    )
    .forMember(
      (dest) => dest.attrezzature,
      mapWith(
        AttrezzaturaDTO,
        Attrezzatura,
        (src) => src.informazioniTecniche.attrezzature.items
      )
    )
    .forMember(
      (dest) => dest.mezziEnabled,
      mapFrom((src) => src.informazioniTecniche.mezzi.enabled)
    )
    .forMember(
      (dest) => dest.mezzi,
      mapWith(MezzoDTO, Mezzo, (src) => src.informazioniTecniche.mezzi.items)
    )
    .forMember(
      (dest) => dest.dpiEnabled,
      mapFrom((src) => src.informazioniTecniche.dpi.enabled)
    )
    .forMember(
      (dest) => dest.dpi,
      mapWith(DpiDTO, DpiItem, (src) => src.informazioniTecniche.dpi.items)
    )
    .forMember(
      (dest) => dest.altrePersone,
      mapFrom((src) => src.informazioniTecniche.altrePersone)
    )
    .forMember(
      (dest) => dest.attivitaConsueta,
      mapFrom((src) => src.informazioniTecniche.attivitaConsueta)
    )
    .forMember(
      (dest) => dest.lavorazioneSvolta,
      mapFrom((src) => src.informazioniTecniche.lavorazioneSvolta)
    )
    .forMember(
      (dest) => dest.testimoniEnabled,
      mapFrom((src) => src.informazioniTecniche.testimoni.enabled)
    )
    .forMember(
      (dest) => dest.testimoni,
      mapWith(
        TestimoneDTO,
        Testimone,
        (src) => src.informazioniTecniche.testimoni.items
      )
    )
    .forMember(
      (dest) => dest.tipoSoccorso,
      mapFrom((src) => src.informazioniTecniche.tipoSoccorso)
    )
    .forMember(
      (dest) => dest.giorniPS,
      mapFrom((src) => src.prognosi.giorniPS)
    )
    .forMember(
      (dest) => dest.decessoDipendente,
      mapFrom((src) => src.prognosi.decessoDipendente)
    )
    .forMember(
      (dest) => dest.conseguenzaLesioni,
      mapFrom((src) => src.lesioni.conseguenzaLesioni)
    )
    .forMember(
      (dest) => dest.certificatiMedici,
      mapWith(
        CertificatoMedicoDTO,
        CertificatoMedico,
        (src) => src.prognosi.certificatiMedici
      )
    )
    .forMember(
      (dest) => dest.lesioni,
      mapWith(LesioneDTO, LesioneItem, (src) => src.lesioni.items)
    )
    .forMember(
      (dest) => dest.attiIstruttori,
      mapWith(AllegatoDTO, Allegato, (src) => src.allegati.attiIstruttori)
    )
    .forMember(
      (dest) => dest.documentazioneSanitaria,
      mapWith(
        AllegatoDTO,
        Allegato,
        (src) => src.allegati.documentazioneSanitaria
      )
    );

  mapper
    .createMap(DipendenteInfortunatoDTO, DipendenteInfortunato, {
      namingConventions: new CamelCaseNamingConvention(),
    })
    .forMember(
      (dest) => dest.dipendente.infortunato,
      mapWith(Anagrafica, AnagraficaDTO, (src) => src.infortunato)
    )
    .forMember(
      (dest) => dest.dipendente.infortunato.codiceFiscale,
      mapFrom((src) => src.infortunato.codiceFiscale)
    )
    .forMember(
      (dest) => dest.dipendente.infortunato.nome,
      mapFrom((src) => src.infortunato.nome)
    )
    .forMember(
      (dest) => dest.dipendente.infortunato.cognome,
      mapFrom((src) => src.infortunato.cognome)
    )
    .forMember(
      (dest) => dest.dipendente.infortunato.sesso,
      mapFrom((src) => src.infortunato.sesso)
    )
    .forMember(
      (dest) => dest.dipendente.infortunato.indirizzo,
      mapFrom((src) => src.infortunato.indirizzo)
    )
    .forMember(
      (dest) => dest.dipendente.infortunato.provincia,
      mapFrom((src) => src.infortunato.provincia)
    )
    .forMember(
      (dest) => dest.dipendente.infortunato.lavoro,
      mapFrom((src) => src.infortunato.lavoro)
    )
    .forMember(
      (dest) => dest.dipendente.infortunato.ruolo,
      mapFrom((src) => src.infortunato.ruolo)
    )
    .forMember(
      (dest) => dest.dipendente.infortunato.qualifica,
      mapFrom((src) => src.infortunato.qualifica)
    )
    .forMember(
      (dest) => dest.dipendente.infortunato.cap,
      mapFrom((src) => src.infortunato.cap)
    )
    .forMember(
      (dest) => dest.dipendente.infortunato.comune,
      mapFrom((src) => src.infortunato.comune)
    )
    .forMember(
      (dest) => dest.dipendente.infortunato.dataNascita,
      mapFrom((src) => src.infortunato.dataNascita)
    )
    .forMember(
      (dest) => dest.dipendente.infortunato.dataNascita,
      mapFrom((src) => src.infortunato.dataNascita)
    )
    .forMember(
      (dest) => dest.dipendente.infortunato.luogoNascita,
      mapFrom((src) => src.infortunato.luogoNascita)
    )
    .forMember(
      (dest) => dest.dipendente.infortunato.turno,
      mapFrom((src) => src.infortunato.articolazioneOrdinaria)
    )
    .forMember(
      (dest) => dest.dipendente.infortunato.sede,
      mapFrom((src) => src.infortunato.uo)
    )
    .forMember(
      (dest) => dest.dipendente.infortunato.sipec,
      mapFrom((src) => src.infortunato.sipec)
    )
    .forMember(
      (dest) => dest.tempoLuogo.dataLuogo,
      mapWith(DataLuogo, DipendenteInfortunatoDTO, (src) => src)
    )
    .forMember(
      (dest) => dest.tempoLuogo.dataLuogo.orarioLavoroStraordinario,
      mapFrom((src) => src.luogoInfortunio.orarioLavoroStraordinario)
    )
    .forMember(
      (dest) => dest.tempoLuogo.intervento,
      mapWith(Intervento, LuogoInfortunioDTO, (src) => src.luogoInfortunio)
    )
    .forMember(
      (dest) => dest.tempoLuogo.indirizzo,
      mapWith(Indirizzo, LuogoInfortunioDTO, (src) => src.luogoInfortunio)
    )
    .forMember(
      (dest) => dest.tempoLuogo.indirizzo.sede,
      mapFrom((src) => src.luogoInfortunio.sedeAccadimento)
    )
    .forMember(
      (dest) => dest.dinamicaInfortunio.dinamica,
      mapWith(Dinamica, DipendenteInfortunatoDTO, (src) => src)
    )
    .forMember(
      (dest) => dest.dinamicaInfortunio.documenti,
      mapWith(Documento, DocumentoDTO, (src) => src.documentazione)
    )
    .forMember(
      (dest) => dest.informazioniTecniche.attrezzature,
      mapWith(Attrezzature, DipendenteInfortunatoDTO, (src) => src)
    )
    .forMember(
      (dest) => dest.informazioniTecniche.mezzi,
      mapWith(Mezzi, DipendenteInfortunatoDTO, (src) => src)
    )

    .forMember(
      (dest) => dest.informazioniTecniche.mezzi.items,
      mapWith(Mezzo, MezzoDTO, (src) => src.mezzi)
    )

    .forMember(
      (dest) => dest.informazioniTecniche.dpi,
      mapWith(Dpi, DipendenteInfortunatoDTO, (src) => src)
    )
    .forMember(
      (dest) => dest.informazioniTecniche.altrePersone,
      mapFrom((src) => src.altrePersone)
    )
    .forMember(
      (dest) => dest.informazioniTecniche.attivitaConsueta,
      mapFrom((src) => src.attivitaConsueta)
    )
    .forMember(
      (dest) => dest.informazioniTecniche.lavorazioneSvolta,
      mapFrom((src) => src.lavorazioneSvolta)
    )
    .forMember(
      (dest) => dest.informazioniTecniche.testimoni,
      mapWith(Testimoni, DipendenteInfortunatoDTO, (src) => src)
    )
    .forMember(
      (dest) => dest.informazioniTecniche.tipoSoccorso,
      mapFrom((src) => src.tipoSoccorso)
    )
    .forMember(
      (dest) => dest.prognosi.giorniPS,
      mapFrom((src) => src.giorniPS)
    )
    .forMember(
      (dest) => dest.prognosi.decessoDipendente,
      mapFrom((src) => src.decessoDipendente)
    )
    .forMember(
      (dest) => dest.lesioni.conseguenzaLesioni,
      mapFrom((src) => src.conseguenzaLesioni)
    )
    .forMember(
      (dest) => dest.prognosi.certificatiMedici,
      mapWith(
        CertificatoMedico,
        CertificatoMedicoDTO,
        (src) => src.certificatiMedici
      )
    )
    .forMember(
      (dest) => dest.lesioni.items,
      mapWith(LesioneItem, LesioneDTO, (src) => src.lesioni)
    )
    .forMember(
      (dest) => dest.allegati.attiIstruttori,
      mapWith(Allegato, AllegatoDTO, (src) => src.attiIstruttori)
    )
    .forMember(
      (dest) => dest.allegati.documentazioneSanitaria,
      mapWith(Allegato, AllegatoDTO, (src) => src.documentazioneSanitaria)
    );
};
