import {
  Directive,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormControlDirective, NgControl } from '@angular/forms';
import { isEqual } from 'lodash-es';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';

@Directive({ selector: '[connectControl]' })
export class ConnectControlDirective implements OnInit, OnChanges, OnDestroy {
  private destroy$ = new Subject();

  @Input() connectControl: any;
  @Input() debounceTime = 0;
  @Output() valueChange = new EventEmitter<any>();

  constructor(private formControlDirective: FormControlDirective) {}

  ngOnInit(): void {
    this.formControlDirective.control?.valueChanges
      .pipe(
        debounceTime(this.debounceTime),
        distinctUntilChanged(isEqual),
        takeUntil(this.destroy$)
      )
      .subscribe((value) => {
        this.valueChange.emit(value)
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.connectControl) {
      this.formControlDirective.control?.setValue(
        changes.connectControl.currentValue
      );
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }
}
