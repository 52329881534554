import {Action, ActionReducer} from '@ngrx/store';
import merge from 'lodash-es/merge';
import pick from 'lodash-es/pick';
import {LocalStorageService} from '../../services/local-storage.service';

export function storageMetaReducer<S, A extends Action = Action>(
  saveKeys: string[],
  localStorageKey: string,
  localStorageService: LocalStorageService
) {
  let onInit = true; // after load/refresh…
  return function(reducer: ActionReducer<S, A>) {
    return function(state: S, action: A): S {
      // get the next state.
      const nextState = reducer(state, action);
      // init the application state.
      if (onInit) {
        onInit = false;
        const savedState = localStorageService.getSavedState(localStorageKey);
        return merge(nextState, savedState);
      }

      // save the next state to the application storage.
      const stateToSave = pick(nextState, ...saveKeys);
      localStorageService.setSavedState(stateToSave, localStorageKey);

      return nextState;
    };
  };
}
