import {Component, Input} from '@angular/core';

@Component({
  selector: 'shared-tab',
  template: `
    <div [hidden]="!active">
      <ng-content></ng-content>
    </div>
  `
})
export class TabComponent {
  @Input() title = '';
  @Input() active = false;
}
