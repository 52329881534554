import { mapFrom, MappingProfile } from '@automapper/core';
import { BaseRole } from './base-role';
import { FormState as ManualForm } from 'src/app/features/gestione-manuali/features/new/store/form/form.state';
export class Manual {
  id: string;

  tipo: string;

  nomeFile: string;

  descrizione: string;

  statoManuale: string;

  roles: BaseRole[];
}

export interface ManualGridItem extends Manual {
  allowedActions: AllowedManualiActions;
}
export type AllowedManualiActions = Partial<Record<ManualiActionType, boolean>>;

export enum ManualiActionType {
  DELETE = 'delete',
  EDIT = 'edit',
  DOWNLOAD = 'download',
}

export interface ManualAction {
  id: string;
  actionType: ManualiActionType;
}

export enum ManualeStato {
  ATTIVO = 'attivo',
  CANCELLATO = 'cancellato',
}

export const ManualeProfile: MappingProfile = (mapper) => {
  mapper
    .createMap(Manual, ManualForm)
    .forMember(
      (dest: ManualForm) => dest.attachment.name,
      mapFrom((src: Manual) => src.nomeFile)
    )
    .forMember(
      (dest: ManualForm) => dest.roles,
      mapFrom((src: Manual) => [...src.roles.map((x) => x.id)])
    )
    .forMember(
      (dest: ManualForm) => dest.id,
      mapFrom((src: Manual) => src.id)
    )
    .forMember(
      (dest: ManualForm) => dest.descrizione,
      mapFrom((src: Manual) => src.descrizione)
    );
};
