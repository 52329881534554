import {Injectable} from '@angular/core';
import {NgbDateAdapter, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';

@Injectable()
export class CustomAdapter extends NgbDateAdapter<string> {

  fromModel(value: string | null): NgbDateStruct | null {
    if (value) {
      const date = new Date(+value);
      return {
        day: date.getDate(),
        month: date.getMonth() + 1,
        year: date.getFullYear(),
      };
    }
    return null;
  }

  toModel(date: NgbDateStruct | null): string | null {
    const dateObj = new Date(date?.year as number, (date?.month as number) - 1, date?.day);
    const dateTime = dateObj.getTime() - (dateObj.getTimezoneOffset() * 60 * 1000);
    if (isNaN(dateTime)) { return ''; }
    return dateTime.toString();
  }
}
