import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Pagination } from '../models/pagination.model';

@Component({
  selector: 'vvf-pagination',
  template: `
    <div class="d-flex justify-content-between p-2">
      <div *ngIf="pagination?.itemsPerPage!">
        <span>{{ 'pagination.itemsPerPage' | translate }}</span>
        <vvf-select
          [ngModel]="pagination?.itemsPerPage!"
          [items]="itemsPerPageOptions"
          [searchable]="false"
          (selectChange)="selectChangeHandler($event)"
        >
        </vvf-select>
      </div>

      <div class="d-flex align-items-center">
        <ngb-pagination
          [page]="pagination?.currentPage!"
          [boundaryLinks]="true"
          [collectionSize]="pagination?.totalItems!"
          [maxSize]="5"
          [pageSize]="pagination?.itemsPerPage!"
          [rotate]="true"
          (pageChange)="pageChangeHandler($event)"
        >
        </ngb-pagination>
      </div>
    </div>
  `,
})
export class PaginationComponent {
  @Input() pagination: Pagination;
  @Input() itemsPerPageOptions: number[] = [5, 10, 20, 50];
  @Output() paginationChange: EventEmitter<Partial<Pagination>> =
    new EventEmitter<Partial<Pagination>>();

  selectChangeHandler(itemsPerPage: number) {
    this.paginationChange.emit({ itemsPerPage });
  }

  pageChangeHandler(currentPage: number) {
    this.paginationChange.emit({ currentPage });
  }
}
