import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanLoad,
  Route,
  RouterStateSnapshot,
  UrlSegment,
} from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Role, RoleEnum } from 'src/app/model/role';
import { AppState } from '../core.module';
import { RoleSelectors } from '../store/profile/store/role/role.selectors';

@Injectable()
export class RolesOnlyGuard implements CanActivate, CanLoad {
  constructor(private store: Store<AppState>) {}

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> {
    const roles = route.data?.role as RoleEnum | RoleEnum[];
    return this.store
      .select(RoleSelectors.rolesOnly, { roles })
      .pipe(catchError(() => of(false)));
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    const roles = route.data?.role as RoleEnum | RoleEnum[];
    return this.store
      .select(RoleSelectors.rolesOnly, { roles })
      .pipe(catchError(() => of(false)));
  }
}
