import { Type } from '@angular/core';
import { AuthEffects } from './auth/store/auth.effects';
import { ConfigurationEffects } from './configuration/store/configuration.effects';
import { MessagesEffects } from './messages/messages.effects';
import { profileEffects } from './profile/store/profile.effects';
import { RouterEffects } from './router/store/router.effects';
import { SidebarEffects } from './sidebar/sidebar.effects';

export const coreEffects: Type<any>[] = [
  AuthEffects,
  ConfigurationEffects,
  MessagesEffects,
  ...profileEffects,
  RouterEffects,
  SidebarEffects
];
