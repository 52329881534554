import { createAction, props } from '@ngrx/store';
import { User } from 'src/app/model/user';

export namespace UserActions {
  export const load = createAction('[Profile User] load');

  export const loadSuccess = createAction(
    '[Profile User] load success',
    props<{ user: User }>()
  );

  export const loadComplete = createAction(
    '[Profile User] load complete',
    props<{ user: User }>()
  );

  export const loadFailed = createAction('[Profile User] load failed');

  export const edit = createAction(
    '[Profile User] edit',
    props<{ user: User }>()
  );

  export const editSuccess = createAction(
    '[Profile User] edit success',
    props<{ user: User }>()
  );

  export const editFailed = createAction('[Profile User] edit failed');

  export const clear = createAction('[Profile User] clear');
}
