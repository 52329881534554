import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { PasswordService } from '../../../../core/services/password.service';
import { AppState } from '../../../../core/core.module';
import { RouterActions } from '../../../../core/store/router/store/router.actions';
import { Store } from '@ngrx/store';
import { ActivatedRoute } from '@angular/router';
import { AuthActions } from '../../../../core/store/auth/store/auth.actions';

@Component({
  selector: 'vvf-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
})
export class ChangePasswordComponent implements OnInit {
  passwordVisible: any;
  confirmPasswordVisible: boolean;
  private token: string | null;

  changePasswordForm = new FormGroup({
    password: new FormControl(null, [Validators.required]),
    confirmPassword: new FormControl(null, [Validators.required]),
  });

  constructor(
    private store: Store<AppState>,
    private route: ActivatedRoute,
    private passwordService: PasswordService
  ) {
    this.token = this.route.snapshot.paramMap.get('token');
    // this.store.dispatch(AuthActions.logoutPassword({ token: this.token }));s
  }

  ngOnInit(): void {}

  changePassword() {
    const { password } = this.changePasswordForm.value;
    if (password) {
      this.passwordService.changePassword(password, this.token).subscribe(() => {
        this.store.dispatch(RouterActions.go({ path: ['account'] }));
      });
    }
  }
}
