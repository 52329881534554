import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'vvf-input-enabler',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <vvf-radio-group
      [formControl]="control"
      [connectControl]="model"
      [name]="name"
      [label]="question"
      [options]="options"
      [disabled]="disabled"
      bindLabel="label"
      bindValue="value"
      (valueChange)="valueChange.emit($event)"
    ></vvf-radio-group>
  `,
})
export class EnablerComponent {
  control = new FormControl();

  @Input() disabled: boolean;
  @Input() model = false;
  @Input() name = '';
  @Input() question = '';
  @Input() options: Array<{ value: boolean; label: string }> = [];
  @Output() valueChange = new EventEmitter<boolean>();
}
