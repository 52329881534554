import { AutoMap } from '@automapper/classes';
import { LesioniConfig } from './config';
import { LesioneEditor } from './editor';
import { LesioneItem } from './lesione-item';

export class Lesioni {
  config: LesioniConfig;

  @AutoMap({ typeFn: () => LesioneEditor })
  editor: LesioneEditor;

  @AutoMap({ typeFn: () => LesioneItem })
  items: LesioneItem[];

  @AutoMap()
  conseguenzaLesioni: string;
}
