import { createAction, props } from '@ngrx/store';
import { Role } from 'src/app/model/role';

export namespace RoleActions {
  export const load = createAction('[Profile Role] load');
  export const set = createAction(
    '[Profile Role] set',
    props<{ role: Role }>()
  );

  export const impersonate = createAction(
    '[Profile Role] impersonate',
    props<{ role: Role }>()
  );

  export const save = createAction(
    '[Profile Role] save',
    props<{ role: Role }>()
  );
  export const clear = createAction('[Profile Role] clear');
}
