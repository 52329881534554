import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/core/core.module';
import { DownloadFileActions } from 'src/app/core/store/download-file/download-file.actions';
import { DipendenteInfortunato } from 'src/app/model/dipendente-infortunato';
import { RoleEnum } from 'src/app/model/role';

@Component({
  template: `
    <div class="modal-header">
      <h5 class="title">
        {{ 'registroInfortuni.detail' | translate }}
      </h5>
      <button
        class="close"
        type="button"
        data-dismiss="modal"
        [attr.aria-label]="'common.close' | translate"
        [ngbTooltip]="'common.close' | translate"
        placement="bottom"
        (click)="activeModal.close()"
      >
        <svg class="icon">
          <use
            xlink:href="./bootstrap-italia/dist/svg/sprite.svg#it-close"
          ></use>
        </svg>
      </button>
    </div>
    <div class="modal-body">
      <shared-collapse
        [expanded]="false"
        *rolesExcept="[Role.SEA_RESPONSABILE_PS, Role.SEA_RESPONSABILE_SPI]"
      >
        <h6 class="primary-color mt-3" title>
          {{ 'nuovoInfortunio.stepDipendente.title' | translate }}
        </h6>
        <vvf-registro-infortuni-dipendente-anagrafica
          body
          [anagrafica]="dipendente.dipendente.infortunato"
        ></vvf-registro-infortuni-dipendente-anagrafica>
      </shared-collapse>
      <shared-collapse [expanded]="false">
        <h6 class="primary-color mt-3" title>
          {{ 'nuovoInfortunio.stepTempoLuogo.title' | translate }}
        </h6>
        <vvf-riepilogo-infortunio-tempo-luogo
          body
          [tempoLuogo]="dipendente.tempoLuogo"
          (download)="downloadIntervento($event)"
        ></vvf-riepilogo-infortunio-tempo-luogo>
      </shared-collapse>

      <shared-collapse>
        <h6 class="primary-color mt-3" title>
          {{ 'nuovoInfortunio.stepDinamicaInfortunio.title' | translate }}
        </h6>
        <vvf-registro-infortuni-dipendente-dinamica
          body
          [dinamica]="dipendente.dinamicaInfortunio.dinamica"
        ></vvf-registro-infortuni-dipendente-dinamica>
      </shared-collapse>

      <shared-collapse>
        <h6 class="primary-color mt-3" title>
          {{ 'nuovoInfortunio.stepInformazioniTecniche.title' | translate }}
        </h6>
        {{ dipendente.informazioniTecniche | json }}
        <vvf-registro-infortuni-dipendente-informazioni-tecniche
          body
          [informazioniTecniche]="dipendente.informazioniTecniche"
        ></vvf-registro-infortuni-dipendente-informazioni-tecniche>
      </shared-collapse>

      <shared-collapse>
        <h6 class="primary-color mt-3" title>
          {{
            'nuovoInfortunio.stepInformazioniTecniche.otherInformations.title'
              | translate
          }}
        </h6>
        <vvf-registro-infortuni-altre-informazioni
          body
          [informazioniTecniche]="dipendente.informazioniTecniche"
        ></vvf-registro-infortuni-altre-informazioni>
      </shared-collapse>

      <shared-collapse>
        <h6 class="primary-color mt-3" title>
          {{ 'Prognosi' | translate }}
        </h6>
        <vvf-registro-infortuni-prognosi-detail
          body
          [prognosi]="dipendente.prognosi"
        ></vvf-registro-infortuni-prognosi-detail>
      </shared-collapse>

      <shared-collapse>
        <h6 class="primary-color mt-3" title>
          {{ 'nuovoInfortunio.stepDescrizioneLesioni.title' | translate }}
        </h6>
        <vvf-riepilogo-infortunio-descrizione-lesioni
          body
          [lesioni]="dipendente.lesioni"
        ></vvf-riepilogo-infortunio-descrizione-lesioni>
      </shared-collapse>

      <shared-collapse
        *rolesExcept="[
          Role.SEA_DATORE_LAVORO,
          Role.SEA_RESPONSABILE_SPP,
          Role.SEA_RESPONSABILE_PS,
          Role.SEA_RESPONSABILE_SPI
        ]"
      >
        <h6 class="primary-color mt-3" title>
          {{ 'nuovoInfortunio.stepAllegati.title' | translate }}
        </h6>
        <vvf-riepilogo-infortunio-allegati
          body
          [allegati]="dipendente.allegati"
        ></vvf-riepilogo-infortunio-allegati>
      </shared-collapse>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-sm btn-primary"
        (click)="activeModal.close()"
      >
        {{ 'common.close' | translate }}
      </button>
    </div>
  `,
})
export class DipendenteInfortunatoDetailComponent {
  @Input() dipendente: DipendenteInfortunato = {} as DipendenteInfortunato;

  Role = RoleEnum;

  constructor(
    public activeModal: NgbActiveModal,
    private store: Store<AppState>
  ) {}

  downloadIntervento({ objectId, name }: { objectId: string; name: string }) {
    this.store.dispatch(DownloadFileActions.download({ objectId, name }));
  }
}
