import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

@Component({
  selector: 'vvf-scheda-actions',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div class=" d-flex justify-content-between px-4 ">
      <div>
        <button
          class="btn btn-sm btn-outline-primary mr-3"
          (click)="cancel.emit()"
        >
          {{ 'common.cancel' | translate }}
        </button>
        <button
          class="btn btn-sm btn-primary"
          type="button"
          (click)="save.emit()"
        >
          {{ 'common.save' | translate }}
        </button>
      </div>
      <button
        *ngIf="activePage == maxTabNumber - 1"
        class="btn btn-sm btn-primary"
        type="button"
        (click)="send.emit()"
      >
        {{ 'common.send' | translate }}
      </button>
    </div>
  `,
})
export class SchedaActionsComponent {
  @Input() activePage = 0;
  @Input() maxTabNumber = 3;
  @Output() cancel = new EventEmitter<void>();
  @Output() save = new EventEmitter<void>();
  @Output() send = new EventEmitter<void>();
}
