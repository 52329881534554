<ng-container
  *ngIf="(data$ | async)?.length || (paginationService?.loading$ | async)"
>
  <table class="table {{ className }}">
    <thead *ngIf="head">
    <tr>
      <th
        *ngFor="let column of columns"
        [ngClass]="{ sortable: column.sortable }"
        scope="col"
      >
        <span>{{ column.label ? column.label : '' | translate }}</span>
      </th>
    </tr>
    </thead>
    <tbody>
    <tr
      *ngFor="let item of data$ | async; let i = index"
    >
      <td *ngFor="let column of columns">
        <ng-container *ngIf="!column.actions; else actionsCol">
          <ng-container *ngIf="column.pipe; else defaultCol">
            {{
            item
              | propertyValue: column.bind
              | dynamic: column.pipe:column.pipeArgs
              | translate

            }}
          </ng-container>
          <ng-template #defaultCol>
            {{  item
            | propertyValue: column.bind
            | translate
            }}
          </ng-template>
        </ng-container>
        <ng-template #actionsCol>
          <ng-container *ngFor="let action of column.actions">
            <a class="mx-2 cursor-pointer" (click)="onActionClicked(action.action)">
              <i [class]="action.class">{{ action.icon }}</i>
            </a>
          </ng-container>
        </ng-template>
      </td>
    </tr>
    </tbody>
  </table>
  <div class="d-flex justify-content-between p-2">
    <div *ngIf="pageSize">
      <span>{{"table.elementi" | translate}}:</span>
      <vvf-select
        [(ngModel)]="pageSize"
        [items]="itemsPerPageOptions"
        [searchable]="false"
      >
      </vvf-select>
    </div>

    <div class="d-flex align-items-center">
      <div
        *ngIf="paginationService.loading$ | async"
        class="progress-spinner progress-spinner-active size-sm mr-3"
      ></div>
      <ngb-pagination
        *ngIf="page"
        [(page)]="page"
        [boundaryLinks]="true"
        [collectionSize]="total ? total : 0"
        [maxSize]="5"
        [pageSize]="pageSize"
        [rotate]="true"
      >
      </ngb-pagination>
    </div>
  </div>
</ng-container>
<ng-container
  *ngIf="!(paginationService?.firstTime$ | async) && !(paginationService?.loading$ | async) && !(data$ | async)?.length"
>
  <ng-content select="[noResult]"></ng-content>
</ng-container>
