import { AutoMap } from '@automapper/classes';
import { Typologic } from 'src/app/model/common/typologic';

export class Mezzo {
  @AutoMap()
  id: string;

  @AutoMap()
  targa: string;
  @AutoMap()
  sigla: string;
  @AutoMap()
  codiceGenereMezzo: string;
  @AutoMap()
  descrizioneGenereMezzo: string;
  @AutoMap()
  tipoGenereMezzo: string;

  @AutoMap({ typeFn: () => Typologic })
  categoriaAttrezzatura: Typologic;

  @AutoMap()
  attrezzaturaId: number;
}
