import { createReducer, on } from '@ngrx/store';
import { SidebarActions } from './sidebar.actions';

export interface SidebarItem {
  text: string;
  class: string;
  icon?: string;
  link: string[];
}

const initialState: SidebarItem[] = [];

export const sidebarReducer = createReducer(
  initialState,
  on(SidebarActions.loadComplete, (_, { items }) => items)
);
