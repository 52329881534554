import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { UserInfoService } from 'src/app/core/services/user-info.service';
import { SidebarActions } from '../../../sidebar/sidebar.actions';
import { RoleActions } from '../role/role.actions';
import { UserActions } from './user.actions';

@Injectable()
export class UserEffects {
  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.load),
      switchMap(() =>
        this.userInfoService.load().pipe(
          map((user) => UserActions.loadSuccess({ user })),
          catchError(() => of(UserActions.loadFailed()))
        )
      )
    )
  );

  loadSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.loadSuccess),
      switchMap(({ user }) => [
        UserActions.loadComplete({ user }),
        RoleActions.load(),
      ])
    )
  );

  edit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.edit),
      switchMap(({ user }) =>
        this.userInfoService.edit(user).pipe(
          map((result) => UserActions.editSuccess({ user: result })),
          catchError(() => of(UserActions.editFailed()))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private userInfoService: UserInfoService
  ) {}
}
