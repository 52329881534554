import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  Input,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormArray,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { StringHelper } from '../../providers/string-helper.provider';

@Component({
  selector: 'vvf-checkbox-group',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div
      class="row bg-color mx-0 mb-2"
      *ngFor="let option of options; index as index; trackBy: trackByItemsFn"
    >
    {{isChecked(option[bindValue])}}
      <vvf-checkbox
        class="col-12 col-md-3"
        [name]="name + '-' + index"
        [ngModel]="isChecked(option[bindValue])"
        [label]="option[bindLabel]"
        (check)="checkHandler(option.id, $event)"
      ></vvf-checkbox>

      <div
        class="col-12 col-md-9 border-left px-4"
        *ngIf="option.children?.length"
      >
        <ng-container
          *ngFor="let subOption of option.children; index as subIndex"
        >
          <vvf-checkbox
            className="form-check form-check-inline col-12 col-sm-6 col-md-4 mr-0"
            [name]="name + '-' + index + '-' + subIndex"
            [ngModel]="isChecked(subOption[bindValue])"
            [label]="subOption[bindLabel]"
            (check)="checkHandler(subOption.id, $event)"
          ></vvf-checkbox>
        </ng-container>
      </div>
    </div>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxGroupComponent),
      multi: true,
    },
  ],
})
export class CheckboxGroupComponent implements ControlValueAccessor {
  private _value: string[] = [];
  get value() {
    return this._value;
  }

  set value(val: string[]) {
    this._value = val;
    this.onChange(val);
    this.onTouch(val);
  }

  @Input() name = '';
  @Input() options: any[];
  @Input() formArray: FormArray;
  @Input() bgColor: boolean = true;
  @Input() bindLabel: string;
  @Input() bindValue: string;
  @Input() labelTemplate: string;

  trackByItemsFn = (index: number) => index;

  isChecked = (value: any) => this.value.includes(value);

  onChange: any = () => {};

  onTouch: any = () => {};

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(value: any): void {
    this.value = value;
  }

  format(text: string, value: string) {
    return StringHelper.format(text, value);
  }

  checkHandler(id: string, checked: boolean, parent?: any) {
    if (!checked) {
      this.value = this.value.filter((v) => v !== id);
      return;
    }

    this.value = [...this.value, id];
  }

  isHasSubItems(items: any[]) {
    let index = items.findIndex((x) => !!x.values);
    return index !== -1;
  }
}
