import { AutoMap } from '@automapper/classes';

export enum TipoLesione {
  ALTRO = 11,
}

export class Lesione {
  @AutoMap({ typeFn: () => Number })
  tipo: TipoLesione;

  @AutoMap()
  nota: string;
}
