import { AutoMap } from '@automapper/classes';
import { TipoLesione } from 'src/app/model/dipendente-infortunato/lesioni/lesione-item/lesione';
import { TipoParteCorpo } from 'src/app/model/dipendente-infortunato/lesioni/tipo-parte-corpo.enum';

export class LesioneDTO {
  @AutoMap({ typeFn: () => Number })
  tipoLesione: TipoLesione;

  @AutoMap()
  notaLesione: string;

  @AutoMap({ typeFn: () => Number })
  tipoParteCorpo: TipoParteCorpo[];

  @AutoMap()
  notaParteCorpo: string;
}
