import { Component, Input, Output, EventEmitter } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  AllowedManualiActions,
  ManualAction,
  ManualGridItem,
  ManualiActionType,
} from 'src/app/model/manual';
import { ManageManualsDeleteDialogComponent } from './manage-manuals-delete-dialog.component';

@Component({
  selector: 'vvf-manuals-grid-actions',
  template: `
    <button
      class="btn btn-xs"
      *ngFor="let action of allowedActionsArray"
      (click)="$event.stopPropagation(); actionHandler(action)"
      [ngbTooltip]="'infortuni.grid.actions.' + action | translate"
    >
      <i class="material-icons text-secondary">{{ actionButtons[action] }}</i>
    </button>
  `,
})
export class ManualsGridActionsComponent {
  @Input() index: number = -1;
  @Input() manuale: ManualGridItem;
  @Output() action = new EventEmitter<ManualAction>();

  constructor(private modalService: NgbModal) {}

  get allowedActions(): AllowedManualiActions {
    return this.manuale?.allowedActions ?? {};
  }

  get allowedActionsArray(): string[] {
    return this.manuale?.allowedActions
      ? Object.keys(this.manuale?.allowedActions)
      : [];
  }

  actionButtons = {
    [ManualiActionType.DELETE as string]: 'delete',
    [ManualiActionType.EDIT as string]: 'edit',
    [ManualiActionType.DOWNLOAD as string]: 'download',
  };

  trackByFn = (index: number) => index;

  async actionHandler(type: string) {
    if (type === ManualiActionType.DELETE) {
      const modalRef = this.modalService.open(
        ManageManualsDeleteDialogComponent
      );
      const confirmed = await modalRef.closed.toPromise();
      if (confirmed) {
        this.action.emit({
          id: this.manuale.id,
          actionType: type as ManualiActionType,
        });
      }
      return;
    }
    this.action.emit({
      id: this.manuale.id,
      actionType: type as ManualiActionType,
    });
  }
}
