import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Option } from 'src/app/model/common/option';
import { Typologic } from 'src/app/model/common/typologic';

@Injectable()
export class TypologicService {
  constructor(private translate: TranslateService) {}

  private _mapToOptions(
    typologics: Typologic[],
    path: string,
    parentId?: number
  ): Option[] {
    let partiCorpo: Option[] = [];

    for (const typologic of typologics) {
      partiCorpo = [
        ...partiCorpo,
        {
          id: typologic.id,
          description: this.translate.instant(
            `${path}.${typologic.description}`
          ),
          parentId,
        } as Option,
      ];

      if (typologic.children) {
        partiCorpo = [
          ...partiCorpo,
          ...this._mapToOptions(typologic.children, path, typologic.id),
        ];
      }
    }

    return partiCorpo;
  }

  mapToOptions = (typologics: Typologic[], path: string): Option[] =>
    this._mapToOptions(typologics, path);
}
