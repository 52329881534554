import { createReducer, on } from '@ngrx/store';
import { User } from 'src/app/model/user';
import { UserActions } from './user.actions';

const initialState: User = {} as User;

export const userReducer = createReducer(
  initialState,
  on(UserActions.loadSuccess, (state, { user }) => user),
  on(UserActions.editSuccess, (state, { user }) => ({
    ...state,
    ...user,
  })),
  on(UserActions.clear, (state) => ({} as User))
);
