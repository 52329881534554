import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { Message } from '../models/message';

@Component({
  selector: 'shared-toast',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div class="notification-container">
      <div
        *ngFor="let message of messages; let i = index; trackBy: trackByFn"
        [attr.id]="'notification' + i"
        [attr.aria-labelledby]="'notification-title' + i"
        [class]="'notification with-icon ' + message.messageType"
        role="alert"
        [class.dismissable]="message.dismissable"
      >
        <h5 [attr.id]="'notification-title' + i">
          <svg class="icon">
            <ng-container [ngSwitch]="message.messageType">
              <use
                *ngSwitchCase="'success'"
                xlink:href="./bootstrap-italia/dist/svg/sprite.svg#it-check-circle"
              ></use>
              <use
                *ngSwitchCase="'error'"
                xlink:href="./bootstrap-italia/dist/svg/sprite.svg#it-close-circle"
              ></use>
              <use
                *ngSwitchCase="'warning'"
                xlink:href="./bootstrap-italia/dist/svg/sprite.svg#it-error"
              ></use>
              <use
                *ngSwitchCase="'info'"
                xlink:href="./bootstrap-italia/dist/svg/sprite.svg#it-info-circle"
              ></use>
            </ng-container>
          </svg>
          {{ message.title }}
        </h5>
        <p>{{ message.text }}</p>
        <button
          type="button"
          class="btn notification-close"
          *ngIf="message.dismissable"
          (click)="$event.stopPropagation(); dismissHandler(message)"
        >
          <svg class="icon">
            <use
              xlink:href="./bootstrap-italia/dist/svg/sprite.svg#it-close"
            ></use>
          </svg>
        </button>
      </div>
    </div>
  `,
})
export class ToastComponent {
  @Input() messages: Message[] = [];
  @Output() dismiss = new EventEmitter<string>();

  trackByFn = (index: number) => index;

  dismissHandler(message: Message) {
    if (message.dismissable) {
      this.dismiss.emit(message.id);
    }
  }
}
