import { AutoMap } from '@automapper/classes';
import { DocumentoDTO } from '../shared/documento-dto';

export class CertificatoMedicoDTO {
  @AutoMap()
  dataInizio!: string;

  @AutoMap()
  dataFine!: string;

  @AutoMap({ typeFn: () => DocumentoDTO })
  certificato: DocumentoDTO;
}
