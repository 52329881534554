import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ConfigurationService } from '../services/configuration.service';
import { ConfigurationActions } from './configuration.actions';
import { map, switchMap } from 'rxjs/operators';

@Injectable()
export class ConfigurationEffects {
  loadConfiguration$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ConfigurationActions.load),
      switchMap(({ tipoPratica }) =>
        this.configurationService
          .configurations$(tipoPratica)
          .pipe(
            map((configuration) =>
              ConfigurationActions.loadSuccess({ configuration })
            )
          )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private configurationService: ConfigurationService
  ) {}
}
