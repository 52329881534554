import { createReducer, on } from '@ngrx/store';
import { Role } from 'src/app/model/role';
import { RoleActions } from './role.actions';

const initialState: Role = {} as Role;

export const roleReducer = createReducer(
  initialState,
  on(RoleActions.save, (_, { role }) => role),
  on(RoleActions.clear, (_) => ({} as Role))
);
