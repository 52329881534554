import { Component, Input } from '@angular/core';
import { Attrezzatura } from 'src/app/model/dipendente-infortunato/informazioni-tecniche/attrezzature/attrezzatura';
import { Mezzo } from 'src/app/model/dipendente-infortunato/informazioni-tecniche/mezzi/mezzo';

@Component({
  selector: 'vvf-registro-infortuni-dipendente-attrezzatura-detail-items',
  template: `
    <table class="table table-sm table-bordered" *ngIf="items.length">
      <thead>
        <tr>
          <th scope="col" class="text-center">#</th>
          <th scope="col">
            {{ 'Categoria' | translate }}
          </th>
          <th scope="col">
            {{ 'Dettaglio' | translate }}
          </th>
        </tr>
      </thead>
      <tbody>
        <ng-container>
          <tr *ngFor="let item of items; index as index; trackBy: trackByFn">
            <td class="text-center align-middle">
              {{ index + 1 }}
            </td>
            <td class="align-middle">
              <vvf-input-text
                [id]="item.id + '-' + index"
                [ngModel]="
                  'nuovoInfortunio.stepInformazioniTecniche.detail.attrezzatura.' +
                    item.categoriaAttrezzatura.id | translate
                "
                [readOnly]="true"
                className="form-group no-mb m-3 mt-4"
                formControlClassName="form-control no-bg"
              ></vvf-input-text>
            </td>
            <td class="align-middle">
              <vvf-input-text
                [id]="item.id + '-' + index"
                [ngModel]="
                  'nuovoInfortunio.stepInformazioniTecniche.detail.dettaglioAttrezzatura.' +
                    item.attrezzaturaId | translate
                "
                [readOnly]="true"
                className="form-group no-mb m-3 mt-4"
                formControlClassName="form-control no-bg"
              ></vvf-input-text>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  `,
})
export class AttrezzaturaDetailItemsComponent {
  @Input() items: any[] = [];
  @Input() label: string = '';

  trackByFn = (index: number) => index;
}
