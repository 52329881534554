import { AutoMap } from '@automapper/classes';
import { Allegati } from './allegati';
import { DinamicaInfortunio } from './dinamica-infortunio';
import { Dipendente } from './dipendente';
import { InformazioniTecniche } from './informazioni-tecniche';
import { Lesioni } from './lesioni';
import { Prognosi } from './prognosi';
import { TempoLuogo } from './tempo-luogo';

export class DipendenteInfortunato {
  @AutoMap({ typeFn: () => Dipendente })
  dipendente: Dipendente;

  @AutoMap({ typeFn: () => TempoLuogo })
  tempoLuogo: TempoLuogo;

  @AutoMap({ typeFn: () => DinamicaInfortunio })
  dinamicaInfortunio: DinamicaInfortunio;

  @AutoMap({ typeFn: () => InformazioniTecniche })
  informazioniTecniche: InformazioniTecniche;

  @AutoMap({ typeFn: () => Prognosi })
  prognosi: Prognosi;

  lesioni: Lesioni;

  @AutoMap({ typeFn: () => Allegati })
  allegati: Allegati;
}
